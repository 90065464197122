import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1004,
});

const ModalContainer = styled("div")({
  background: "white",
  borderRadius: "8px",
  maxWidth: "90%",
  maxHeight: "90%",
  width: "500px",
  padding: "8px",
  boxSizing: "border-box",
  overflow: "auto",
  position: "relative",
});

const CloseButton = styled("button")({
  background: "none",
  border: "none",
  fontSize: "1.5rem",
  position: "absolute",
  top: "8px",
  right: "8px",
  cursor: "pointer",
});

const ModalContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Title = styled("h2")({
  marginBottom: "0.5rem",
  fontSize: "18px",
  textAlign: "center",
  "@media (max-width: 500px)": {
    fontSize: "16px",
  },
});

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string; // Optional title prop
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title }) => {
  useEffect(() => {
    // Close modal on "Esc" key press
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEsc);

      return () => {
        document.removeEventListener("keydown", handleEsc);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {title && <Title>{title}</Title>}
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Overlay>,
    document.body
  );
};

export default Modal;
