import styled from "@emotion/styled";
import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { getApiInstance } from "../../hooks/useApi";
import useDebounce from "../../hooks/useDebounce";
import { useToast } from "../../hooks/useToast";
import { colors } from "../../types";
import { getCroppedImg } from "../../utils/cropImage";
import Button from "../form/Button";
import {
  HR,
  InputField,
  InputWrapper,
  Label,
  SelectField,
} from "../form/Styles";
import LoadingDots from "../misc/LoadingDots";
import Modal from "../misc/Modal";
import PaginationControls from "../Pagination";

const SearchBarWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "space-between",
  marginBottom: "16px",

  "@media (min-width: 1025px)": {
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },

  "media (min-width: 768px)": {
    marginBottom: "32px",
    gap: 0,
  },
});

const StyledMenuItem = styled("option")({
  padding: "8px",
  fontSize: "16px",
  backgroundColor: "#fff",
  color: colors.brand.brandBlack.hex,
  "&:hover": {
    backgroundColor: colors.brand.brandSilver.hex,
    color: "#fff",
  },
});

export const FilterBarWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "15px",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap", // Allow items to wrap on small screens
  "@media (max-width: 768px)": {
    gap: "10px",
    justifyContent: "space-between", // Spread out items on small screens
  },
});

const FilterSelect = styled(SelectField)({
  flex: 1,
  minWidth: "200px", // Ensure a reasonable width on smaller screens
  "@media (max-width: 768px)": {
    flex: "1 1 100%", // Allow it to take up full width on smaller screens
  },
});

export const FilterInputWrapper = styled(InputWrapper)({
  flex: 1,
  minWidth: "250px",
  maxWidth: "300px",
  "@media (max-width: 768px)": {
    flex: "1 1 100%",
  },
});

export const FilterInput = styled(InputField)({
  width: "100%",
});

export const FilterLabel = styled(Label)({
  fontSize: "14px", // Adjust font size for better readability on small screens
});

const GalleryContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  gap: "10px",
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  justifyItems: "center", // Centers items horizontally within each grid cell
  margin: "0 auto", // Centers the entire grid container horizontally
  maxWidth: "1280px", // Optional: You can limit the maximum width if needed
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    padding: "10px",
    maxWidth: "100%",
  },
});

const ImageCard = styled("div")({
  width: "100%",
  height: "150px",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: "8px",
  position: "relative",
  overflow: "hidden",
  border: "1px solid black",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const UploadContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "calc(100% - 32px)",
  marginBottom: "20px",
  border: "1px solid #ddd",
  padding: "16px",
  margin: "16px 0",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
});

export const UploadInputLabel = styled("label")({
  backgroundColor: colors.brand.brandDarkRed.hex,
  fontFamily: "AvanteGarde-Bold, sans-serif",
  color: "white",
  width: "150px",
  padding: "8px",
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
  },
});

export const UploadInput = styled("input")({
  display: "none",
});

export const PreviewImageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

const FullSizeImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "400px",
  objectFit: "contain",
  borderRadius: "8px",
  border: "2px solid #ddd",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
});

const ImageDetails = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "14px",
  color: colors.brand.brandBlack.hex,
});

export interface ImageData {
  imageId: string;
  filename: string;
  url: string;
}

const aspectRatioTypeMap: Record<number, string> = {
  [1280 / 250]: "banner",
  [300 / 200]: "card",
  [300 / 300]: "story",
};

const ImagesTab: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [filename, setFilename] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const { showToast } = useToast();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [totalPages, setTotalPages] = useState(1); // Total pages from API
  const [isLoading, setIsLoading] = useState(false); // Loading state for pagination
  const [imageType, setImageType] = useState<
    "banner" | "card" | "story" | "original" | "all"
  >("all");
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);

  const fetchImages = async (
    page = 1,
    limit = 28,
    type = imageType,
    query = search
  ) => {
    setIsLoading(true);
    try {
      const response = await getApiInstance().get("/api/images", {
        params: {
          page,
          limit,
          type,
          query,
        },
      });

      setImages(response.data.images); // Assuming the API returns an array of images in data.images
      setTotalPages(response.data.totalPages); // Assuming the API returns total pages count
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false); // Clear loading state
    }
  };

  // useEffect(() => {
  //   fetchImages(currentPage); // Fetch images on component mount and page change
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage, imageType, search]);
  useEffect(() => {
    // Trigger search when debouncedSearch changes
    fetchImages(currentPage, 28, imageType, debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, imageType, currentPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Cropper state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [aspectRatio, setAspectRatio] = useState(1280 / 250);
  const onCropComplete = (
    _croppedAreaPercentage: Area,
    croppedAreaPixels: Area
  ) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    } else {
      setPreviewUrl("");
    }
  };

  const handleCrop = async () => {
    if (!selectedFile || !croppedArea) return;

    if (!previewUrl) {
      showToast("Preview URL is not available", "error");
      return;
    }

    const type =
      aspectRatio === 0
        ? "original"
        : aspectRatioTypeMap[aspectRatio] || "unknown";

    try {
      const croppedImageBlob = await getCroppedImg(previewUrl, croppedArea);

      if (!croppedImageBlob) {
        showToast("Cropped image is not available", "error");
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result as string;
        try {
          setIsUploading(true);
          await getApiInstance().put("/api/images", {
            base64Image,
            filename,
            type,
          });

          setSelectedFile(null);
          setPreviewUrl("");
          setFilename("");
          setIsModalOpen(false);
          fetchImages(1);
          showToast("Image uploaded successfully!", "success");
        } catch (error) {
          console.error("Error uploading image:", error);
          showToast("Error uploading image", "error");
        } finally {
          setIsUploading(false);
        }
      };
      reader.onerror = () => {
        showToast("Error reading image file", "error");
      };

      reader.readAsDataURL(croppedImageBlob);
    } catch (error) {
      console.error("Error cropping image:", error);
      showToast("Error cropping image", "error");
    }
  };

  // const handleDelete = async (imageId: string) => {
  //   try {
  //     const response = await getApiInstance().delete(`/api/images/${imageId}`);
  //     if (response.status === 200) {
  //       setImages((prev) => prev.filter((img) => img.imageId !== imageId));
  //       showToast("Image deleted successfully", "success");
  //     } else {
  //       console.error("Error deleting image:", response.data?.error);
  //       showToast("Error deleting image", "error");
  //     }
  //   } catch (error) {
  //     showToast("Error deleting image", "error");
  //     console.error("Error deleting image:", error);
  //   }
  // };

  const handleImageClick = (image: ImageData) => {
    setSelectedImage(image); // Set the clicked image for preview
    setIsPreviewModalOpen(true); // Open the preview modal
  };

  return (
    <>
      <SearchBarWrapper>
        <FilterBarWrapper>
          <FilterSelect
            value={imageType}
            onChange={(e) => {
              const newType = e.target.value as
                | "banner"
                | "card"
                | "story"
                | "original"
                | "all";
              setImageType(newType);
              setCurrentPage(1);
            }}
          >
            <StyledMenuItem value="all">All</StyledMenuItem>
            <StyledMenuItem value="banner">Banner</StyledMenuItem>
            <StyledMenuItem value="card">Card</StyledMenuItem>
            <StyledMenuItem value="story">Story</StyledMenuItem>
            <StyledMenuItem value="original">Original</StyledMenuItem>
          </FilterSelect>

          <FilterInputWrapper>
            <FilterInput
              type="text"
              name="search"
              placeholder=" "
              value={search}
              onChange={handleSearchChange}
            />
            <FilterLabel>Search</FilterLabel>
          </FilterInputWrapper>
        </FilterBarWrapper>
        <Button
          style={{ width: 125 }}
          onClick={() => setIsModalOpen(true)}
          label={"Add Image"}
        />
      </SearchBarWrapper>
      <HR style={{ marginBottom: 16 }} />
      {/* Gallery Section */}
      {isLoading ? (
        <LoadingDots />
      ) : !images.length ? (
        <p>No images uploaded yet.</p>
      ) : (
        <>
          <>
            {totalPages > 1 && (
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </>
          <GalleryContainer>
            {images.map(({ imageId, url, filename }) => (
              <ImageCard
                key={imageId}
                style={{
                  backgroundImage: `url(${encodeURI(url)
                    .replace(/'/g, "%27")
                    .replace(/"/g, "%22")})`,
                }}
                onClick={() => handleImageClick({ imageId, url, filename })}
              >
                {/* <DeleteIcon
                    icon={faTrash}
                    onClick={() => handleDelete(imageId)}
                  /> */}
              </ImageCard>
            ))}
          </GalleryContainer>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedFile(null);
          setPreviewUrl("");
        }}
        title="Upload Image"
      >
        <UploadContainer>
          {previewUrl ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  marginBottom: 8,
                }}
              >
                <InputWrapper>
                  <InputField
                    type="text"
                    name="filename"
                    value={filename}
                    onChange={(e) => setFilename(e.target.value)}
                    placeholder=" "
                    required
                  />
                  <Label>Filename</Label>
                </InputWrapper>
              </div>
              <div style={{ position: "relative", width: "100%", height: 300 }}>
                <Cropper
                  image={previewUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspectRatio}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, zoom) => setZoom(zoom as number)}
                aria-labelledby="Zoom"
                style={{
                  width: "90%",
                  color: colors.brand.brandDarkRed.hex,
                  marginTop: 8,
                }}
              />
              <div style={{ margin: "20px 0", textAlign: "center" }}>
                <SelectField
                  value={aspectRatio}
                  onChange={(e) => setAspectRatio(parseFloat(e.target.value))}
                >
                  <StyledMenuItem value={1280 / 250}>
                    Banner (1280x250)
                  </StyledMenuItem>
                  <StyledMenuItem value={300 / 200}>
                    Card (300x200)
                  </StyledMenuItem>
                  <StyledMenuItem value={300 / 300}>
                    Story (300x300)
                  </StyledMenuItem>
                  <StyledMenuItem value={0}>Original Size</StyledMenuItem>
                </SelectField>
              </div>
              <Button
                style={{ width: "150px" }}
                onClick={handleCrop}
                disabled={isUploading || !selectedFile || !filename}
                label={
                  isUploading
                    ? "Uploading..."
                    : aspectRatio === 0
                    ? "Upload"
                    : "Crop & Upload"
                }
              />
            </>
          ) : (
            <UploadInputLabel htmlFor="file-upload">
              Select Image
            </UploadInputLabel>
          )}
          <UploadInput
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          />
        </UploadContainer>
      </Modal>
      <Modal
        isOpen={isPreviewModalOpen}
        onClose={() => {
          setIsPreviewModalOpen(false);
          setSelectedImage(null);
        }}
        title={"Image Details"}
      >
        {selectedImage && (
          <PreviewImageContainer>
            <FullSizeImage src={selectedImage.url} alt="Full-size image" />
            <ImageDetails>
              <p>
                <strong>Filename:</strong> {selectedImage.filename || "N/A"}
              </p>
            </ImageDetails>
          </PreviewImageContainer>
        )}
      </Modal>
    </>
  );
};

export default ImagesTab;
