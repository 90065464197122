import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

interface HeaderImageProps {
  backgroundImageUrl: string | undefined;
}

const HeaderImageContainer = styled("div")<{
  backgroundImageUrl: string | undefined;
}>(({ backgroundImageUrl }) => ({
  width: "100%",
  height: "250px",
  background: `url(${backgroundImageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "top",
  marginTop: "1rem",
  marginBottom: "2rem",
  borderRadius: "8px",
  boxShadow: `0px 4px 10px ${colors.brand.brandBlack.rgbaString({
    alpha: 0.5,
  })}`,
  position: "relative",
  // "@media (max-width: 768px)": {
  //   height: "150",
  //   backgroundSize: "cover",
  //   repeat: "no-repeat",
  // },
  "@media (max-width: 480px)": {
    height: "80px",
    backgroundSize: "contain", // Prevents cropping, fits image inside
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: `${colors.brand.brandBlack.rgbaString({
    alpha: 0.5,
  })}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
});

const HeaderImage: React.FC<HeaderImageProps> = ({ backgroundImageUrl }) => {
  return (
    <HeaderImageContainer backgroundImageUrl={backgroundImageUrl}>
      <Overlay />
    </HeaderImageContainer>
  );
};

export default HeaderImage;
