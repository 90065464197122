import { brand } from "./brand";
import { ColorDefinition, ColorDefinitionRGBA } from "./types";

function createColorDefinition(color: ColorDefinitionRGBA): ColorDefinition {
  return {
    rgba: color,
    rgbaString: (
      values = {
        red: color.red,
        green: color.green,
        blue: color.blue,
        alpha: color.alpha,
      }
    ) => {
      // If an object is provided but doesn't include certain colors.
      const {
        red = color.red,
        green = color.green,
        blue = color.blue,
        alpha = color.alpha,
      } = values;
      return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    },
    hex: `#${color.red.toString(16).padStart(2, "0")}${color.green
      .toString(16)
      .padStart(2, "0")}${color.blue.toString(16).padStart(2, "0")}`,
  };
}

export const colors = {
  brand: {
    brandBlack: createColorDefinition(brand.brandBlack),
    brandDarkRed: createColorDefinition(brand.brandDarkRed),
    brandSilver: createColorDefinition(brand.brandSliver),
  },
};
