import styled from "@emotion/styled";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { colors } from "../../types/colors";
import { MenuItemType } from "./Navbar";

// Styled components
const MobileNavbarWrapper = styled("div")<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => ({
    width: "100%",
    height: isCollapsed ? "68px" : "118px",
    backgroundColor: `${colors.brand.brandBlack.rgbaString()}`,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
    transition: "height 0.3s ease",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottom: `4px solid ${colors.brand.brandDarkRed.hex}`,
    borderTop: "4px solid #982925",
  })
);

const LogoWrapper = styled("div")<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => ({
    position: "absolute",
    left: isCollapsed ? "15%" : "50%",
    transform: isCollapsed ? "translateX(-15%)" : "translateX(-50%)",
    transition:
      "left 0.3s ease, transform 0.3s ease, width 0.3s ease, height 0.3s ease",
  })
);

const Logo = styled("img")<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  height: isCollapsed ? "64px" : "116px",
  objectFit: "cover",
  transition: "width 0.3s ease, height 0.3s ease",
}));

const BurgerMenu = styled.div({
  cursor: "pointer",
  fontSize: "24px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  padding: "16px",
});

const MobileMenu = styled("div")<{ isOpen: boolean; isCollapsed: boolean }>(
  ({ isOpen, isCollapsed }) => ({
    position: "fixed",
    top: isCollapsed ? "68px" : "118px",
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: colors.brand.brandBlack.hex,
    height: isOpen ? "100vh" : "0",
    overflowY: isOpen ? "auto" : "hidden", // Enable scrolling for larger content
    transition: "height 0.3s ease",
    zIndex: 999,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: isOpen ? "flex-start" : "center",
    paddingTop: isOpen ? "20px" : "0",
  })
);

const MenuLink = styled("a")({
  color: "white",
  textDecoration: "none",
  fontSize: "14px",
  width: "100%",
  textAlign: "left",
  padding: "15px 25px",
  display: "block",
  "&:hover": {
    color: "#ccc",
  },
});

const SubmenuWrapper = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  maxHeight: isOpen ? "500px" : "0",
  overflow: "hidden",
  transition: "max-height 0.3s ease",
  width: "100%",
  paddingLeft: "20px",
}));

const SubSubmenuWrapper = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  maxHeight: isOpen ? "500px" : "0",
  overflow: "hidden",
  transition: "max-height 0.3s ease",
  width: "100%",
  paddingLeft: "20px",
}));

const SubmenuToggle = styled("div")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "15px 25px",
  color: "white",
  fontSize: "14px",
  "&:hover": {
    color: "#ccc",
  },
});

const ChevronIcon = styled(FontAwesomeIcon)({
  fontSize: "18px",
  marginLeft: "10px",
});

const SubmenuItem = styled("div")({
  fontSize: "16px",
  "&:hover": {
    color: "#ccc",
  },
});

const SocialIconsWrapper = styled.div({
  display: "flex",
  marginTop: "5rem",
  justifyContent: "center",
  padding: "10px 0",
  width: "100%",
  left: 0,
  right: 0,
});

const SocialIconLink = styled("a")({
  margin: "0 15px",
  fontSize: "24px",
  color: "white",
  "&:hover": {
    color: "#ccc", // Change color on hover
  },
});

// Accordion-style menu rendering
const AccordionMenu = ({ menu }: { menu: any[] }) => {
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const [openSubSubMenu, setOpenSubSubMenu] = useState<string | null>(null);

  const handleToggleSubmenu = (title: string) => {
    setOpenSubmenu(openSubmenu === title ? null : title);
    setOpenSubSubMenu(null); // Close subSubMenu when toggling a new submenu
  };

  const handleToggleSubSubMenu = (title: string) => {
    setOpenSubSubMenu(openSubSubMenu === title ? null : title);
  };

  return (
    <>
      {menu.map((item) => (
        <div key={item.title} style={{ width: "100%" }}>
          {item.submenu ? (
            <>
              <SubmenuToggle onClick={() => handleToggleSubmenu(item.title)}>
                <span>{item.title}</span>
                <ChevronIcon
                  icon={
                    openSubmenu === item.title ? faChevronDown : faChevronRight
                  }
                />
              </SubmenuToggle>
              <SubmenuWrapper isOpen={openSubmenu === item.title}>
                {item.submenu.map((si: any) => (
                  <div key={si.title}>
                    {si.subSubMenu ? (
                      <>
                        <SubmenuToggle
                          onClick={() => handleToggleSubSubMenu(si.title)}
                        >
                          <span>{si.title}</span>
                          <ChevronIcon
                            icon={
                              openSubSubMenu === si.title
                                ? faChevronDown
                                : faChevronRight
                            }
                          />
                        </SubmenuToggle>
                        <SubSubmenuWrapper isOpen={openSubSubMenu === si.title}>
                          {si.subSubMenu.map((ssi: any) => (
                            <SubmenuItem key={ssi.title}>
                              <MenuLink href={ssi.url}>{ssi.title}</MenuLink>
                            </SubmenuItem>
                          ))}
                        </SubSubmenuWrapper>
                      </>
                    ) : (
                      <SubmenuItem>
                        <MenuLink href={si.url}>{si.title}</MenuLink>
                      </SubmenuItem>
                    )}
                  </div>
                ))}
              </SubmenuWrapper>
            </>
          ) : (
            <MenuLink href={item.url}>{item.title}</MenuLink>
          )}
        </div>
      ))}
    </>
  );
};

interface MobileNavbarProps {
  menuData: MenuItemType[];
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ menuData }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <MobileNavbarWrapper isCollapsed={isCollapsed}>
        <LogoWrapper isCollapsed={isCollapsed}>
          <a href="/">
            <Logo
              src="/logo.png"
              alt={"DTN Academy"}
              isCollapsed={isCollapsed}
            />
          </a>
        </LogoWrapper>
        <BurgerMenu onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </BurgerMenu>
      </MobileNavbarWrapper>

      <MobileMenu isOpen={isOpen} isCollapsed={isCollapsed}>
        <AccordionMenu menu={menuData} />
        <SocialIconsWrapper>
          <SocialIconLink
            href="https://www.facebook.com/DTNAcademy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.linkedin.com/company/dtn-academy-ltd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.instagram.com/dtnacademy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </SocialIconLink>
          <SocialIconLink
            href="https://x.com/DTN_Academy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </SocialIconLink>
          <SocialIconLink
            href="https://api.whatsapp.com/send?phone=447903202240"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </SocialIconLink>
        </SocialIconsWrapper>
      </MobileMenu>
    </>
  );
};

export default MobileNavbar;
