import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import { colors } from "../types/colors";

const PageWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh", // Ensures it takes at least the full viewport height
  maxWidth: "1280px",
  margin: "0 auto",
  boxSizing: "border-box",
  padding: "0 20px",

  // Ensure the footer space at the bottom
  paddingBottom: "100px", // Adjust based on the height of your footer

  "@media (max-width: 768px)": {
    marginTop: "7.5rem",
    paddingBottom: "120px", // Increase padding for smaller screens
  },
});

const InfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
  textAlign: "center",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  "@media (max-width: 768px)": {
    fontSize: "14px",
  },
});

const InfoLink = styled("a")({
  color: colors.brand.brandDarkRed.hex,
  cursor: "pointer",
  "&:hover": {
    color: colors.brand.brandBlack.hex,
    textDecoration: "underline",
  },
});

const ContentSection = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "2rem",
  marginTop: "1rem",
  gap: "2rem", // Spacing between columns
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
});

const TextSection = styled("div")({
  flex: 1,
  fontFamily: "AvanteGarde-Bold, sans-serif",
  lineHeight: "1.5",
});

const Paragraph = styled("p")({
  fontSize: "14px",
  marginBottom: "1rem",
});

const Heading = styled("h2")({
  fontSize: "20px",
  marginBottom: "1rem",
  fontWeight: "bold",
  borderBottom: `2px solid ${colors.brand.brandDarkRed.hex}`,
});

const ImageSection = styled("div")({
  flex: "0 0 40%", // Adjusts size of image container
  borderRadius: "8px",
  overflow: "hidden",
  marginTop: "2rem",
  boxShadow: `0px 4px 10px ${colors.brand.brandBlack.rgbaString({
    alpha: 0.2,
  })}`,
  "@media (max-width: 768px)": {
    margin: "0 auto",
    marginTop: "0",
    width: "100%",
    flex: "none",
  },
});

const Image = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
});

const MainTitle = styled("h1")({
  fontSize: "20px",
  fontWeight: "bold",
  color: colors.brand.brandDarkRed.hex,
  marginBottom: "0.5rem",
  borderBottom: `2px solid ${colors.brand.brandSilver.hex}`,
  "@media (max-width: 768px)": {
    textAlign: "center",
    fontSize: "18px",
  },
});

const SubTitle = styled("p")({
  fontSize: "16px",
  color: colors.brand.brandBlack.hex,
  "@media (max-width: 768px)": {
    textAlign: "center",
    fontSize: "14px",
  },
});

interface SkillsForLifeProps {
  // Add any props you need here
}

const SkillsForLife: React.FC<SkillsForLifeProps> = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>DTN - Skills For Life</title>
        <meta
          name="description"
          content="Our skills bootcamp offers flexible courses for up to 16 weeks, giving you the opportunity to develop your skills."
        />
      </Helmet>
      <Breadcrumb
        items={[{ label: "Home", href: "/" }, { label: "Skills For Life" }]}
      />
      <HeaderImage
        backgroundImageUrl={`https://dtnacademy.lon1.cdn.digitaloceanspaces.com/uploads/banner/PIA%20COURSE%20BANNER.png`}
      />
      <InfoWrapper>
        <span>
          For all course enquiries please contact{" "}
          <InfoLink href="mailto:training@dtnacademy.com">
            training@dtnacademy.com
          </InfoLink>
        </span>
      </InfoWrapper>
      <ContentSection>
        <TextSection>
          <MainTitle>
            Our Skills Bootcamp offers flexible courses for up to 16 weeks
          </MainTitle>
          <SubTitle>
            DTN Academy offers Skills for Life Bootcamps in Telecoms and Civils.
          </SubTitle>
          <Paragraph>
            Are you 19 or over and looking for a brand-new career? Our Skills
            Bootcamps are a new set of training courses designed to help adults
            build sector-specific skills with a guaranteed interview after
            course completion.
          </Paragraph>
          <Paragraph>
            Delivered in partnership with employers, providers, and local
            authorities, our Skills Bootcamps help support skills demands in the
            local area and ultimately help people get better jobs.
          </Paragraph>

          <Heading>Who is eligible for our Skills Bootcamps?</Heading>
          <Paragraph>
            The Skills Bootcamp is designed for adults, aged 19 and over, who
            want to quickly increase their skills in a specific industry area.
            You must be living in the Tees Valley.
          </Paragraph>
          <Paragraph>
            Experience in the industry sector is not required, just a good
            understanding of the English language.
          </Paragraph>

          <Heading>Employers</Heading>
          <Paragraph>
            Employers who would like to access courses for existing staff are
            required to contribute to the cost of the learning (10% for SMEs and
            30% for large employers). The cost varies for each Skills Bootcamp.
          </Paragraph>
        </TextSection>

        <ImageSection>
          <Image
            src={`${process.env.REACT_APP_CDN_HOST}/static/skills-for-life-card`}
            alt="Skills Bootcamp Image"
          />
        </ImageSection>
      </ContentSection>
    </PageWrapper>
  );
};

export default SkillsForLife;
