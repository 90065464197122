import React, { useEffect } from "react";
import { getApiInstance } from "../../hooks/useApi";
import useIsMobile from "../../hooks/useIsMobile";
import { useToast } from "../../hooks/useToast";
import LoadingDots from "../misc/LoadingDots";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export type MenuItemType = {
  title: string;
  url: string;
  submenu?: MenuItemType[];
};

const Navbar: React.FC = () => {
  const isMobile = useIsMobile();
  const { showToast } = useToast();
  const [menuData, setMenuData] = React.useState<MenuItemType[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    getApiInstance()
      .get("api/nav")
      .then((response) => {
        setMenuData(response.data);
      })
      .catch((error) => {
        showToast("Failed to fetch nav links", "error");
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [showToast]);

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  return isMobile || window.innerWidth <= 1024 ? (
    <MobileNavbar menuData={menuData} />
  ) : (
    <DesktopNavbar menuData={menuData} />
  );
};

export default Navbar;
