import styled from "@emotion/styled";

const ToggleContainer = styled("div")<{ active: boolean }>(({ active }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "60px", // Adjusted for better text spacing
  height: "30px",
  borderRadius: "16px",
  backgroundColor: active ? "#4CAF50" : "#ddd",
  padding: "0 10px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
}));

const ToggleText = styled("span")<{ active: boolean }>(({ active }) => ({
  position: "relative",
  zIndex: 2, // Ensure text is above the circle
  fontWeight: "bold",
  fontSize: "12px",
  fontFamily: "Avantegarde-Bold, sans-serif",
  color: "#000", // Adjust color based on state
  transition: "color 0.3s ease",
  visibility: !active ? "visible" : "hidden", // Hide inactive text
}));

const ToggleCircle = styled("div")<{ active: boolean }>(({ active }) => ({
  position: "absolute",
  top: "4px", // Adjust for centering
  left: active ? "calc(100% - 25px)" : "3px", // Adjust based on toggle state
  width: "22px",
  height: "22px",
  borderRadius: "50%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  transition: "left 0.3s ease",
}));

interface ToggleProps {
  active: boolean;
  onToggle: (newActiveState: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ active, onToggle }) => {
  const handleToggle = () => {
    onToggle(!active); // Pass the new active state to the parent
  };

  return (
    <ToggleContainer active={active} onClick={handleToggle}>
      <ToggleText active={!active}>NO</ToggleText>
      <ToggleCircle active={active} />
      <ToggleText active={active}>YES</ToggleText>
    </ToggleContainer>
  );
};

export default Toggle;
