import styled from "@emotion/styled";
import { colors } from "../../types/colors";

const CourseDetailPartWrapper = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  marginBottom: "8px",

  // Media query for mobile view
  "@media (max-width: 768px)": {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
});

const LeftBox = styled("div")({
  backgroundColor: colors.brand.brandBlack.hex,
  fontFamily: "AvanteGarde-Bold, sans-serif",
  color: "white",
  padding: "10px 40px 10px 30px",
  fontWeight: "bold",
  position: "relative",
  zIndex: 1,
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderTopRightRadius: "15px",
  // Media query for mobile view
  "@media (max-width: 768px)": {
    padding: "10px 20px",
    textAlign: "center",
    width: "95%",
    borderRadius: "8px",
  },
});

const AngledBorder = styled("div")({
  position: "absolute",
  right: "-3px",
  top: 0,
  bottom: 0,
  width: "10px",
  backgroundColor: "white",
  zIndex: -1,
  transform: "skewX(+20deg)",
  borderLeft: `5px solid ${colors.brand.brandDarkRed.hex}`,
  borderRight: `5px solid ${colors.brand.brandDarkRed.hex}`,
  borderEndEndRadius: "8px",

  // Media query for mobile view
  "@media (max-width: 768px)": {
    position: "relative",
    right: "auto",
    top: "auto",
    bottom: 0,
    width: "100%",
    // height: "2px",
    transform: "skewX(0deg)",
    borderLeft: "none",
    borderRight: "none",

    borderBottom: "3px solid #c40000",
    marginTop: "5px",
  },
});

const RightBox = styled("div")({
  position: "relative",
  padding: "10px 20px",
  marginLeft: "30px",
  border: `1px solid ${colors.brand.brandSilver.hex}`,
  borderRadius: "8px",
  color: "#707070",
  // fontWeight: "bold",
  backgroundColor: "#f4f4f4",
  fontFamily: "AvanteGarde-Bold, sans-serif",

  // Media query for mobile view
  "@media (max-width: 768px)": {
    marginTop: "10px",
    marginLeft: 0,
    width: "95%",
    textAlign: "left",
    justifySelf: "center",
  },
});

interface CourseDetailPartProps {
  title: string;
  value: string;
}

const CourseDetailPart = ({ title, value }: CourseDetailPartProps) => {
  return (
    <CourseDetailPartWrapper>
      <LeftBox>
        {title}
        <AngledBorder />
      </LeftBox>
      {value && value.length > 0 && <RightBox>{value}</RightBox>}
    </CourseDetailPartWrapper>
  );
};

export default CourseDetailPart;
