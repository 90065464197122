import { useQuery, UseQueryResult } from "react-query";
import { Story } from "../../types"; // Import your types
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

// Define your API fetching logic with return types
const fetchStory = async (slug: string): Promise<Story> => {
  const response = await getApiInstance().get<Story>(
    `/api/success-stories/${slug}`
  );
  return response.data;
};

// Custom hook using React Query with typed result
export const useGetStory = (slug: string): UseQueryResult<Story> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch categories with typed data
  return useQuery<Story, unknown>(
    ["story", slug], // Query key (based on the slug)
    () => fetchStory(slug), // API fetching function
    {
      enabled: !!slug, // Only fetch if slug is available
      onError: (error: any) => {
        showToast("Error fetching story ", "error");
        console.error("Error fetching story:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache result for 5 minutes
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );
};
