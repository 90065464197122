import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

const AcademySectionContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "2rem",
  backgroundColor: colors.brand.brandBlack.rgbaString({ alpha: 0.6 }),
  borderRadius: "8px",
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: "100%",
  boxSizing: "border-box",
  minHeight: "400px",
  margin: "1rem",

  // Mobile and Tablet responsiveness
  "@media (max-width: 960px)": {
    flexDirection: "column", // Stack elements vertically on tablets and mobile
    padding: "1.5rem", // Reduce padding on smaller screens
  },

  "@media (max-width: 600px)": {
    padding: "1rem", // Further reduce padding on mobile screens
  },
});

const TextContainer = styled("div")({
  flex: 1,
  paddingRight: "1rem",
  color: "white",
  minWidth: "300px",
  boxSizing: "border-box",
  width: "100%",

  // Mobile and Tablet responsiveness
  "@media (max-width: 960px)": {
    paddingRight: "0", // Remove right padding on tablets and mobile
    textAlign: "center", // Center text on smaller screens
  },
});

const HeaderText = styled("h1")({
  fontFamily: "AvantGarde-Bold, sans-serif",
  color: "white",
  textAlign: "center",

  // Mobile and Tablet responsiveness
  "@media (max-width: 960px)": {
    fontSize: "1.5rem", // Adjust font size for smaller screens
  },

  "@media (max-width: 600px)": {
    fontSize: "1.25rem", // Further adjust for mobile screens
  },
});

const ContentText = styled("p")({
  color: "white",
  fontFamily: "AvantGarde-Regular, sans-serif",

  // Mobile and Tablet responsiveness
  "@media (max-width: 960px)": {
    fontSize: "0.875rem", // Make font smaller for tablets
  },

  "@media (max-width: 600px)": {
    fontSize: "0.75rem", // Further adjust for mobile screens
  },
});

const IframeContainer = styled("div")({
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  marginTop: "1rem",

  // Mobile and Tablet responsiveness
  "@media (max-width: 960px)": {
    width: "100%", // Ensure iframe takes full width on smaller screens
    marginTop: "1.5rem", // Add more spacing on smaller screens
  },
});

const Iframe = styled("iframe")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  border: "none",
  borderRadius: "8px",
});

const AcademySectionTest: React.FC = () => {
  return (
    <AcademySectionContainer>
      <TextContainer>
        <HeaderText>Digital Telecoms Network Academy</HeaderText>
        <ContentText style={{ marginBottom: "1rem" }}>
          Digital Telecoms Network Academy provides a comprehensive learning
          experience for the Telecommunications Industry, with blended practical
          training to enhance or embrace new skills for the ongoing Digital
          Development for the Smart City Infrastructure.
        </ContentText>
        <ContentText>
          We are working as part of the Digital Policy Alliance (DPA), with the
          Digital Skills Group, servicing the industry to meet the employment
          gaps and skills shortages nationwide.
        </ContentText>
      </TextContainer>
      <IframeContainer>
        <Iframe
          src="https://www.youtube.com/embed/KeDHo0c8XW8"
          title="DTN Academy"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IframeContainer>
    </AcademySectionContainer>
  );
};

export default AcademySectionTest;
