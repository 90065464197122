import styled from "@emotion/styled";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { colors } from "../types/colors";

// Styled Components
const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  textAlign: "center",
  backgroundColor: "#f0f0f0",
});

const Title = styled("h1")({
  fontSize: "48px",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  marginBottom: "20px",
  color: colors.brand.brandDarkRed.hex,
});

const Message = styled("p")({
  fontSize: "24px",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  marginBottom: "30px",
  color: colors.brand.brandBlack.hex,
});

const StyledLink = styled(Link)({
  fontSize: "20px",
  color: colors.brand.brandDarkRed.hex,
  fontFamily: "AvanteGarde-Bold, sans-serif",
  textDecoration: "none",
  padding: "10px 20px",
  border: `2px solid ${colors.brand.brandDarkRed.hex}`,
  borderRadius: "8px",
  transition: "background-color 0.3s ease",

  "&:hover": {
    color: colors.brand.brandBlack.hex,
    border: `2px solid ${colors.brand.brandBlack.hex}`,
  },
});

const Icon = styled(FontAwesomeIcon)({
  fontSize: "72px",
  color: colors.brand.brandDarkRed.hex,
});

const NotFound: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>DTN - Oops Page Not Found</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides practical and theoretical courses for the telecommunications industry."
        />
      </Helmet>
      <Container>
        <Icon icon={faExclamationTriangle} />
        <Title>404</Title>
        <Message>Oops! The page you're looking for doesn't exist.</Message>
        <StyledLink to="/">Go Back Home</StyledLink>
      </Container>
    </>
  );
};

export default NotFound;
