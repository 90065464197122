import styled from "@emotion/styled";
import React from "react";

const MissionSection = styled.div`
  max-width: 1280px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f5f5f5; /* Light grey background for the section */
`;

const SectionTitle = styled.h1`
  color: #a81010;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1; /* Both columns have the same flex value for even spacing */
  color: #333;

  h3 {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
`;

const RightColumn = styled.div`
  flex: 1; /* Both columns have the same flex value for even spacing */
  color: #333;

  h3 {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5rem;

    li {
      font-size: 1rem;
      line-height: 1.6;
      margin-bottom: 0.5rem;

      span {
        font-weight: bold; /* Make the span bold */
      }
    }
  }
`;

const MissionStatement: React.FC = () => {
  return (
    <MissionSection>
      <SectionTitle>DTN Academy’s Mission Statement</SectionTitle>
      <ContentContainer>
        <LeftColumn>
          <h3>Vision</h3>
          <p>
            The ambition of the DTN Academy in the coming years is to be
            recognised by industry as a major local and national training force
            in the field of digital and telecommunications provision.
          </p>
          <h3>Mission</h3>
          <p>
            Our mission is to work in partnership with employers to provide
            quality accredited training leading to measurable business impact.
            Our mission for learners is to provide accredited training that is
            current, practical, engaging and challenging that leads to
            sustainable employment. Our overarching ambition is to be recognised
            as the leading telecoms training provider regionally and nationally.
          </p>
        </LeftColumn>
        <RightColumn>
          <h3>Values</h3>
          The values of the DTN Academy:
          <ul>
            <li>
              <span>LEARNERS FIRST</span> – high performance and achievement of
              our learners
            </li>
            <li>
              <span>PARTNERSHIPS</span> – with employers, educational
              organisations, and agencies
            </li>
            <li>
              <span>FAIRNESS</span> – treating all fairly and with respect
            </li>
            <li>
              <span>RESPONSIBLE</span> – accountable for our actions
            </li>
            <li>
              <span>OPENNESS</span> – transparent in our transactions
            </li>
            <li>
              <span>CONTINUOUS IMPROVEMENT</span> – continuous reflection,
              consultation and improving the quality of our provision
            </li>
            <li>
              <span>PROFITABLE AND FINANCIALLY SOUND</span> – prudent in our
              spending and investments
            </li>
          </ul>
        </RightColumn>
      </ContentContainer>
    </MissionSection>
  );
};

export default MissionStatement;
