import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import ErrorMessage from "../../components/misc/ErrorMessage";
import LoadingDots from "../../components/misc/LoadingDots";
import { useGetOtherCourseBySubcategorySlug } from "../../hooks/queries/useGetOtherCourseBySubcategorySlug";
import { CourseSubCategoryType } from "../../types";
import { colors } from "../../types/colors";
import { decodeBase64Markdown } from "../../utils/decodeBase64Markdown";
import TestimonialCard from "./TestimonialCard";

const StyledLink = styled.a({
  color: colors.brand.brandDarkRed.hex, // Custom red color for the links
  textDecoration: "underline",
  "&:hover": {
    color: colors.brand.brandDarkRed.hex, // Keep it red when hovered
  },
});

const FlexRow = styled("div")<{ isFirst?: boolean; isLast?: boolean }>(
  ({ isFirst, isLast }) => ({
    display: "flex",
    justifyContent: "space-between", // Create space between the two divs
    alignItems: "center", // Vertically center the content
    width: "100%", // Ensure it takes up the full width
    padding: "15px 20px", // Padding for top/bottom and left/right
    boxSizing: "border-box", // Ensure padding does not affect the width
    fontFamily: "AvanteGarde-Bold, sans-serif",
    borderTop: isFirst ? `1px solid ${colors.brand.brandSilver.hex}` : "none", // Border on top only for the first row
    borderBottom: isLast
      ? `1px solid ${colors.brand.brandSilver.hex}`
      : `1px solid ${colors.brand.brandSilver.rgbaString({ alpha: 0.2 })}`, // Bottom border for the last row
    transition: "background-color 0.3s ease", // Add a smooth transition effect

    "&:hover": {
      backgroundColor: colors.brand.brandSilver.rgbaString({ alpha: 0.2 }), // Change background color on hover
    },
    "@media (max-width: 768px)": {
      flexDirection: "column", // Stack items vertically on mobile
      alignItems: "flex-start", // Align text to the left on mobile
      padding: "10px 15px", // Reduce padding on mobile
      fontSize: "14px", // Reduce font size on mobile
    },
  })
);

const LayoutWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: "20px", // Add some space between the two columns
  "@media (max-width: 768px)": {
    flexDirection: "column", // Stack columns on top of each other on mobile
    padding: "0 15px", // Add padding to the sides on mobile
  },
});

const LeftColumn = styled("div")({
  flex: 2, // Take up more space than the right column
  fontSize: "16px",
  lineHeight: "1.6",
  "@media (max-width: 768px)": {
    fontSize: "14px",
  },
});

const RightColumn = styled("div")({
  flex: 1, // Takes up less space
  display: "flex",
  flexDirection: "column",
  gap: "20px", // Add space between the images or blocks
  img: {
    width: "100%",
    borderRadius: "8px",
    boxShadow: `0px 4px 10px ${colors.brand.brandBlack.rgbaString({
      alpha: 0.5,
    })}`,
    marginTop: "20px",
    marginBottom: "10px",
  },
  "@media (max-width: 768px)": {
    img: {
      marginBottom: "10px",
    },
  },
});

const FormerSection = styled("div")({
  display: "grid",
  width: "100%", // Ensure it spans the full width
  gridTemplateColumns: "1fr 1fr 1fr", // 3 columns of equal width
  gap: "20px", // Gap between grid items
  marginTop: "1rem",
  marginBottom: "1rem",

  // Adjust for mobile view
  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr", // Stack the items in one column on mobile
  },
});

const ValueWrapper = styled("div")({
  color: colors.brand.brandDarkRed.hex,
  "@media (max-width: 768px)": {
    marginTop: "5px", // Add some space between the text and the value on mobile
  },
});

interface OtherCoursePageProps {
  admin?: boolean;
  subCategorySlug: string;
}

const OtherCoursePage: React.FC<OtherCoursePageProps> = ({
  admin = false,
  subCategorySlug,
}) => {
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
    refetch: fetchCourse,
  } = useGetOtherCourseBySubcategorySlug(subCategorySlug);

  useEffect(() => {
    if (subCategorySlug) {
      fetchCourse();
    }
  }, [fetchCourse, subCategorySlug]);

  if (isCourseLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCourseError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`DTN - ${course?.name}`}</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <FlexRow isFirst={true}>
        <div>Duration:</div>
        <ValueWrapper>{course?.duration}</ValueWrapper>
      </FlexRow>
      <FlexRow>
        <div>Course Code:</div>
        <ValueWrapper>{course?.courseCode}</ValueWrapper>
      </FlexRow>
      <FlexRow isLast={true}>
        <div>FT/PT:</div>
        <ValueWrapper>{course?.employment}</ValueWrapper>
      </FlexRow>
      <LayoutWrapper>
        <LeftColumn>
          {course?.employment && (
            <ReactMarkdown
              components={{
                a: ({ href, children }) => (
                  <StyledLink href={href}>{children}</StyledLink>
                ),
              }}
            >
              {decodeBase64Markdown(
                course?.content ||
                  "Tm8gY29udGVudCBhdmFpbGFibGUgZm9yIHRoaXMgY291cnNl"
              )}
            </ReactMarkdown>
          )}
        </LeftColumn>
        <RightColumn>
          {course?.images.map((image) => (
            <>
              <img
                key={image.otherCourseImageId}
                src={image.imageUrl}
                alt="Course"
              />
            </>
          ))}
          <div>
            {course?.type === CourseSubCategoryType.APPRENTICESHIP && (
              <>
                <h4>How well are we doing?</h4>
                <p>
                  73.3% of our apprentices have achieved a Distinction grade!
                </p>
              </>
            )}
          </div>
        </RightColumn>
      </LayoutWrapper>
      {course && course?.stories?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div>
            <h3>
              Don’t just take our word for it, see what our current and former
              apprentices have to say……
            </h3>
            <FormerSection>
              {course?.stories?.map((story) => (
                <>
                  <TestimonialCard
                    key={story.otherCourseStoryId}
                    imageUrl={story.imageUrl}
                    content={decodeBase64Markdown(story.content)}
                  />
                </>
              ))}
            </FormerSection>
          </div>
        </div>
      )}
    </>
  );
};

export default OtherCoursePage;
