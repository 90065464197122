import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

// Define the animation for the dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Style the container that holds the dots and center it in the parent container
const LoadingContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)", // Center it horizontally and vertically
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// Style each dot and apply the animation
const Dot = styled("div")<{ color: string }>(({ color }) => ({
  width: "15px",
  height: "15px",
  margin: "0 5px",
  backgroundColor: color,
  borderRadius: "50%",
  display: "inline-block",
  animation: `${bounce} 1.4s infinite ease-in-out both`,

  "&:nth-of-type(1)": {
    animationDelay: "-0.32s",
  },
  "&:nth-of-type(2)": {
    animationDelay: "-0.16s",
  },
  "&:nth-of-type(3)": {
    animationDelay: "0s",
  },
}));

const SmallLoadingDots: React.FC = () => {
  return (
    <LoadingContainer>
      <Dot color={colors.brand.brandDarkRed.hex} />
      <Dot color={colors.brand.brandBlack.hex} />
      <Dot color={colors.brand.brandSilver.hex} />
    </LoadingContainer>
  );
};

export default SmallLoadingDots;
