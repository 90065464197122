import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { getApiInstance } from "../../hooks/useApi";
import CourseCard from "../../pages/admin/courses/CourseCard";
import { colors, Course } from "../../types";
import SmallLoadingDots from "../misc/SmallLoadingDots";

const RelatedCoursesContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "1px solid #e0e0e0",
  margin: "1rem 0",
  marginBottom: "2rem",
  position: "relative", // Make sure it's positioned relatively for absolute elements to work
});

const PaginationControls = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: 25,
  right: 25,
  transform: "translateY(-50%)",
  padding: "0 1rem",
});

const PaginationIcon = styled(FontAwesomeIcon)({
  fontSize: "1.5rem",
  color: "#333",
  cursor: "pointer",
  transition: "color 0.2s ease",

  "&:hover": {
    color: "#555",
  },

  "&[disabled]": {
    cursor: "not-allowed",
    opacity: 0.5,
  },
});

const CardsGrid = styled("div")({
  display: "grid",
  alignSelf: "center",
  gap: "32px",
  gridTemplateColumns: "repeat(3, 1fr)", // Default for desktops
  justifyContent: "center",

  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(2, 1fr)", // For tablets
  },

  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr", // For mobile
  },
});

const PaginationDots = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
  gap: "0.5rem",
});

const Dot = styled("span")<{
  isActive: boolean;
}>(({ isActive }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: isActive
    ? colors.brand.brandDarkRed.hex
    : colors.brand.brandSilver.hex,
  cursor: "pointer",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: isActive ? "#444" : "#aaa",
  },
}));

const Title = styled("h3")({
  textAlign: "center", // Center the title by default
  fontSize: "1.4rem", // Default font size for desktop
  fontFamily: "Neuropolitical, sans-serif",

  "@media (max-width: 768px)": {
    fontSize: "1rem", // Smaller font size for mobile
  },
});

interface RelatedCoursesProps {
  courseId: string;
  subCategoryId: string;
}

const RelatedCourses: React.FC<RelatedCoursesProps> = ({
  courseId,
  subCategoryId,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relatedCourses, setRelatedCourses] = useState<Course[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [limit, setLimit] = useState<number>(0); // Default limit for desktop

  // Update the limit dynamically based on screen width
  useEffect(() => {
    const updateLimit = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setLimit(1); // Mobile view
      } else if (width <= 1024) {
        setLimit(2); // Tablet view
      } else {
        setLimit(3); // Desktop view
      }
    };

    updateLimit();
    window.addEventListener("resize", updateLimit);

    return () => {
      window.removeEventListener("resize", updateLimit);
    };
  }, [limit]);

  useEffect(() => {
    if (!subCategoryId || !courseId || limit === 0) {
      return;
    }

    setIsLoading(true);
    getApiInstance()
      .get(
        `/api/courses/${subCategoryId}/related?excludedCourseId=${courseId}&page=${currentPage}&limit=${limit}`
      )
      .then((response) => {
        setTotalPages(response.data.totalPages);
        setRelatedCourses(response.data?.items);
      })
      .finally(() => setIsLoading(false));
  }, [courseId, currentPage, subCategoryId, limit]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDotClick = (page: number) => {
    setCurrentPage(page);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextPage,
    onSwipedRight: handlePrevPage,
    preventScrollOnSwipe: true, // Prevent scrolling when swiping
    trackMouse: true, // Optional for desktop support
  });

  return (
    <>
      {relatedCourses.length > 0 && (
        <div style={{ position: "relative" }}>
          <RelatedCoursesContainer {...swipeHandlers}>
            {isLoading && <SmallLoadingDots />}

            <Title>Related Courses</Title>
            <PaginationControls>
              <PaginationIcon
                icon={faChevronLeft}
                onClick={handlePrevPage}
                style={{
                  pointerEvents: currentPage === 1 ? "none" : "auto",
                  visibility: currentPage === 1 ? "hidden" : "visible",
                }}
              />
              <PaginationIcon
                icon={faChevronRight}
                onClick={handleNextPage}
                style={{
                  pointerEvents: currentPage === totalPages ? "none" : "auto",
                  visibility: currentPage === totalPages ? "hidden" : "visible",
                }}
              />
            </PaginationControls>
            <CardsGrid>
              {relatedCourses.map((c) => (
                <CourseCard
                  onClick={() => {
                    navigate(
                      `/our-courses/${c.categorySlug}/${c.subCategorySlug}/${c.slug}`
                    );
                  }}
                  key={c.courseId}
                  logoUrl={"/logo.png"}
                  imageUrl={c.imageUrl}
                  title={c.name}
                  description={c.description}
                />
              ))}
            </CardsGrid>
            <PaginationDots>
              {Array.from({ length: totalPages }, (_, index) => (
                <Dot
                  key={index}
                  isActive={index + 1 === currentPage}
                  onClick={() => handleDotClick(index + 1)}
                />
              ))}
            </PaginationDots>
          </RelatedCoursesContainer>
        </div>
      )}
    </>
  );
};

export default RelatedCourses;
