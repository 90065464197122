import axios from "axios";
import { useCallback } from "react";
import { navigateToLogin } from "../utils/Navigation";

interface ErrorResponseData {
  message?: string;
}

export function useApi() {
  const getInstance = useCallback(() => {
    return getApiInstance();
  }, []);

  return { getInstance };
}

export const getApiInstance = () => {
  const accessToken = getAuthToken();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const data = error.response.data as ErrorResponseData;

      if (error.response.status === 401 || data?.message === "Token expired") {
        localStorage.removeItem("authToken");
        navigateToLogin();
      }
    }
  );

  return instance;
};

const getAuthToken = () => {
  return localStorage.getItem("authToken");
};
