import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

// Styled components
const InfoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "1rem",
  textAlign: "center",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  "@media (max-width: 768px)": {
    fontSize: "12px",
  },
});

const InfoLink = styled("a")({
  color: colors.brand.brandDarkRed.hex,
  cursor: "pointer",
  "&:hover": {
    color: colors.brand.brandBlack.hex,
    textDecoration: "underline",
  },
});

// InfoSection Component
const InfoSection: React.FC = () => {
  return (
    <InfoWrapper>
      <span>
        For all course enquiries please contact{" "}
        <InfoLink href="mailto:training@dtnacademy.com">
          training@dtnacademy.com
        </InfoLink>
      </span>
    </InfoWrapper>
  );
};

export default InfoSection;
