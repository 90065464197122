import styled from "@emotion/styled";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Slider } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useGetPartnerCarousel } from "../../hooks/queries";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { colors, PartnerCarousel } from "../../types";
import { getCroppedImg } from "../../utils/cropImage";
import Button from "../form/Button";
import {
  DeleteIcon,
  FormWrapper,
  HR,
  ImageContainer,
  InputField,
  InputWrapper,
  Label,
} from "../form/Styles";
import Toggle from "../form/Toggle";
import ErrorMessage from "../misc/ErrorMessage";
import LoadingDots from "../misc/LoadingDots";
import Modal from "../misc/Modal";
import { UploadInput } from "./ImagesTab";

const PartnerCarouselContainer = styled("div")({
  position: "relative",
  padding: "20px",
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
  maxWidth: "1280px",
  "@media (max-width: 1024px)": {
    padding: "16px",
  },
  "@media (max-width: 768px)": {
    padding: "10px",
    maxWidth: "100%",
  },
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  maxWidth: "100%",
  margin: "0 auto",
  marginBottom: "16px",
});

const ItemsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "16px",
  margin: "0 auto",
  maxWidth: "1280px",
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    justifyItems: "center", // Center the items on mobile
  },
});

const CarouselItem = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  width: "300",
  cursor: "pointer",
  "@media (max-width: 1024px)": {
    width: "200px",
    height: "auto",
  },
  "@media (max-width: 768px)": {
    width: "calc(100% - 32px)",
    height: "auto",
  },

  img: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
    borderRadius: "4px",
    marginBottom: "10px",
  },

  p: {
    margin: "5px 0",
    color: "#333",
    fontSize: "14px",
    "@media (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
});

const ImageButton = styled("label")({
  position: "absolute",
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  padding: "8px 16px",
  border: "none",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "bold",
  cursor: "pointer",
  zIndex: 2,
  transition: "background-color 0.3s ease, transform 0.2s ease",

  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
  },
});

const PartnerCarouselTab: React.FC = () => {
  const { showToast } = useToast();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [carouselItem, setCarouselItem] = React.useState<PartnerCarousel>({
    created: "",
    partnerCarouselId: "",
    name: "",
    image: "",
    link: "",
    active: false,
    order: 0,
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>();

  const {
    data,
    isLoading,
    isError,
    refetch: fetchPartnerCarouselItems,
  } = useGetPartnerCarousel();

  useEffect(() => {
    fetchPartnerCarouselItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cropper state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [aspectRatio] = useState(250 / 100);
  const onCropComplete = (
    _croppedAreaPercentage: Area,
    croppedAreaPixels: Area
  ) => {
    setCroppedArea(croppedAreaPixels);
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  if (isError) {
    return <ErrorMessage message="Error fetching partner carousel items" />;
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setCarouselItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    } else {
      setPreviewUrl("");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !croppedArea) return;

    if (!previewUrl) {
      showToast("Preview URL is not available", "error");
      return;
    }

    try {
      const croppedImageBlob = await getCroppedImg(previewUrl, croppedArea);

      if (!croppedImageBlob) {
        showToast("Cropped image is not available", "error");
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result as string;
        try {
          setIsUploading(true);
          getApiInstance()
            .post("/api/dtn/carousel/partner", {
              base64Image,
              name: carouselItem.name,
              link: carouselItem?.link,
              active: carouselItem.active,
            })
            .then((response) => {
              if (response.status === 200) {
                setSelectedFile(null);
                setPreviewUrl("");
                setCarouselItem({
                  created: "",
                  partnerCarouselId: "",
                  name: "",
                  image: "",
                  link: "",
                  active: true,
                  order: 0,
                });

                setIsAddModalOpen(false);

                fetchPartnerCarouselItems();

                showToast("Carousel Item Added", "success");
              } else {
                showToast("Error uploading image", "error");
              }
            });
        } catch (error) {
          console.error("Error uploading image:", error);
          showToast("Error uploading image", "error");
        } finally {
          setIsUploading(false);
        }
      };
      reader.onerror = () => {
        showToast("Error reading image file", "error");
      };

      reader.readAsDataURL(croppedImageBlob);
    } catch (error) {
      console.error("Error cropping image:", error);
      showToast("Error cropping image", "error");
    }
  };

  const handleToggle = (newActiveState: boolean) => {
    setCarouselItem((prevItem) => ({
      ...prevItem,
      active: newActiveState,
    }));
  };

  const handlePatchActive = (partnerCarouselId: string, active: boolean) => {
    getApiInstance()
      .patch(`/api/dtn/carousel/partner/${partnerCarouselId}`, { active })
      .then((response) => {
        if (response.status === 204) {
          fetchPartnerCarouselItems();
          showToast("Carousel Item Updated", "success");
        } else {
          showToast("Error updating carousel item", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating carousel item:", error);
        showToast("Error updating carousel item", "error");
      });
  };

  const handleSave = () => {
    getApiInstance()
      .patch(`/api/dtn/carousel/partner/${carouselItem.partnerCarouselId}`, {
        name: carouselItem.name,
        link: carouselItem.link,
        active: carouselItem.active,
      })
      .then((response) => {
        if (response.status === 204) {
          fetchPartnerCarouselItems();
          setIsEditModalOpen(false);
          setCarouselItem({
            created: "",
            partnerCarouselId: "",
            name: "",
            image: "",
            link: "",
            active: true,
            order: 0,
          });
          showToast("Carousel Item Updated", "success");
        } else {
          showToast("Error updating carousel item", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating carousel item:", error);
        showToast("Error updating carousel item", "error");
      });
  };

  const handleDelete = (partnerCarouselId: string) => {
    getApiInstance()
      .delete(`/api/dtn/carousel/partner/${partnerCarouselId}`)
      .then((response) => {
        if (response.status === 204) {
          fetchPartnerCarouselItems();
          showToast("Carousel Item Deleted", "success");
        } else {
          showToast("Error deleting carousel item", "error");
        }
      })
      .catch((error) => {
        console.error("Error deleting carousel item:", error);
        showToast("Error deleting carousel item", "error");
      });
  };

  return (
    <PartnerCarouselContainer>
      <ButtonContainer>
        <div style={{ width: 100 }}>
          <Button label="Add New" onClick={() => setIsAddModalOpen(true)} />
        </div>
      </ButtonContainer>
      <HR
        style={{
          borderBottom: `1px solid ${colors.brand.brandSilver.hex}`,
          marginBottom: 16,
        }}
      />
      <ItemsContainer>
        {!isLoading && (data?.carouselItems?.length ?? 0) > 0 ? (
          data?.carouselItems.map((item) => (
            <CarouselItem key={item.partnerCarouselId}>
              <DeleteIcon
                icon={faTrash}
                style={{ top: "10px", left: "10px" }}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setCarouselItem(item);
                }}
              />
              <Button
                label={item.active ? "Active" : "Inactive"}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: item.active
                    ? "#4caf50"
                    : colors.brand.brandDarkRed.hex,
                  fontSize: "12px",
                  cursor: "pointer",
                  width: "65px",
                }}
                onClick={() =>
                  handlePatchActive(item.partnerCarouselId, !item.active)
                }
              />

              <img
                src={item.image}
                alt={item.name}
                onClick={() => {
                  setIsEditModalOpen(true);
                  setCarouselItem(item);
                }}
              />
              <p>{item.name}</p>
            </CarouselItem>
          ))
        ) : (
          <p>No carousel images</p>
        )}
      </ItemsContainer>

      {isAddModalOpen && (
        <Modal
          title="Add Partner Carousel Item"
          onClose={() => {
            setIsAddModalOpen(false);
            setCarouselItem({
              created: "",
              partnerCarouselId: "",
              name: "",
              image: "",
              link: "",
              active: true,
              order: 0,
            });
            setSelectedFile(null);
            setPreviewUrl("");
          }}
          isOpen={isAddModalOpen}
        >
          <FormWrapper>
            <HR style={{ marginTop: 0 }} />
            <InputWrapper>
              <InputField
                type="text"
                name="name"
                value={carouselItem.name}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Name</Label>
            </InputWrapper>
            <InputWrapper>
              <InputField
                type="text"
                name="link"
                value={carouselItem.link}
                onChange={handleInputChange}
                placeholder=" "
              />
              <Label>Link (Optional)</Label>
            </InputWrapper>
            <div>
              {previewUrl ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ position: "relative", width: "100%", height: 300 }}
                  >
                    <Cropper
                      image={previewUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspectRatio}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                    />
                  </div>
                  <div
                    style={{ width: "90%", alignSelf: "center", marginTop: 8 }}
                  >
                    <Slider
                      style={{ color: colors.brand.brandDarkRed.hex }}
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      onChange={(e, zoom) => setZoom(zoom as number)}
                      aria-labelledby="Zoom"
                    />
                  </div>
                </div>
              ) : (
                <>
                  <ImageContainer style={{ width: "100%" }}>
                    <ImageButton htmlFor="file-upload">
                      Select Image
                    </ImageButton>
                  </ImageContainer>
                </>
              )}
              <UploadInput
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "AvanteGarde-Bold, sans-serif",
                fontSize: "14px",
              }}
            >
              <span style={{ marginRight: "8px" }}>Active:</span>
              <Toggle active={carouselItem.active} onToggle={handleToggle} />
            </div>
            <HR />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 16,
                marginTop: 8,
              }}
            >
              <Button
                style={{ width: 100 }}
                label="Cancel"
                onClick={() => {
                  setIsAddModalOpen(false);
                  setCarouselItem({
                    created: "",
                    partnerCarouselId: "",
                    name: "",
                    image: "",
                    link: "",
                    active: true,
                    order: 0,
                  });
                  setSelectedFile(null);
                  setPreviewUrl("");
                }}
              />
              <Button
                style={{ width: 100 }}
                label={isUploading ? "Uploading" : "Add"}
                disabled={!selectedFile || !carouselItem.name || isUploading}
                onClick={() => {
                  handleUpload();
                }}
              />
            </div>
          </FormWrapper>
        </Modal>
      )}

      {isEditModalOpen && (
        <Modal
          title="Edit Partner Carousel Item"
          onClose={() => {
            setIsEditModalOpen(false);
            setCarouselItem({
              created: "",
              partnerCarouselId: "",
              name: "",
              image: "",
              link: "",
              active: true,
              order: 0,
            });
          }}
          isOpen={isEditModalOpen}
        >
          <FormWrapper>
            <HR style={{ marginTop: 0 }} />
            <InputWrapper>
              <InputField
                type="text"
                name="name"
                value={carouselItem.name}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Name</Label>
            </InputWrapper>
            <InputWrapper>
              <InputField
                type="text"
                name="link"
                value={carouselItem.link}
                onChange={handleInputChange}
                placeholder=" "
              />
              <Label>Link (Optional)</Label>
            </InputWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "AvanteGarde-Bold, sans-serif",
                fontSize: "14px",
              }}
            >
              <span style={{ marginRight: "8px" }}>Active:</span>
              <Toggle active={carouselItem.active} onToggle={handleToggle} />
            </div>
            <HR />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <Button
                style={{ width: 100 }}
                label="Cancel"
                onClick={() => {
                  setIsEditModalOpen(false);
                  setCarouselItem({
                    created: "",
                    partnerCarouselId: "",
                    name: "",
                    image: "",
                    link: "",
                    active: true,
                    order: 0,
                  });
                }}
              />
              <Button
                style={{ width: 100 }}
                label="Save"
                onClick={() => {
                  handleSave();
                }}
              />
            </div>
          </FormWrapper>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal
          title="Delete Partner Carousel Item"
          onClose={() => {
            setIsDeleteModalOpen(false);
            setCarouselItem({
              created: "",
              partnerCarouselId: "",
              name: "",
              image: "",
              link: "",
              active: true,
              order: 0,
            });
          }}
          isOpen={isDeleteModalOpen}
        >
          <FormWrapper>
            <HR style={{ marginTop: 0 }} />
            <p style={{ textAlign: "center" }}>
              Are you sure you want to delete this carousel item?
            </p>
            <HR />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <Button
                style={{ width: 100 }}
                label="Cancel"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setCarouselItem({
                    created: "",
                    partnerCarouselId: "",
                    name: "",
                    image: "",
                    link: "",
                    active: true,
                    order: 0,
                  });
                }}
              />
              <Button
                style={{ width: 100 }}
                label="Delete"
                onClick={() => {
                  handleDelete(carouselItem.partnerCarouselId);
                  setIsDeleteModalOpen(false);
                  setCarouselItem({
                    created: "",
                    partnerCarouselId: "",
                    name: "",
                    image: "",
                    link: "",
                    active: true,
                    order: 0,
                  });
                }}
              />
            </div>
          </FormWrapper>
        </Modal>
      )}
    </PartnerCarouselContainer>
  );
};

export default PartnerCarouselTab;
