import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CourseCardsGrid } from "../components/courses/CourseCardsGrid";
import CourseCategoryCard from "../components/courses/CourseCategoryCard";
import { CoursesWrapper } from "../components/courses/CoursesWrapper";
import { CenteredContainer } from "../components/form/Styles";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import ErrorMessage from "../components/misc/ErrorMessage";
import InfoSection from "../components/misc/InfoSection";
import LoadingDots from "../components/misc/LoadingDots";
import { PageWrapper } from "../components/misc/PageWrapper";
import { useGetStories } from "../hooks/queries";

const SuccessStoriesPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: successStories, isLoading, isError } = useGetStories();

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching stories"} />;
  }

  const handleStoryClick = (slug: string) => {
    navigate(`/success-stories/${slug}`);
  };

  return (
    <>
      <Helmet>
        <title>DTN - Success Stories</title>
        <meta
          name="description"
          content="Discover inspiring success stories from students and professionals who enhanced their telecom skills with DTN Academy's courses and bootcamps."
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/" }, { label: "Success Stories" }]}
        />
        <HeaderImage
          backgroundImageUrl={`https://dtnacademy.lon1.cdn.digitaloceanspaces.com/uploads/banner/FRONT%20SLIDER%201%20.png`}
        />
        <InfoSection />
        <CenteredContainer>
          <CoursesWrapper>
            <CourseCardsGrid>
              {successStories?.length &&
                successStories.map((story) => (
                  <CourseCategoryCard
                    key={story.successStoryId}
                    imageUrl={story.imageUrl}
                    logoUrl="/logo.png"
                    title={story.name}
                    onClick={() => handleStoryClick(story.slug)}
                  />
                ))}
            </CourseCardsGrid>
          </CoursesWrapper>
        </CenteredContainer>
      </PageWrapper>
    </>
  );
};

export default SuccessStoriesPage;
