import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { CourseCardsGrid } from "../../../components/courses/CourseCardsGrid";
import CourseCategoryCard from "../../../components/courses/CourseCategoryCard";
import { CoursesWrapper } from "../../../components/courses/CoursesWrapper";
import AddCourseSubCategoryForm from "../../../components/form/AddCourseSubCategoryForm";
import Button from "../../../components/form/Button";
import { AdminPageWrapper } from "../../../components/form/Styles";
import Breadcrumb from "../../../components/misc/Breadcrumb";
import ErrorMessage from "../../../components/misc/ErrorMessage";
import FloatingButton from "../../../components/misc/FloatingButton";
import LoadingDots from "../../../components/misc/LoadingDots";
import Modal from "../../../components/misc/Modal";
import { useGetCourseCategoryBySlug } from "../../../hooks/queries/useGetCourseCategoryBySlug";
import { useSubCategories } from "../../../hooks/queries/useSubCategories";
import { getApiInstance } from "../../../hooks/useApi";
import { useToast } from "../../../hooks/useToast";
import { colors, SubCategory } from "../../../types";

const AdminCourseSubCategoriesPage = () => {
  const { categorySlug = "" } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<SubCategory>();
  const { showToast } = useToast();

  const navigate = useNavigate();

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
  } = useGetCourseCategoryBySlug(categorySlug);

  const {
    data: subCategories = [],
    isLoading: isLoadingSubCategories,
    isError: isErrorSubCategories,
    refetch: fetchSubCategories,
  } = useSubCategories(category?.categoryId || "", "all");

  useEffect(() => {
    if (category) {
      fetchSubCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (isErrorSubCategories) {
    return <ErrorMessage message={"Error fetching sub-categories"} />;
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isCategoryLoading || isLoadingSubCategories) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  const handleCategoryClick = (category: string, subCategory: string) => {
    navigate(`/admin/courses/${category}/${subCategory}`);
  };

  const handlePatchActive = (subCategoryId: string, active: boolean) => {
    getApiInstance()
      .patch(`/api/courses/sub-categories/${subCategoryId}/active`, { active })
      .then((response) => {
        if (response.status === 204) {
          fetchSubCategories();
          showToast("Sub-category updated", "success");
        } else {
          showToast("Error updating sub-category", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating sub-category:", error);
        showToast("Error updating sub-category", "error");
      });
  };

  return (
    <>
      <Helmet>
        <title>{`DTN - Admin - ${category?.name}`}</title>
        <meta
          name="description"
          content="Manage and update the courses offered by DTN Academy. Add new courses, edit existing ones, and organise course categories for the DTN Academt website."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/admin" },
            { label: "Courses", href: "/admin/courses" },
            { label: `${category?.name}` },
          ]}
        />
        <CoursesWrapper>
          <CourseCardsGrid>
            {subCategories?.length &&
              subCategories.length > 0 &&
              subCategories.map((sc) => (
                <div style={{ position: "relative" }} key={sc.subCategoryId}>
                  <Button
                    label={sc.isActive ? "Active" : "Inactive"}
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "15px",
                      backgroundColor: sc.isActive
                        ? "#4caf50"
                        : colors.brand.brandDarkRed.hex,
                      fontSize: "12px",
                      cursor: "pointer",
                      width: "65px",
                      zIndex: 100,
                    }}
                    onClick={() =>
                      handlePatchActive(sc.subCategoryId, !sc.isActive)
                    }
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "15px",
                      zIndex: 100,
                      cursor: "pointer",
                      backgroundColor: "rgba(255,255,255,0.7)",
                      borderRadius: "50%",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setSelectedCategory(sc);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} color="gray" size="lg" />
                  </div>
                  <CourseCategoryCard
                    title={sc.name}
                    logoUrl={"/logo.png"}
                    imageUrl={sc.imageUrl}
                    key={sc.subCategoryId}
                    onClick={() =>
                      handleCategoryClick(category?.slug || "", sc.slug)
                    }
                  />
                </div>
              ))}
          </CourseCardsGrid>
        </CoursesWrapper>

        <FloatingButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setIsModalOpen(true)}
        />

        {category && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={"Add Course Sub-Category"}
          >
            <AddCourseSubCategoryForm
              categoryId={category.categoryId}
              onSuccess={() => {
                fetchSubCategories();
                setIsModalOpen(false);
              }}
              onClose={() => setIsModalOpen(false)}
            />
          </Modal>
        )}

        {selectedCategory && (
          <Modal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            title={"Edit Course Sub-Category"}
          >
            <AddCourseSubCategoryForm
              categoryId={category?.categoryId || ""}
              initialSubCategory={selectedCategory}
              onSuccess={() => {
                fetchSubCategories();
                setIsEditModalOpen(false);
              }}
              onClose={() => setIsEditModalOpen(false)}
            />
          </Modal>
        )}
      </AdminPageWrapper>
    </>
  );
};

export default AdminCourseSubCategoriesPage;
