import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import CourseDetailPart from "../../../components/courses/CourseDetailPart";
import EditCourseDetailsForm from "../../../components/form/EditCourseDetailsForm";
import { AdminPageWrapper } from "../../../components/form/Styles";
import Breadcrumb from "../../../components/misc/Breadcrumb";
import ErrorMessage from "../../../components/misc/ErrorMessage";
import FloatingButton from "../../../components/misc/FloatingButton";
import LoadingDots from "../../../components/misc/LoadingDots";
import Modal from "../../../components/misc/Modal";
import {
  useGetCourseBySlug,
  useGetCourseCategoryBySlug,
  useGetCourseSubCategoryBySlug,
} from "../../../hooks/queries";

const AdminCourseDetailsPage = () => {
  const { categorySlug = "", subCategorySlug = "", slug = "" } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    refetch: fetchCategory,
  } = useGetCourseCategoryBySlug(categorySlug);

  useEffect(() => {
    if (categorySlug) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug]);

  const {
    data: subCategory,
    isLoading: isSubCategoryLoading,
    isError: isSubCategoryError,
    refetch: fetchSubCategory,
  } = useGetCourseSubCategoryBySlug(subCategorySlug);

  useEffect(() => {
    if (subCategorySlug) {
      fetchSubCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategorySlug]);

  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
    refetch: fetchCourse,
  } = useGetCourseBySlug(slug);

  useEffect(() => {
    if (slug) {
      fetchCourse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (isCategoryLoading || isSubCategoryLoading || isCourseLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isSubCategoryError) {
    return <ErrorMessage message={"Error fetching sub-category"} />;
  }

  if (isCourseError) {
    return <ErrorMessage message={"Error fetching course"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`DTN - Admin - ${course?.name}`}</title>
        <meta
          name="description"
          content="Manage and update the courses offered by DTN Academy. Add new courses, edit existing ones, and organise course categories for the DTN Academt website."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/admin" },
            { label: "Courses", href: "/admin/courses" },
            {
              label: `${category?.name}`,
              href: `/admin/courses/${categorySlug}`,
            },
            {
              label: `${subCategory?.name}`,
              href: `/admin/courses/${categorySlug}/${subCategorySlug}`,
            },
            { label: `${course?.name}` },
          ]}
        />

        {course && (
          <div style={{ marginTop: "1rem" }}>
            <CourseDetailPart
              title="Qualification"
              value={course?.details?.qualification}
            />
            <CourseDetailPart
              title="Qualification Title"
              value={course?.details?.title}
            />
            <CourseDetailPart
              title="Duration"
              value={course?.details?.duration}
            />
            <CourseDetailPart
              title="Maximum Participants"
              value={course?.details?.max?.toString() ?? "Unlimited"}
            />
            <CourseDetailPart
              title="Pre-requisite"
              value={course?.details?.prerequisites}
            />
            <CourseDetailPart title="Covers" value={course?.details?.covers} />
            <CourseDetailPart
              title="Cost Per Person"
              value={course?.details?.cost}
            />
            <CourseDetailPart title="Notes" value={course?.details?.notes} />{" "}
          </div>
        )}

        {/* Floating button to open modal */}

        <FloatingButton
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => setIsModalOpen(true)}
        />

        {/* Modal for adding new category */}
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Edit Course Details"}
        >
          {course && category && subCategory && (
            <EditCourseDetailsForm
              courseCategorySlug={category.slug}
              course={course}
              onSuccess={() => fetchCourse()}
              onClose={() => setIsModalOpen(false)}
            />
          )}
        </Modal>
      </AdminPageWrapper>
    </>
  );
};

export default AdminCourseDetailsPage;
