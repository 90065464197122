import { useEffect } from "react";

const LiveChat: React.FC = () => {
  useEffect(() => {
    const scriptId = "tcx-callus-js";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src =
        "https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js";
      script.id = scriptId;
      script.defer = true;
      script.charset = "utf-8";
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <call-us
        phonesystem-url="https://dtnacademy.3cx.co.uk"
        style={{
          position: "fixed",
          fontSize: "16px",
          lineHeight: "17px",
          zIndex: 99999,
          "--call-us-main-accent-color": "#a83a38",
          "--call-us-main-background-color": "#FFFFFF",
          "--call-us-plate-background-color": "#373737",
          "--call-us-plate-font-color": "#E6E6E6",
          "--call-us-main-font-color": "#292929",
          "--call-us-agent-bubble-color": "#29292910",
          right: "20px",
          bottom: "20px",
        }}
        id="wp-live-chat-by-3CX"
        minimized="true"
        animation-style="noanimation"
        party="LiveChat544518"
        minimized-style="bubbleright"
        allow-call="true"
        allow-video="false"
        allow-soundnotifications="true"
        enable-mute="true"
        enable-onmobile="true"
        offline-enabled="true"
        enable="true"
        ignore-queueownership="false"
        authentication="none"
        operator-name="DTN Academy"
        show-operator-actual-name="true"
        aknowledge-received="true"
        gdpr-enabled="false"
        message-userinfo-format="name"
        message-dateformat="both"
        lang="browser"
        button-icon-type="default"
        greeting-visibility="none"
        greeting-offline-visibility="none"
        chat-delay="2000"
        enable-direct-call="true"
        enable-ga="false"
      ></call-us>
    </div>
  );
};

export default LiveChat;
