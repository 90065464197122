import styled from "@emotion/styled";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../types/colors";

const PaginationButton = styled("button")({
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: "12px",
  transition: "background-color 0.3s",
  "&:disabled": {
    backgroundColor: colors.brand.brandSilver.hex,
    cursor: "not-allowed",
  },
  "&:hover:not(:disabled)": {
    backgroundColor: colors.brand.brandBlack.hex,
  },
});

interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px",
      marginBottom: "20px",
    }}
  >
    <PaginationButton
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </PaginationButton>
    <span style={{ margin: "0 10px" }}>
      Page {currentPage} of {totalPages}
    </span>
    <PaginationButton
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      <FontAwesomeIcon icon={faArrowRight} />
    </PaginationButton>
  </div>
);

export default PaginationControls;
