import { useQuery, UseQueryResult } from "react-query";
import { CourseCard } from "../../types"; // Assuming you have a Category type
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

const fetchCoursesBySubCategoryId = async (
  subCategoryId: string,
  active: "true" | "false" | "all" = "true"
) => {
  const response = await getApiInstance().get(
    `/api/courses/${subCategoryId}/courses?active=${active}`
  );
  return response.data; // Assuming response.data contains the category object
};

export const useGetCoursesBySubCategoryId = (
  subCategoryId: string,
  active: "true" | "false" | "all" = "true"
): UseQueryResult<CourseCard[], unknown> => {
  const { showToast } = useToast();

  return useQuery<CourseCard[], unknown>(
    ["courses", subCategoryId, active],
    () => fetchCoursesBySubCategoryId(subCategoryId, active),
    {
      enabled: !!subCategoryId,
      onError: (error: any) => {
        showToast("Error fetching courses by subCategory", "error");
        console.error("Error fetching courses by subCategory:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache result for 5 minutes
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );
};
