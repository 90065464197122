import styled from "@emotion/styled";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "react-markdown-editor-lite";
import { useNavigate, useParams } from "react-router-dom";
import ImageSelectionModal from "../../../components/form/ImageSelectionModal";
import {
  AdminPageWrapper,
  ImageButton,
  ImageContainer,
  ImagePreview,
  InputField,
  InputWrapper,
  Label,
  SubmitButton,
} from "../../../components/form/Styles";
import Breadcrumb from "../../../components/misc/Breadcrumb";
import ErrorMessage from "../../../components/misc/ErrorMessage";
import LoadingDots from "../../../components/misc/LoadingDots";
import { useGetStory } from "../../../hooks/queries";
import { getApiInstance } from "../../../hooks/useApi";
import { useToast } from "../../../hooks/useToast";
import { Story } from "../../../types";
import {
  decodeBase64Markdown,
  encodeBase64Markdown,
} from "../../../utils/decodeBase64Markdown";

// Utility function to generate a slug from a name
const generateSlug = (name: string) => {
  return name
    .toLowerCase()
    .replace(/'/g, "") // Remove single quotes
    .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters (except single quotes) with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
};

// Styled Components
const FormWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "2rem",
});

const InputGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: "1rem",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
    gap: "0",
  },
});

const Row = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  gap: "1rem",
  marginBottom: "1rem",
});

const ButtonRow = styled(Row)({
  justifyContent: "space-between",
});

const MarkdownWrapper = styled(Row)({ width: "100%" });

const AdminSuccessStory: React.FC = () => {
  const { slug = "" } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [markdownContent, setMarkdownContent] = useState<string>("");
  const [hasSlugBeenModified, setHasSlugBeenModified] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState<Story>({
    successStoryId: "",
    name: "",
    slug: "",
    imageId: "",
    imageUrl: "",
    markdown: "",
  });

  const {
    data: story,
    isLoading: isStoryLoading,
    isError: isStoryError,
    refetch: fetchStory,
  } = useGetStory(slug);

  useEffect(() => {
    if (story) {
      setState(story);
      setMarkdownContent(decodeBase64Markdown(story.markdown));
      setSelectedImageUrl(story.imageUrl);
    }
  }, [story]);

  useEffect(() => {
    if (slug) {
      fetchStory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (isStoryLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isStoryError) {
    return <ErrorMessage message={"Error fetching story"} />;
  }

  // Handle input changes for both the name and slug fields
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Auto-generate slug if the name changes and the slug has not been modified manually
    if (name === "name" && !hasSlugBeenModified) {
      setState((prev) => ({
        ...prev,
        name: value,
        slug: generateSlug(value), // Auto-generate slug
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle slug changes and mark the slug as manually modified
  const handleSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasSlugBeenModified(true);
    setState((prev) => ({
      ...prev,
      slug: e.target.value,
    }));
  };

  const handleEditorChange = ({ text }: { text: string }) => {
    setMarkdownContent(text);
    setState((prev) => ({
      ...prev,
      markdown: encodeBase64Markdown(markdownContent),
    }));
  };

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setState((prev) => ({ ...prev, imageId }));
    setSelectedImageUrl(imageUrl);
    setIsImageModalOpen(false);
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const response = await getApiInstance().patch(
        `/api/success-stories/${state.successStoryId}`,
        {
          name: state.name,
          slug: state.slug,
          markdown: state.markdown,
          imageId: state.imageId,
        }
      );

      if (response.status === 204) {
        showToast("Success story saved", "success");
        fetchStory();
      } else {
        showToast("Failed to save success story", "error");
      }
    } catch (e) {
      showToast("Failed to save success story", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>DTN - Admin - New Success Story</title>
        <meta
          name="description"
          content="Discover inspiring success stories from students and professionals who enhanced their telecom skills with DTN Academy's courses and bootcamps."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/admin" },
            { label: "Success Stories", href: "/admin/success-stories" },
            { label: `${story?.name}` },
          ]}
        />
        <FormWrapper>
          <InputGrid>
            <div>
              <Row>
                <InputWrapper>
                  <InputField
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    placeholder=" "
                    required
                  />
                  <Label>Name</Label>
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <InputField
                    type="text"
                    name="slug"
                    value={state.slug}
                    onChange={handleSlugChange} // Use handleSlugChange for manual slug edits
                    placeholder=" "
                    required
                  />
                  <Label>Slug</Label>
                </InputWrapper>
              </Row>
            </div>
            <div>
              <Row>
                <ImageContainer
                  onClick={() => setIsImageModalOpen(true)}
                  style={{ height: 200 }}
                >
                  {selectedImageUrl ? (
                    <ImagePreview src={selectedImageUrl} alt="Selected" />
                  ) : (
                    <ImageButton type="button">Select Card Image</ImageButton>
                  )}
                </ImageContainer>
              </Row>
            </div>
          </InputGrid>

          <MarkdownWrapper>
            <MarkdownEditor
              value={markdownContent}
              style={{ height: "500px", width: "100%" }}
              renderHTML={(text: string | null | undefined) => (
                <ReactMarkdown>{text}</ReactMarkdown>
              )}
              onChange={handleEditorChange}
            />
          </MarkdownWrapper>
          <ButtonRow>
            <SubmitButton
              type="button"
              disabled={isSubmitting}
              onClick={() => navigate("/admin/success-stories")}
            >
              Back
            </SubmitButton>
            <SubmitButton
              type="submit"
              disabled={isSubmitting}
              onClick={() => {
                handleSave();
              }}
            >
              Update
            </SubmitButton>
          </ButtonRow>
        </FormWrapper>
      </AdminPageWrapper>

      <ImageSelectionModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        onSelectImage={handleImageSelect}
      />
    </>
  );
};

export default AdminSuccessStory;
