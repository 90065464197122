import { asColorDefinitionRGBA } from "./asColorDefinitionRGBA";

export const brand = asColorDefinitionRGBA({
  brandBlack: {
    red: 43,
    green: 42,
    blue: 41,
    alpha: 1,
  },
  brandDarkRed: {
    red: 168,
    green: 58,
    blue: 56,
    alpha: 1,
  },
  brandSliver: {
    red: 162,
    green: 163,
    blue: 164,
    alpha: 1,
  },
});
