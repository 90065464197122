import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import ImagesTab from "../../components/admin/ImagesTab";
import PDFTab from "../../components/admin/PDFTab";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import { ContentWrapper, Tab, TabContainer } from "./CarouselsPage";

interface UploadsPageProps {
  // Add any props you need here
}

const UploadsPage: React.FC<UploadsPageProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <>
      <Helmet>
        <title>DTN - Admin</title>
        <meta
          name="description"
          content="Access and manage the DTN Academy administration dashboard. Control content, manage users, upload media, and maintain the website's functionality."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[{ label: "Admin", href: "/admin" }, { label: "Uploads" }]}
        />

        <TabContainer>
          <Tab active={selectedTab === 0} onClick={() => handleTabChange(0)}>
            Images
          </Tab>
          <Tab active={selectedTab === 1} onClick={() => handleTabChange(1)}>
            PDF
          </Tab>
        </TabContainer>

        <ContentWrapper>
          {selectedTab === 0 && <ImagesTab />}
          {selectedTab === 1 && <PDFTab />}
        </ContentWrapper>
      </AdminPageWrapper>
    </>
  );
};

export default UploadsPage;
