import styled from "@emotion/styled";
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"; // Import specific icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

// Styled components for toast container and messages
const ToastContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ToastMessage = styled.div<{ type: "success" | "error" | "info" }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  color: white;
  background-color: ${({ type }) =>
    type === "success" ? "#5cb85c" : type === "error" ? "#d9534f" : "#5bc0de"};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slideInOut 0.5s ease-in-out;

  @keyframes slideInOut {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    10% {
      opacity: 1;
      transform: translateX(0);
    }
    90% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  svg {
    margin-right: 0.5rem; // Add some space between icon and message text
  }
`;

// Interface for the toast message
interface Toast {
  id: number;
  message: string;
  type: "success" | "error" | "info";
}

// Interface for the context type
interface ToastContextType {
  showToast: (message: string, type?: "success" | "error" | "info") => void;
}

// Create the Toast context
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Hook to use toast context
export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

// Toast Provider to manage the toast messages
export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  // Function to show a toast message
  const showToast = useCallback(
    (message: string, type: "success" | "error" | "info" = "info") => {
      const id = Date.now(); // Unique ID based on timestamp
      setToasts((prevToasts) => [...prevToasts, { id, message, type }]);

      // Auto-remove the toast after 3 seconds
      setTimeout(() => {
        setToasts((prevToasts) =>
          prevToasts.filter((toast) => toast.id !== id)
        );
      }, 3000);
    },
    []
  );

  // Function to render the correct icon based on toast type
  const getIcon = (type: "success" | "error" | "info") => {
    switch (type) {
      case "success":
        return <FontAwesomeIcon icon={faCheckCircle} />;
      case "error":
        return <FontAwesomeIcon icon={faExclamationTriangle} />;
      case "info":
        return <FontAwesomeIcon icon={faInfoCircle} />;
      default:
        return null;
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer>
        {toasts.map((toast) => (
          <ToastMessage key={toast.id} type={toast.type}>
            {getIcon(toast.type)} {/* Display the icon */}
            {toast.message}
          </ToastMessage>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};
