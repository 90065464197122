import { useQuery, UseQueryResult } from "react-query";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { Category } from "../../types"; // Import your types

// Define your API fetching logic with return types
const fetchCategories = async (
  nrswa: string = "false",
  active: string = "true"
): Promise<Category[]> => {
  const response = await getApiInstance().get<Category[]>(
    `/api/courses/categories?nrswa=all&active=${active}`
  );
  return response.data;
};

// Custom hook using React Query with typed result
export const useCategories = (
  nrswa: string = "false",
  active: string = "true"
): UseQueryResult<Category[]> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch categories with typed data
  return useQuery<Category[]>(
    ["categories", nrswa, active],
    () => fetchCategories(nrswa, active),
    {
      onError: (error: any) => {
        showToast("Error fetching categories", "error");
        console.error("Error fetching categories:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache the result for 5 minutes
      refetchOnWindowFocus: false, // Avoid refetching on window focus
    }
  );
};
