import styled from "@emotion/styled";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import ImageSelectionModal from "./ImageSelectionModal";
import PDFSelectionModal from "./PDFSelectionModal";
import {
  FormWrapper,
  HR,
  ImageButton,
  ImageContainer,
  ImagePreview,
  InputField,
  InputWrapper,
  Label,
  SelectField,
  SubmitButton,
  TextAreaField,
} from "./Styles";

const ModalContent = styled("div")({
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "100%",
  boxSizing: "border-box",
});

export const PDFContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "10px",
  cursor: "pointer",
  maxWidth: "100%",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

export const PDFIcon = styled(FontAwesomeIcon)({
  color: "#e53e3e",
  fontSize: "24px",
  flexShrink: 0,
});

export const PDFName = styled("span")({
  fontSize: "14px",
  color: "#333",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  flexGrow: 1,
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const generateSlug = (name: string): string => {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "") // Remove non-alphanumeric characters (except spaces and hyphens)
    .replace(/\s+/g, "-"); // Replace spaces with hyphens
};

interface AddCourseFormProps {
  categoryId: string;
  onSuccess: () => void;
  onClose: () => void;
}

interface NewCourse {
  name: string;
  imageId: string;
  slug: string;
  description: string;
  subCategoryId: string;
  details: {
    qualification: string;
    title: string;
    prerequisites: string;
    duration: string;
    max?: string;
    covers: string;
    cost: string;
    notes?: string;
    pdfId?: string;
  };
}

const AddCourseForm: React.FC<AddCourseFormProps> = ({
  categoryId,
  onSuccess,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [newCourse, setNewCourse] = useState<NewCourse>({
    name: "",
    imageId: "",
    slug: "",
    description: "",
    subCategoryId: "",
    details: {
      qualification: "",
      title: "",
      prerequisites: "",
      max: "",
      duration: "",
      covers: "",
      cost: "",
      notes: "",
      pdfId: "",
    },
  });

  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [selectedPDF, setSelectPDF] = useState<{
    pdfId: string;
    filename: string;
    url: string;
  }>();
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { showToast } = useToast();
  const [slugManuallyEdited, setSlugManuallyEdited] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<
    { subCategoryId: string; name: string }[]
  >([]);

  // Fetch subcategories based on selected categoryId
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getApiInstance().get(
          `/api/courses/${categoryId}/sub-categories`
        );
        setSubCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
        showToast("Error fetching categories", "error");
      }
    };

    fetchCategories();
  }, [categoryId, showToast]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Check if the field belongs to the "details" object
    if (name in newCourse.details) {
      setNewCourse((prevCourse) => ({
        ...prevCourse,
        details: {
          ...prevCourse.details,
          [name]: value, // Update only the specific field in "details"
        },
      }));
    } else {
      // For fields outside of "details"
      if (name === "name" && !slugManuallyEdited) {
        setNewCourse((prevCourse) => ({
          ...prevCourse,
          slug: generateSlug(value), // Auto-generate slug if not manually edited
          [name]: value,
        }));
      } else {
        setNewCourse((prevCourse) => ({
          ...prevCourse,
          [name]: value,
        }));
      }
    }
  };

  // Allow manual edit of slug and set flag
  const handleSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSlugManuallyEdited(true);
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      slug: e.target.value,
    }));
  };

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setNewCourse((prevCourse) => ({ ...prevCourse, imageId }));
    setSelectedImageUrl(imageUrl);
    setIsImageModalOpen(false);
  };

  const handlePDFSelect = (pdfId: string, filename: string, url: string) => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      details: { ...prevCourse.details, pdfId },
    }));
    setSelectPDF({ pdfId, filename, url });
    setIsPDFModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await getApiInstance().post("/api/courses", newCourse);

      // Check if the response is successful
      if (response.status !== 204) {
        throw new Error("Error adding course");
      }

      showToast("Course added successfully", "success");

      setNewCourse({
        name: "",
        imageId: "",
        slug: "",
        subCategoryId: "",
        description: "",
        details: {
          qualification: "",
          title: "",
          prerequisites: "",
          duration: "",
          max: "",
          covers: "",
          cost: "",
          notes: "",
          pdfId: "",
        },
      });

      setSelectedImageUrl(null);
      onSuccess();
      onClose();
    } catch (error) {
      // Handle error case
      console.error("Error adding course:", error);
      showToast("Error adding course", "error");
    } finally {
      // Always reset the submitting state, regardless of success or error
      setIsSubmitting(false);
    }
  };

  const goToNextStep = () => setCurrentStep(currentStep + 1);
  const goToPreviousStep = () => setCurrentStep(currentStep - 1);

  return (
    <ModalContent>
      <FormWrapper>
        <HR style={{ margin: 0 }} />
        {currentStep === 1 && (
          <>
            <InputWrapper>
              <InputField
                type="text"
                name="name"
                value={newCourse.name}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Card Title</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="description"
                value={newCourse.description}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Card Description</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="slug"
                value={newCourse.slug}
                onChange={handleSlugChange}
                placeholder=" "
                required
              />
              <Label>Slug</Label>
            </InputWrapper>

            {/* Dropdown for Subcategories */}
            <InputWrapper>
              <SelectField
                name="subCategoryId"
                value={newCourse.subCategoryId}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select a Subcategory
                </option>
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.subCategoryId}
                    value={subCategory.subCategoryId}
                  >
                    {subCategory.name}
                  </option>
                ))}
              </SelectField>
            </InputWrapper>

            {/* Image Selection */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ImageContainer onClick={() => setIsImageModalOpen(true)}>
                {selectedImageUrl ? (
                  <ImagePreview src={selectedImageUrl} alt="Selected" />
                ) : (
                  <ImageButton type="button">Select Image</ImageButton>
                )}
              </ImageContainer>
              <ImageContainer onClick={() => setIsPDFModalOpen(true)}>
                {selectedPDF ? (
                  <PDFContainer>
                    <PDFIcon icon={faFilePdf} />
                    <PDFName title={selectedPDF.filename}>
                      {selectedPDF.filename}
                    </PDFName>
                  </PDFContainer>
                ) : (
                  <ImageButton type="button">Select PDF</ImageButton>
                )}
              </ImageContainer>
            </div>
            <HR style={{ margin: 0 }} />
            <ButtonContainer>
              <SubmitButton type="button" onClick={onClose}>
                Close
              </SubmitButton>
              <SubmitButton type="button" onClick={goToNextStep}>
                Next
              </SubmitButton>
            </ButtonContainer>
          </>
        )}

        {currentStep === 2 && (
          <>
            <InputWrapper>
              <InputField
                type="text"
                name="qualification"
                value={newCourse.details.qualification}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Qualification</Label>
            </InputWrapper>
            <InputWrapper>
              <InputField
                type="text"
                name="title"
                value={newCourse.details.title}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Title</Label>
            </InputWrapper>
            <InputWrapper>
              <InputField
                type="text"
                name="duration"
                value={newCourse.details.duration}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Duration</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="max"
                value={newCourse.details.max}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Maximum Participants</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="prerequisites"
                value={newCourse.details.prerequisites}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Prerequisites</Label>
            </InputWrapper>

            <InputWrapper>
              <TextAreaField
                name="covers"
                value={newCourse.details.covers}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Covers</Label>
            </InputWrapper>
            <InputWrapper>
              <InputField
                type="text"
                name="cost"
                value={newCourse.details.cost}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Cost</Label>
            </InputWrapper>
            <InputWrapper>
              <TextAreaField
                name="notes"
                value={newCourse.details.notes}
                onChange={handleInputChange}
                placeholder=" "
              />
              <Label>Notes</Label>
            </InputWrapper>
            <HR style={{ margin: 0 }} />
            <ButtonContainer>
              <SubmitButton type="button" onClick={goToPreviousStep}>
                Back
              </SubmitButton>
              <SubmitButton
                type="button"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Add Course
              </SubmitButton>
            </ButtonContainer>
          </>
        )}

        <ImageSelectionModal
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
          onSelectImage={handleImageSelect}
        />

        <PDFSelectionModal
          isOpen={isPDFModalOpen}
          onClose={() => setIsPDFModalOpen(false)}
          onSelectPDF={handlePDFSelect}
        />
      </FormWrapper>
    </ModalContent>
  );
};

export default AddCourseForm;
