import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

const Wrapper = styled("div")({
  padding: "20px",
  textAlign: "center",
  color: colors.brand.brandSilver.hex,
  fontFamily: "AvanteGarde-Bold, sans-serif",
});

interface MessageWrapperProps {
  children: React.ReactNode;
}

const MessageWrapper: React.FC<MessageWrapperProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MessageWrapper;
