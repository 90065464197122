import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { colors } from "../../types";

interface FooterWrapperProps {
  isVisible: boolean;
}

const FooterWrapper = styled("div")<FooterWrapperProps>(({ isVisible }) => ({
  backgroundColor: colors.brand.brandBlack.hex,
  color: "#fff",
  display: "flex",
  flexDirection: "column" /* Stack content vertically */,
  justifyContent: "center" /* Center content vertically */,
  alignItems: "center" /* Center content horizontally */,
  textAlign: "center",
  width: "100%",
  position: "fixed",
  bottom: 0,
  left: 0,
  padding: "1rem 0",
  opacity: isVisible ? 1 : 0, // Directly using the value here
  transition: "opacity 0.5s ease-in-out",
  zIndex: 1000,
  fontSize: "12px",

  "@media (max-width: 768px)": {
    fontSize: "10px",
  },
}));

const LinksWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: "0.5rem" /* Space between links */,

  a: {
    color: "#fff",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  span: {
    display:
      "inline-block" /* Ensures the span is treated as an inline block */,
  },

  "@media (max-width: 768px)": {
    marginTop: "0.5rem",
    flexWrap: "wrap" /* Allow wrapping of the links to fit smaller screens */,
  },
});

const Footer: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Show footer when the user reaches the bottom of the page
    if (scrollTop + windowHeight >= documentHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <FooterWrapper isVisible={isVisible}>
      <div>DTN © All Right Reserved. Developed and maintained by: lenan.io</div>
      <LinksWrapper>
        <a href="/privacy-policy/">Privacy Policy</a>
        <span>|</span>
        <a href="/terms-of-service">Terms of Service</a>
      </LinksWrapper>
    </FooterWrapper>
  );
};

export default Footer;
