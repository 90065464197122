import { useQuery, UseQueryResult } from "react-query";
import { StoryCard } from "../../types"; // Import your types
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

// Define your API fetching logic with return types
const fetchStories = async (): Promise<StoryCard[]> => {
  const response = await getApiInstance().get<StoryCard[]>(
    "/api/success-stories"
  );
  return response.data;
};

// Custom hook using React Query with typed result
export const useGetStories = (): UseQueryResult<StoryCard[], unknown> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch categories with typed data
  return useQuery<StoryCard[], unknown>("stories", fetchStories, {
    onError: (error: any) => {
      showToast("Error fetching stories", "error");
      console.error("Error fetching stories:", error);
    },
    staleTime: 1000 * 60 * 5, // Cache the result for 5 minutes
    refetchOnWindowFocus: false, // Avoid refetching on window focus
  });
};
