import { useQuery, UseQueryResult } from "react-query";
import { Course } from "../../types"; // Assuming you have a Category type
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

// Define the API call to fetch category by slug
const fetchCourseBySlug = async (slug: string) => {
  const response = await getApiInstance().get(`/api/courses/${slug}`);
  return response.data; // Assuming response.data contains the category object
};

export const useGetCourseBySlug = (
  slug: string
): UseQueryResult<Course, unknown> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch the course category by slug
  return useQuery<Course, unknown>(
    ["courseCategory", slug], // Query key (based on the slug)
    () => fetchCourseBySlug(slug), // API fetching function
    {
      enabled: !!slug, // Only fetch if slug is available
      onError: (error: any) => {
        showToast("Error fetching course ", "error");
        console.error("Error fetching course:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache result for 5 minutes
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );
};
