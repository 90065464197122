import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { colors } from "../../types";
import { Story } from "../../types/Story";
import { decodeBase64Markdown } from "../../utils/decodeBase64Markdown";
import HeaderImage from "../header/HeaderImage";
import Breadcrumb from "../misc/Breadcrumb";
import InfoSection from "../misc/InfoSection";
import LoadingDots from "../misc/LoadingDots";
import { PageWrapper } from "../misc/PageWrapper";

const SuccessStory: React.FC = () => {
  const { slug = "" } = useParams();
  const [story, setStory] = useState<Story | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();

  const formatCategory = (category: string | undefined) => {
    if (!category) {
      return "";
    }
    return category
      .split("-") // Split the string by hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with spaces
  };

  useMemo(() => {
    if (!slug) {
      return;
    }

    setIsLoading(true);
    getApiInstance()
      .get(`/api/success-stories/${slug}`)
      .then((response) => {
        if (response.status !== 200) {
          showToast("Error fetching story", "error");
        }
        setStory(response.data);
      })
      .catch((error) => {
        showToast("Error fetching story", "error");
        console.error("Error fetching story", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug, showToast]);

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`DTN - ${story?.name}`}</title>
        <meta
          name="description"
          content="Discover inspiring success stories from students and professionals who enhanced their telecom skills with DTN Academy's courses and bootcamps."
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/" },
            { label: "Success Stories", href: "/success-stories" },
            { label: formatCategory(story?.name) },
          ]}
        />
        <HeaderImage
          backgroundImageUrl={`${process.env.REACT_APP_BASE_URL}/uploads/1728035328176-759526835-success-stories`}
        />
        <InfoSection />

        {story?.markdown ? (
          <div style={{ marginBottom: "1rem" }}>
            <ReactMarkdown
              components={{
                h1: ({ children }) => (
                  <h1 style={{ color: colors.brand.brandDarkRed.hex }}>
                    {children}
                  </h1>
                ),
                h2: ({ children }) => (
                  <h2 style={{ color: colors.brand.brandDarkRed.hex }}>
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 style={{ color: colors.brand.brandBlack.hex }}>
                    {children}
                  </h3>
                ),
                h4: ({ children }) => (
                  <h4 style={{ color: colors.brand.brandDarkRed.hex }}>
                    {children}
                  </h4>
                ),
              }}
            >
              {decodeBase64Markdown(story.markdown)}
            </ReactMarkdown>
          </div>
        ) : (
          <p>No content available.</p>
        )}
        {/* <ImageOverlaySection /> */}
      </PageWrapper>
    </>
  );
};

export default SuccessStory;
