import styled from "@emotion/styled";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { Course } from "../../types";
import LoadingDots from "../misc/LoadingDots";
import { generateSlug, PDFContainer, PDFIcon, PDFName } from "./AddCourseForm";
import ImageSelectionModal from "./ImageSelectionModal";
import PDFSelectionModal from "./PDFSelectionModal";
import {
  FormWrapper,
  ImageButton,
  ImageContainer,
  ImagePreview,
  InputField,
  InputWrapper,
  Label,
  SelectField,
  SubmitButton,
  TextAreaField,
} from "./Styles";

const ModalContent = styled("div")({
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "100%",
  boxSizing: "border-box",
});

const NavigationButtons = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
});

interface EditCourseDetailsFormProps {
  courseCategorySlug: string;
  course: Course;
  onSuccess: () => void;
  onClose: () => void;
}

const EditCourseDetailsForm: React.FC<EditCourseDetailsFormProps> = ({
  courseCategorySlug,
  course,
  onSuccess,
  onClose,
}) => {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slugManuallyEdited, setSlugManuallyEdited] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(
    course.imageUrl
  );
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState<{
    pdfId: string;
    filename: string;
    url: string;
  }>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [newCourse, setNewCourse] = useState<Course>({
    courseId: course.courseId,
    categoryId: course.categoryId,
    subCategoryId: course.subCategoryId,
    name: course.name,
    imageUrl: course.imageUrl,
    imageId: course.imageId,
    slug: course.slug,
    description: course.description,
    details: {
      qualification: course?.details?.qualification,
      title: course?.details?.title,
      duration: course?.details?.duration,
      prerequisites: course?.details?.prerequisites,
      max: course?.details?.max,
      covers: course?.details?.covers,
      cost: course?.details?.cost,
      notes: course?.details?.notes,
      pdfId: course?.details?.pdfId,
      pdfUrl: course?.details?.pdfUrl,
    },
  });

  useEffect(() => {
    if (!course.categoryId) {
      return;
    }

    setIsLoading(true);
    getApiInstance()
      .get(`/api/courses/${course.categoryId}/sub-categories`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to fetch sub-categories");
        }

        setSubCategories(response.data);
      })
      .catch((e) => {
        console.error(e);
        showToast("Failed to fetch sub-categories", "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [course.categoryId, showToast]);

  useEffect(() => {
    if (!course) return;

    setIsLoading(true);
    getApiInstance()
      .get(`/api/dtn/pdfs/${course.details.pdfId}`)
      .then((response) => {
        if (response.status !== 200) {
          showToast("Failed to load pdf", "error");
        } else {
          const { pdfId, filename, url } = response.data;
          setSelectedPDF({ pdfId, filename, url });
        }
      })
      .catch((e) => {
        showToast("Failed to load pfs details", "error");
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [course, showToast]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Check if the field belongs to the "details" object
    if (name in newCourse.details) {
      setNewCourse((prevCourse) => ({
        ...prevCourse,
        details: {
          ...prevCourse.details,
          [name]: value, // Update only the specific field in "details"
        },
      }));
    } else {
      // For fields outside of "details"
      if (name === "description" && !slugManuallyEdited) {
        setNewCourse((prevCourse) => ({
          ...prevCourse,
          slug: generateSlug(value), // Auto-generate slug if not manually edited
          [name]: value,
        }));
      } else {
        setNewCourse((prevCourse) => ({
          ...prevCourse,
          [name]: value,
        }));
      }
    }
  };

  // Allow manual edit of slug and set flag
  const handleSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSlugManuallyEdited(true);
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      slug: e.target.value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      name: newCourse.name,
      description: newCourse.description,
      subCategoryId: newCourse.subCategoryId,
      imageId: newCourse.imageId,
      slug: newCourse.slug,
      details: {
        qualification: newCourse.details.qualification,
        title: newCourse.details.title,
        duration: newCourse.details.duration,
        max: newCourse.details.max,
        prerequisites: newCourse.details.prerequisites,
        covers: newCourse.details.covers,
        cost: newCourse.details.cost,
        notes: newCourse.details.notes,
        pdfId: newCourse.details.pdfId,
      },
    };

    getApiInstance()
      .patch(`/api/courses/${course.courseId}`, payload)
      .then((response) => {
        if (response.status !== 204) {
          showToast("Failed to up course", "success");
          throw new Error("Failed to update course");
        }

        if (payload.slug !== course.slug) {
          window.location.href = `/admin/courses/${courseCategorySlug}/${
            subCategories.find(
              (sc) => (sc.subCategoryId = payload.subCategoryId)
            ).slug
          }/${payload.slug}`;
        } else {
          onSuccess();
          onClose();
          showToast("Course updated successfully", "success");
        }
      })
      .catch((e) => {
        showToast("Failed to update course", "error");
        console.error(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setNewCourse((prevCourse) => ({ ...prevCourse, imageId }));
    setSelectedImageUrl(imageUrl);
    setIsImageModalOpen(false);
  };

  const handlePDFSelect = (pdfId: string, filename: string, url: string) => {
    setNewCourse((prevCourse) => ({
      ...prevCourse,
      details: { ...prevCourse.details, pdfId },
    }));
    setSelectedPDF({ pdfId, filename, url });

    setTimeout(() => {
      setIsPDFModalOpen(false);
    }, 100);
  };

  if (isLoading) {
    return (
      <>
        <LoadingDots />
      </>
    );
  }

  return (
    <ModalContent>
      <FormWrapper>
        {step === 1 && (
          <>
            <InputWrapper>
              <InputField
                type="text"
                name="name"
                value={newCourse.name}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Card Title</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="description"
                value={newCourse.description}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Card Description</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="slug"
                value={newCourse.slug}
                onChange={handleSlugChange}
                placeholder=" "
                required
              />
              <Label>Slug</Label>
            </InputWrapper>

            {/* Dropdown for Subcategories */}
            <InputWrapper>
              <SelectField
                name="subCategoryId"
                value={newCourse.subCategoryId}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select a Subcategory
                </option>
                {subCategories.map((subCategory) => (
                  <option
                    key={subCategory.subCategoryId}
                    value={subCategory.subCategoryId}
                  >
                    {subCategory.name}
                  </option>
                ))}
              </SelectField>
            </InputWrapper>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 8,
              }}
            >
              <ImageContainer onClick={() => setIsImageModalOpen(true)}>
                {selectedImageUrl ? (
                  <ImagePreview src={selectedImageUrl} alt="Selected" />
                ) : (
                  <ImageButton type="button">Select Image</ImageButton>
                )}
              </ImageContainer>
              <ImageContainer onClick={() => setIsPDFModalOpen(true)}>
                {selectedPDF ? (
                  <PDFContainer key={selectedPDF.pdfId}>
                    <PDFIcon icon={faFilePdf} />
                    <PDFName title={selectedPDF.filename}>
                      {selectedPDF.filename}
                    </PDFName>
                  </PDFContainer>
                ) : (
                  <ImageButton type="button">Select PDF</ImageButton>
                )}
              </ImageContainer>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <InputWrapper>
              <InputField
                type="text"
                name="qualification"
                value={newCourse.details.qualification}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Qualification</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="title"
                value={newCourse.details.title}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Qualification Title</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="duration"
                value={newCourse.details.duration}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Duration</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="max"
                value={newCourse.details.max}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Maximum Participants</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="prerequisites"
                value={newCourse.details.prerequisites}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Prerequisite</Label>
            </InputWrapper>

            <InputWrapper>
              <TextAreaField
                name="covers"
                value={newCourse.details.covers}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Covers</Label>
            </InputWrapper>

            <InputWrapper>
              <InputField
                type="text"
                name="cost"
                value={newCourse.details.cost}
                onChange={handleInputChange}
                placeholder=" "
                required
              />
              <Label>Cost</Label>
            </InputWrapper>

            <InputWrapper>
              <TextAreaField
                name="notes"
                value={newCourse.details.notes}
                onChange={handleInputChange}
                placeholder=" "
              />
              <Label>Notes</Label>
            </InputWrapper>
          </>
        )}

        <NavigationButtons>
          {step < 2 && (
            <>
              <SubmitButton type="button" onClick={onClose}>
                Cancel
              </SubmitButton>
              <SubmitButton type="button" onClick={goToNextStep}>
                Next
              </SubmitButton>
            </>
          )}

          {step === 2 && (
            <>
              <SubmitButton type="button" onClick={goToPreviousStep}>
                Back
              </SubmitButton>
              <SubmitButton
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting ? "Submitting..." : "Save Course"}
              </SubmitButton>
            </>
          )}
        </NavigationButtons>
      </FormWrapper>

      <ImageSelectionModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        onSelectImage={handleImageSelect}
      />

      <PDFSelectionModal
        isOpen={isPDFModalOpen}
        onClose={() => setIsPDFModalOpen(false)}
        onSelectPDF={handlePDFSelect}
      />
    </ModalContent>
  );
};

export default EditCourseDetailsForm;
