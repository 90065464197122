import styled from "@emotion/styled";
import {
  faBars,
  faCog,
  faHome,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import LoadingDots from "../misc/LoadingDots";

// Styled components using styled("element")({}) syntax
const NavbarWrapper = styled("div")({
  backgroundColor: "#2b2a29",
});

const Navbar = styled("div")({
  backgroundColor: "#2b2a29",
  paddingTop: "10px",
  paddingBottom: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  maxWidth: "1280px",
  margin: "0 auto",

  "@media (max-width: 768px)": {
    padding: "10px", // Adjust padding for smaller screens
  },
});

const HomeIcon = styled("div")({
  marginRight: "12px", // Adjust margin as needed

  "@media (max-width: 768px)": {
    marginRight: "0", // Adjust margin for mobile
  },
});

const MenuItemsContainer = styled("div")<{ isMobileOpen: boolean }>(
  ({ isMobileOpen }) => ({
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    transition: "max-height 0.3s ease",

    "@media (max-width: 768px)": {
      flexDirection: "column",
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",
      backgroundColor: "#333",
      overflow: "hidden",
      maxHeight: isMobileOpen ? "300px" : "0",
      zIndex: 1000,
    },
  })
);

const MenuItem = styled(Link)({
  display: "block",
  color: "white",
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: "15px",

  "&:first-of-type": {
    marginLeft: 0,
  },

  "@media (max-width: 768px)": {
    padding: "10px 20px",
    margin: 0,
  },
});

const SettingsLink = styled(Link)({
  color: "white",
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: "auto",
  marginRight: "10px",

  "@media (max-width: 768px)": {
    margin: 0,
    padding: "10px 20px",
    width: "100%",
    textAlign: "center",
  },
});

const LogoutLink = styled("div")({
  color: "white",
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: "10px",

  "@media (max-width: 768px)": {
    margin: 0,
    padding: "10px 20px",
    width: "100%",
    textAlign: "center",
  },
});

const HamburgerIcon = styled("div")({
  display: "none",
  color: "white",
  cursor: "pointer",
  fontSize: "1.5rem",

  "@media (max-width: 768px)": {
    display: "block",
  },
});

const AdminLayout: React.FC = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  useEffect(() => {
    if (!auth.accessToken) {
      setShouldRedirect(true);
    } else {
      setIsLoading(false);
    }
  }, [auth.accessToken]);

  const handleLogout = () => {
    auth.logout();
    setShouldRedirect(true);
  };

  if (shouldRedirect) {
    return <Navigate to="/admin/login" replace />;
  }

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  return (
    <>
      <NavbarWrapper>
        <Navbar>
          <HomeIcon>
            <Link
              to="/admin"
              style={{ color: "#a83a38", textDecoration: "none" }}
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </HomeIcon>

          {/* Hamburger Menu for Mobile */}
          <HamburgerIcon onClick={() => setIsMobileOpen(!isMobileOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </HamburgerIcon>

          <MenuItemsContainer isMobileOpen={isMobileOpen}>
            <MenuItem
              to="/admin/courses"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              Courses
            </MenuItem>
            <MenuItem
              to="/admin/success-stories"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              Stories
            </MenuItem>
            <MenuItem
              to="/admin/uploads"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              Uploads
            </MenuItem>
            <MenuItem
              to="/admin/testimonials"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              Testimonials
            </MenuItem>

            <MenuItem
              to="/admin/carousels"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              Carousels
            </MenuItem>

            <SettingsLink
              to="/admin/settings"
              onClick={() => setIsMobileOpen(false)} // Close menu on click
            >
              <FontAwesomeIcon icon={faCog} /> Settings
            </SettingsLink>
            <LogoutLink
              onClick={() => {
                handleLogout();
                setIsMobileOpen(false); // Close menu on logout
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </LogoutLink>
          </MenuItemsContainer>
        </Navbar>
      </NavbarWrapper>
      <Outlet />
    </>
  );
};

export default AdminLayout;
