import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCoursesBySubCategoryId } from "../../hooks/queries";
import { useGetCourseCategoryBySlug } from "../../hooks/queries/useGetCourseCategoryBySlug";
import { useGetCourseSubCategoryBySlug } from "../../hooks/queries/useGetCourseSubCategoryBySlug";
import CourseCard from "../../pages/admin/courses/CourseCard";
import { CourseSubCategoryType } from "../../types";
import HeaderImage from "../header/HeaderImage";
import Breadcrumb from "../misc/Breadcrumb";
import ErrorMessage from "../misc/ErrorMessage";
import InfoSection from "../misc/InfoSection";
import LoadingDots from "../misc/LoadingDots";
import MessageWrapper from "../misc/MessageWrapper";
import { PageWrapper } from "../misc/PageWrapper";
import { CourseCardsGrid } from "./CourseCardsGrid";
import { CoursesWrapper } from "./CoursesWrapper";
import OtherCoursePage from "./OtherCoursePage";

const CourseSubCategoryPage: React.FC = () => {
  const { categorySlug = "", subCategorySlug = "" } = useParams();
  const navigate = useNavigate();

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    refetch: fetchCategory,
  } = useGetCourseCategoryBySlug(categorySlug);

  useEffect(() => {
    if (categorySlug) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug]);

  const {
    data: subCategory,
    isLoading: isSubCategoryLoading,
    isError: isSubCategoryError,
    refetch: fetchSubCategory,
  } = useGetCourseSubCategoryBySlug(subCategorySlug);

  useEffect(() => {
    if (subCategorySlug) {
      fetchSubCategory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategorySlug]);

  const {
    data: courses = [],
    isLoading: isCoursesLoading,
    isError: isCoursesError,
    refetch: fetchCourses,
  } = useGetCoursesBySubCategoryId(subCategory?.subCategoryId || "");

  useEffect(() => {
    if (subCategory?.subCategoryId) {
      fetchCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory?.subCategoryId]);

  if (isCategoryLoading || isSubCategoryLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isSubCategoryError) {
    return <ErrorMessage message={"Error fetching sub-category"} />;
  }

  if (isCoursesError) {
    return <ErrorMessage message={"Error fetching courses"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`DTN - ${subCategory?.name}`}</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/" },
            { label: "Our Courses", href: "/our-courses" },
            {
              label: `${category?.name}`,
              href: `/our-courses/${categorySlug}`,
            },
            {
              label: `${subCategory?.name}`,
            },
          ]}
        />
        <HeaderImage
          backgroundImageUrl={subCategory?.headerImageUrl.replace(/ /g, "%20")}
        />
        <InfoSection />

        {subCategory?.type === "COURSE" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CoursesWrapper>
              {isCoursesLoading ? (
                <LoadingDots />
              ) : courses.length === 0 ? (
                <MessageWrapper>
                  {subCategorySlug === "confined-spaces"
                    ? "CONTACT US FOR MORE INFORMATION"
                    : "We currently do not have any courses available for this category. Please check back later."}
                </MessageWrapper>
              ) : (
                <CourseCardsGrid>
                  {courses.map((c) => (
                    <CourseCard
                      onClick={() =>
                        navigate(
                          `/our-courses/${categorySlug}/${subCategorySlug}/${c.slug}`
                        )
                      }
                      key={c.courseId}
                      logoUrl={"/logo.png"}
                      imageUrl={c.imageUrl}
                      title={c.name}
                      description={c.description}
                    />
                  ))}
                </CourseCardsGrid>
              )}
            </CoursesWrapper>
          </div>
        )}

        {subCategory &&
          [
            CourseSubCategoryType.APPRENTICESHIP,
            CourseSubCategoryType.ARMY,
            CourseSubCategoryType.CITY_AND_GUILDS,
            CourseSubCategoryType.EUSR,
          ].includes(subCategory.type) && (
            <OtherCoursePage subCategorySlug={subCategorySlug} />
          )}
      </PageWrapper>
    </>
  );
};

export default CourseSubCategoryPage;
