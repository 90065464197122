import styled from "@emotion/styled";

export const CourseCardsGrid = styled("div")({
  display: "grid",
  gap: "20px",
  gridTemplateColumns: "repeat(4, 1fr)",

  "@media (max-width: 1280px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
  },
});
