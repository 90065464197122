import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useGetTestimonials } from "../../hooks/queries";
import { colors } from "../../types/colors";
import ErrorMessage from "../misc/ErrorMessage";
import LoadingDots from "../misc/LoadingDots";

const TestimonialsContainer = styled("div")({
  padding: "2rem",
  backgroundColor: colors.brand.brandSilver.rgbaString({ alpha: 0.1 }),
  textAlign: "center",
  maxWidth: "1280px",
  margin: "2rem auto 2rem auto",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  "@media (max-width: 768px)": {
    padding: "1rem",
    margin: "1rem",
  },
});

const TestimonialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TestimonialSlider = styled.div<{ index: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => -props.index * 100}%);
  width: 100%;
`;

const TestimonialContent = styled.div`
  min-width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
`;

const TestimonialText = styled.p`
  font-family: "AvantGarde-Bold", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  color: #666;
  margin-bottom: 1rem;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const TestimonialAuthor = styled.p`
  font-family: "AvantGarde-Bold", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SectionHeader = styled.h2`
  font-family: "AvantGarde-Bold", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const TestimonialsSection: React.FC = () => {
  const { data: testimonials, isLoading, isError } = useGetTestimonials();

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!testimonials?.testimonials.length) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % testimonials.testimonials.length
      );
    }, 7000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [testimonials?.testimonials.length]);

  if (isLoading) {
    return <LoadingDots />;
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching testimonials"} />;
  }

  return (
    <TestimonialsContainer>
      <SectionHeader>Testimonials</SectionHeader>
      <TestimonialWrapper>
        <TestimonialSlider index={currentIndex}>
          {testimonials?.testimonials.map((testimonial, index) => (
            <TestimonialContent key={index}>
              <TestimonialText>{`"${testimonial.testimonial}"`}</TestimonialText>
              <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
            </TestimonialContent>
          ))}
        </TestimonialSlider>
      </TestimonialWrapper>
    </TestimonialsContainer>
  );
};

export default TestimonialsSection;
