import React, { createContext, useCallback, useEffect, useState } from "react";
import LoadingDots from "../components/misc/LoadingDots";
import { getApiInstance } from "../hooks/useApi";
import { User } from "../types";

interface AuthContextType {
  accessToken: string | null;
  user: User | null;
  handleLogin: (username: string, password: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: () => boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Track whether auth state is loading

  const fetchUserInfo = useCallback(async () => {
    try {
      if (!accessToken) {
        setIsLoading(false); // Finished loading even if no token
        return;
      }

      await getApiInstance()
        .get("/api/admin/user/info")
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        })
        .finally(() => {
          setIsLoading(false); // Finished loading
        });
    } catch (error) {
      console.error("Error fetching user info:", error);
      setIsLoading(false); // Finished loading in case of error
    }
  }, [accessToken]);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      setAccessToken(storedToken);
      fetchUserInfo();
    } else {
      setIsLoading(false); // If no token in local storage, we are done loading
    }
  }, [fetchUserInfo]);

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await getApiInstance().post("/api/admin/auth/login", {
        username,
        password,
      });

      if (!response.data?.token) {
        throw new Error("No token found in response");
      }

      const authToken = response.data?.token;
      localStorage.setItem("authToken", authToken);
      setAccessToken(authToken);
      fetchUserInfo(); // Fetch user info after successful login
    } catch (error: any) {
      console.error("Login error:", error);
      throw new Error(
        error?.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setAccessToken(null);
    setUser(null);
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem("authToken");
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        handleLogin,
        logout,
        isAuthenticated,
      }}
    >
      {!isLoading ? children : <LoadingDots />}{" "}
      {/* Render children once loading is done */}
    </AuthContext.Provider>
  );
};
