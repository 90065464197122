import styled from "@emotion/styled";
import Cookie from "js-cookie";
import { useEffect, useState } from "react";
import { colors } from "../types";
import Button from "./form/Button";

const Banner = styled("div")({
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  backgroundColor: colors.brand.brandBlack.hex,
  color: "#fff",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1002",
  boxShadow: "0 -4px 8px rgba(0, 0, 0, 0.2)",
  // Ensure banner doesn't block the button on mobile
  flexDirection: "column",
  gap: "1rem", // Add space between the text and button

  "@media (max-width: 768px)": {
    fontSize: "0.875rem", // Smaller font size on mobile
    padding: "0.8rem", // Adjust padding on mobile
  },
});

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const consent = Cookie.get("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookie.set("cookieConsent", "true", { expires: 30 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    Cookie.remove("cookieConsent");
    window.location.href = "https://www.google.co.uk";
  };

  return (
    <>
      {showBanner && (
        <Banner>
          <p>
            We use cookies to ensure you get the best experience on our website.
            By continuing to browse, you accept our use of cookies.
          </p>
          <div style={{ display: "flex", gap: 16 }}>
            <Button
              onClick={handleDecline}
              label="Decline"
              style={{
                width: "150px",
                alignSelf: "center",
                padding: "0.5rem 1rem",
              }}
            />
            <Button
              onClick={handleAccept}
              label="Accept"
              style={{
                width: "150px",
                alignSelf: "center",
                padding: "0.5rem 1rem",
              }}
            />
          </div>
        </Banner>
      )}
    </>
  );
};

export default CookieConsent;
