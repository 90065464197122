import styled from "@emotion/styled";

import React from "react";
import { colors } from "../../types";

const StyledButton = styled("button")({
  width: "100%",
  padding: "5px 10px",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  border: "none",
  borderRadius: "4px",
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  fontSize: "1rem",
  cursor: "pointer",
  // margin: "0 auto",
  textAlign: "center",
  display: "flex", // Make the button a flex container
  justifyContent: "center", // Center content horizontally
  alignItems: "center", // Center content vertically
  height: "auto",

  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
  },

  "&:disabled": {
    backgroundColor: colors.brand.brandSilver.hex,
    color: "#ffffff",
    cursor: "not-allowed",
  },
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

const Button: React.FC<ButtonProps> = ({ label, ...props }) => {
  return <StyledButton {...props}>{label}</StyledButton>;
};

export default Button;
