import { useQuery, UseQueryResult } from "react-query";
import { HeroCarousel } from "../../types"; // Import your types
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

const fetchHeroCarousel = async (
  active: "all" | "true" | "false" = "all"
): Promise<{
  carouselItems: HeroCarousel[];
}> => {
  const response = await getApiInstance().get<{
    carouselItems: HeroCarousel[];
  }>("/api/dtn/carousel/hero", { params: { active } });
  return response.data;
};

// Custom hook using React Query with typed result
export const useGetHeroCarousel = (
  active: "all" | "true" | "false" = "all"
): UseQueryResult<{ carouselItems: HeroCarousel[] }> => {
  const { showToast } = useToast();

  return useQuery<{ carouselItems: HeroCarousel[] }>(
    "heroCarousel",
    () => fetchHeroCarousel(active),
    {
      onError: (error: any) => {
        showToast("Error fetching hero carousel items", "error");
        console.error("Error fetching hero carousel items", error);
      },
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );
};
