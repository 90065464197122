import { useQuery, UseQueryResult } from "react-query";
import { Testimonial } from "../../types"; // Import your types
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

// Define your API fetching logic with return types
const fetchTestimonials = async (): Promise<{
  testimonials: Testimonial[];
}> => {
  const response = await getApiInstance().get<{ testimonials: Testimonial[] }>(
    "/api/dtn/testimonials"
  );
  return response.data;
};

// Custom hook using React Query with typed result
export const useGetTestimonials = (): UseQueryResult<{
  testimonials: Testimonial[];
}> => {
  const { showToast } = useToast();

  return useQuery<{ testimonials: Testimonial[] }>(
    "testimonials",
    fetchTestimonials,
    {
      onError: (error: any) => {
        showToast("Error fetching testimonials", "error");
        console.error("Error fetching testimonials:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache the result for 5 minutes
      refetchOnWindowFocus: false, // Avoid refetching on window focus
    }
  );
};
