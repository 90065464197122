import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

const HeaderText = styled("h1")({
  fontFamily: "AvantGarde-Bold, sans-serif",
  color: "white",
});

const ContentText = styled("p")({
  color: "white",
  fontFamily: "AvantGarde-Regular, sans-serif",
});

const AcademySectionContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "2rem",
  backgroundColor: colors.brand.brandBlack.rgbaString({ alpha: 0.5 }),
  borderRadius: "8px",
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: "100%",
  boxSizing: "border-box",
  height: "400px",
});

const TextContainer = styled("div")({
  flex: 1,
  paddingRight: "1rem",
  color: "white",
  minWidth: "300px",
  boxSizing: "border-box",
  width: "100%",
});

const IframeContainer = styled("div")({
  flex: 1,
  width: "100%",
  position: "relative",
  height: "100%",
});

const Iframe = styled("iframe")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  border: "none",
  borderRadius: "8px",
});

const AcademySectionDesktop: React.FC = () => {
  return (
    <AcademySectionContainer>
      <TextContainer>
        <HeaderText>Digital Telecoms Network Academy</HeaderText>
        <ContentText style={{ marginBottom: "1rem" }}>
          Digital Telecoms Network Academy provides a comprehensive learning
          experience for the Telecommunications Industry, with blended practical
          training to enhance or embrace new skills for the ongoing Digital
          Development for the Smart City Infrastructure.
        </ContentText>
        <ContentText>
          We are working as part of the Digital Policy Alliance (DPA), with the
          Digital Skills Group, servicing the industry to meet the employment
          gaps and skills shortages nationwide.
        </ContentText>
      </TextContainer>
      <IframeContainer>
        <Iframe
          src="https://www.youtube.com/embed/KeDHo0c8XW8"
          title="DTN Academy"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IframeContainer>
    </AcademySectionContainer>
  );
};

export default AcademySectionDesktop;
