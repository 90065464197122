import styled from "@emotion/styled";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../types";

// Interfaces for Breadcrumb Items and Props
interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

// Styled Components using styled("element")({}) syntax

const BreadcrumbWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  fontSize: "14px", // Default for desktop
  fontFamily: "AvanteGarde-Bold, sans-serif",
  color: colors.brand.brandBlack.hex,
  paddingTop: "2.5rem",
  flexWrap: "wrap", // Allows wrapping to prevent overflow

  "@media (max-width: 1200px)": {
    fontSize: "14px", // Medium screens (tablets and smaller desktops)
  },

  "@media (max-width: 768px)": {
    fontSize: "14px", // Small tablets and large mobile screens
    paddingTop: "2rem",
    paddingRight: "1rem",
  },

  "@media (max-width: 480px)": {
    fontSize: "12px", // Small mobile screens
    paddingTop: "1.5rem",
    paddingRight: "0.5rem",
  },
});

const BreadcrumbLink = styled("a")({
  color: colors.brand.brandDarkRed.hex,
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
    color: colors.brand.brandBlack.hex,
  },
});

const BreadcrumbSeparator = styled("span")({
  margin: "0 8px",
  color: colors.brand.brandSilver.hex,

  "@media (max-width: 768px)": {
    margin: "0 6px", // Reduce spacing for tablets
  },

  "@media (max-width: 480px)": {
    margin: "0 4px", // Smaller spacing for mobile
  },
});

const BreadcrumbItemText = styled("span")({
  color: colors.brand.brandBlack.hex,
});

// Breadcrumb component
const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <BreadcrumbWrapper aria-label="breadcrumb">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.href ? (
            <BreadcrumbLink href={item.href}>{item.label}</BreadcrumbLink>
          ) : (
            <BreadcrumbItemText>{item.label}</BreadcrumbItemText>
          )}
          {index < items.length - 1 && (
            <BreadcrumbSeparator>
              <FontAwesomeIcon icon={faChevronRight} />
            </BreadcrumbSeparator>
          )}
        </React.Fragment>
      ))}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
