import styled from "@emotion/styled";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../../types/colors";

const PageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#f0f0f0",
});

const ErrorContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  backgroundColor: colors.brand.brandDarkRed.rgbaString({ alpha: 0.1 }),
  color: colors.brand.brandDarkRed.hex,
  border: "1px solid #f5c6cb",
  borderRadius: "4px",
  margin: "20px 0",
});

// Styling for the icon
const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 24px;
`;

const Message = styled.p`
  margin: 0;
  font-size: 16px;
`;

// Error Component
interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <PageContainer>
      <ErrorContainer>
        <IconWrapper>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </IconWrapper>
        <Message>{message}</Message>
      </ErrorContainer>
    </PageContainer>
  );
};

export default ErrorMessage;
