import styled from "@emotion/styled";
import React, { useState } from "react";
import { colors } from "../../types/colors";

// Define the props interface
interface TestimonialCardProps {
  imageUrl: string;
  content: string;
}

// Styled-components for styling
const CardWrapper = styled.div({
  border: "1px solid #ddd",
  borderRadius: "8px",
  padding: "20px",
  width: "300px",
  textAlign: "left",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "hidden", // Ensure no content overflows out of the card
});

const Image = styled.img({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  marginBottom: "15px",
});

const Content = styled.p({
  fontSize: "16px",
  color: colors.brand.brandBlack.hex,
  lineHeight: "1.5",
  marginTop: "10px",
  wordWrap: "break-word", // Break long words to avoid overflow
  wordBreak: "break-word", // Ensure content breaks inside the card
});

const MoreButton = styled.button({
  marginTop: "10px",
  fontSize: "14px",
  color: colors.brand.brandDarkRed.hex,
  background: "none",
  border: "none",
  cursor: "pointer",
});

// TestimonialCard component
const TestimonialCard: React.FC<TestimonialCardProps> = ({
  imageUrl,
  content,
}) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to toggle expanded/collapsed content
  const charLimit = 100; // Set a character limit for initial content

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Determine if we need to show the "Read More" button
  const shouldShowMoreButton = content.length > charLimit;

  return (
    <CardWrapper>
      <Image src={imageUrl} alt="Testimonial" />
      <Content>
        {isExpanded ? content : `${content.substring(0, charLimit)}...`}
      </Content>
      {shouldShowMoreButton && (
        <MoreButton onClick={toggleReadMore}>
          {isExpanded ? "Show Less" : "Read More"}
        </MoreButton>
      )}
    </CardWrapper>
  );
};

export default TestimonialCard;
