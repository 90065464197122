import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useCategories } from "../../hooks/queries";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { CourseSubCategoryType, SubCategory } from "../../types";
import ErrorMessage from "../misc/ErrorMessage";
import LoadingDots from "../misc/LoadingDots";
import ImageSelectionModal from "./ImageSelectionModal";
import {
  FormWrapper,
  HR,
  ImageButton,
  ImageContainer,
  ImagePreview,
  ImageSelectionWrapper,
  InputField,
  InputWrapper,
  Label,
  ModalContent,
  NavigationButtons,
  SelectField,
  SubmitButton,
} from "./Styles";

interface AddCourseSubCategoryFormProps {
  categoryId: string;
  initialSubCategory?: SubCategory;
  onSuccess: () => void;
  onClose: () => void;
}

const AddCourseSubCategoryForm: React.FC<AddCourseSubCategoryFormProps> = ({
  categoryId,
  initialSubCategory,
  onSuccess,
  onClose,
}) => {
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isHeaderImageModalOpen, setIsHeaderImageModalOpen] = useState(false);
  const [isSlugManuallyEdited, setIsSlugManuallyEdited] = useState(false);

  const [subCategory, setNewSubCategory] = useState<SubCategory>({
    categoryId: categoryId,
    subCategoryId: initialSubCategory?.subCategoryId || "",
    name: initialSubCategory?.name || "",
    imageId: initialSubCategory?.imageId || "",
    imageUrl: initialSubCategory?.imageUrl || "",
    headerImageId: initialSubCategory?.headerImageId || "",
    headerImageUrl: initialSubCategory?.headerImageUrl || "",
    headerText: initialSubCategory?.headerText || "",
    slug: initialSubCategory?.slug || "",
    type: initialSubCategory?.type || CourseSubCategoryType.COURSE,
    isActive: initialSubCategory?.isActive || true,
    order: initialSubCategory?.order || 0,
  });

  useEffect(() => {
    setNewSubCategory((prev) => ({
      ...prev,
      categoryId: categoryId,
    }));
  }, [categoryId]);

  const {
    data: categories,
    isLoading,
    isError,
    refetch: fetchCategories,
  } = useCategories("all", "all");

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const generateSlug = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  };

  useEffect(() => {
    if (!isSlugManuallyEdited) {
      setNewSubCategory((prev) => ({
        ...prev,
        slug: generateSlug(prev.name),
      }));
    }
  }, [subCategory.name, isSlugManuallyEdited]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "slug") {
      setIsSlugManuallyEdited(true);
    }

    setNewSubCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setNewSubCategory((prev) => ({ ...prev, imageId, imageUrl }));
    setIsImageModalOpen(false);
  };

  const handleHeaderImageSelect = (imageId: string, imageUrl: string) => {
    setNewSubCategory((prev) => ({
      ...prev,
      headerImageId: imageId,
      headerImageUrl: imageUrl,
    }));
    setIsHeaderImageModalOpen(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      name: subCategory.name,
      categoryId: subCategory.categoryId,
      imageId: subCategory.imageId,
      headerImageId: subCategory.headerImageId,
      active: subCategory.isActive,
      slug: subCategory.slug,
      type: subCategory.type,
    };

    try {
      const api = getApiInstance();
      const endpoint = initialSubCategory
        ? `/api/courses/sub-categories/${subCategory.subCategoryId}`
        : `/api/courses/sub-categories`;

      const method = initialSubCategory ? "patch" : "post";
      const successMessage = initialSubCategory
        ? "Course subCategory updated"
        : "Course subCategory added";

      const resp = await api[method](endpoint, payload);

      if (resp.status === 204) {
        showToast(successMessage, "success");
        onSuccess();
      } else {
        showToast("Failed to add/update sub-category", "error");
      }
    } catch (e) {
      showToast("Failed to add/update sub-category", "error");
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching categories"} />;
  }

  return (
    <ModalContent>
      <FormWrapper onSubmit={handleSubmit}>
        <HR style={{ margin: 0 }} />
        <InputWrapper>
          <InputField
            type="text"
            name="name"
            value={subCategory.name}
            onChange={handleInputChange}
            placeholder=" "
            required
          />
          <Label>Name</Label>
        </InputWrapper>

        <InputWrapper>
          <InputField
            type="text"
            name="slug"
            value={subCategory.slug}
            onChange={handleInputChange}
            placeholder=" "
            required
          />
          <Label>Slug</Label>
        </InputWrapper>

        <InputWrapper>
          <SelectField
            name="type"
            value={subCategory.type}
            onChange={handleInputChange}
            required
          >
            <option value="APPRENTICESHIP">Apprenticeship</option>
            <option value="ARMY">Army</option>
            <option value="CITY_AND_GUILDS">City & Guilds</option>
            <option value="COURSE">Course</option>
            <option value="EUSR">EUSR</option>
          </SelectField>
        </InputWrapper>

        <InputWrapper>
          <SelectField
            name="categoryId"
            value={subCategory.categoryId}
            onChange={handleInputChange}
            required
          >
            {categories &&
              categories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.name}
                  </option>
                ))}
          </SelectField>
        </InputWrapper>

        <ImageSelectionWrapper>
          <ImageContainer onClick={() => setIsImageModalOpen(true)}>
            {subCategory?.imageUrl ? (
              <ImagePreview src={subCategory?.imageUrl} alt="Selected" />
            ) : (
              <ImageButton type="button">Select Card Image</ImageButton>
            )}
          </ImageContainer>

          <ImageContainer onClick={() => setIsHeaderImageModalOpen(true)}>
            {subCategory?.headerImageUrl ? (
              <ImagePreview src={subCategory?.headerImageUrl} alt="Selected" />
            ) : (
              <ImageButton type="button">Select Header Image</ImageButton>
            )}
          </ImageContainer>
        </ImageSelectionWrapper>

        <ImageSelectionModal
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
          onSelectImage={handleImageSelect}
        />

        <ImageSelectionModal
          isOpen={isHeaderImageModalOpen}
          onClose={() => setIsHeaderImageModalOpen(false)}
          onSelectImage={handleHeaderImageSelect}
        />
        <HR style={{ margin: 0 }} />
        <NavigationButtons>
          <SubmitButton type="button" onClick={onClose}>
            Close
          </SubmitButton>
          <SubmitButton
            type="button"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {initialSubCategory ? "Update" : "Add"}
          </SubmitButton>
        </NavigationButtons>
      </FormWrapper>
    </ModalContent>
  );
};

export default AddCourseSubCategoryForm;
