import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import { PageWrapper } from "../components/misc/PageWrapper";
import { colors } from "../types/colors";

const PrivacyParagraph = styled("p")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  fontSize: "14px",
  lineHeight: "1.5",
  marginBottom: "1rem",
  "@media (max-width: 768px)": {
    fontSize: "12px",
    lineHeight: "1.7",
  },
  "@media (max-width: 1024px)": {
    fontSize: "14px",
    lineHeight: "1.8",
  },
});

const SectionTitle = styled("h2")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  fontSize: "18px",
  lineHeight: "1.5",
  marginBottom: "1.5rem",
  color: colors.brand.brandDarkRed.hex,
  "@media (max-width: 768px)": {
    fontSize: "14px",
    lineHeight: "1.6",
  },
  "@media (max-width: 1024px)": {
    fontSize: "16px",
    lineHeight: "1.7",
  },
});

const Link = styled("a")({
  color: colors.brand.brandDarkRed.hex,
  textDecoration: "none",
  fontWeight: "bold",
  "&:hover": {
    textDecoration: "underline",
  },
  "@media (hover: hover)": {
    "&:hover": {
      color: colors.brand.brandSilver.hex,
    },
  },
  "@media (max-width: 767px)": {
    fontSize: "14px",
  },
  "@media (max-width: 768px)": {
    fontSize: "16px",
  },
});

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>DTN - Privacy Policy</title>
        <meta name="description" content="" />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/" }, { label: "Privacy Policy" }]}
        />
        <HeaderImage
          backgroundImageUrl={`https://dtnacademy.lon1.cdn.digitaloceanspaces.com/uploads/banner/PRIVACY%20POLICY.png`}
        />
        <PrivacyParagraph>
          <SectionTitle>How do we use your information?</SectionTitle>
          This privacy statement tells you what to expect when DTN Academy
          collects your personal information. It applies to information we
          collect about:
          <ul>
            <li>Visitors to our website Complaints and feedback received</li>
            <li>
              Information required for commercial purposes for providing our
              services to our customers
            </li>
            <li>Visitors to our website</li>
          </ul>
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Use of cookies by DTN Academy</SectionTitle>
          Cookies are small text files that are placed on your computer by
          websites that you visit. They are widely used in order to make
          websites work, or work more efficiently, as well as to provide
          information to the owners of the site. We will ask you to opt in and
          agree to the use of cookies on our website upon your first visit. Your
          choice will be remembered for 30 days if you opt in. Opting in will
          improve your browsing experience tou our website. No cookies will be
          used until you opt in and accept the use of cookies on our website.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Changing our cookie settings</SectionTitle>
          If you change your maxd about the use of cookies on our website, click
          the “Privacy Policy” link which can be found in the bottom, left
          corner accessible throughout the website. Here you can change your
          cookie use settings on our website. Alternatively, most web browsers
          allow some control of most cookies through the browser settings. To
          find out more about cookies, including how to see what cookies have
          been set and how to manage and delete them, visit{" "}
          <Link href="www.aboutcookies.org">www.aboutcookies.org</Link> or{" "}
          <Link href="www.allaboutcookies.org">www.allaboutcookies.org</Link>.
          To opt out of being tracked by Google Analytics across all websites
          visit{" "}
          <Link href="http://tools.google.com/dlpage/gaoptout">
            http://tools.google.com/dlpage/gaoptout
          </Link>
          .
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Submission of your information</SectionTitle>
          Submission of your information, requested, from our website is held on
          our secure platform. This is protected by all methods necessary. Only
          the required personnel have access to this information. Your
          information will only be used for the stated intended purpose and will
          not be shared outside of DTN Academy without your express consent.
          Your consent to share your information will be sought at the time of
          you submitting your data.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Complaints and feedback</SectionTitle>
          Should you wish to make a complaint our complaints process is
          available upon request. All information received during the course of
          a complaint is handled with the same level of security protection on
          need for privacy as any other information we collect.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Requests for your information</SectionTitle>
          We will respond to requests for the information we hold on you within
          the required 30-day period. Initial requests will not be charged.
          However, should more than 2 requests be made within a 3-month period
          of time, subsequent requests will be charged at £10 per request. All
          information will be provided in the format of a PDF document.
        </PrivacyParagraph>
        <PrivacyParagraph>
          <SectionTitle>Sharing information</SectionTitle>
          DTN Academy do not share any information of a personal nature outside
          our organisation. All information is utilised for internal operations
          and commercial purposes only. The exception to this criteria is the
          sharing of information with UK authorities for investigatory purposes
          as per current legislation on finance and personnel. DTN Academy may
          share information with the following external organisations for
          commercial purposes:
          <ul>
            <li>HMRC</li>
            <li>External solicitors for legal purposes</li>
            <li>External Accountant for auditing purposes and payroll</li>
            <li>Employee Engagement Solutions</li>
          </ul>
        </PrivacyParagraph>
      </PageWrapper>
    </>
  );
};

export default PrivacyPolicy;
