import styled from "@emotion/styled";
import React from "react";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import ErrorMessage from "../components/misc/ErrorMessage";
import LoadingDots from "../components/misc/LoadingDots";
import MissionStatement from "../components/misc/MissionStatement";
import { PageWrapper } from "../components/misc/PageWrapper";
import CustomCarousel from "../components/partner-carousel/CustomCarousel";
import { useGetPartnerCarousel } from "../hooks/queries";

const AboutUsContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "1280px",
  marginBottom: "1rem",
  borderRadius: "8px",
  gap: "2rem",
  alignItems: "center",

  "@media (max-width: 768px)": {
    flexDirection: "column",
    padding: "1rem",
    alignItems: "flex-start", // Align items to the start on small screens
  },

  "@media (min-width: 768px) and (max-width: 1024px)": {
    // Adjust gap and alignment for tablets
    gap: "1.5rem",
    alignItems: "flex-start",
    padding: "1rem",
  },

  "@media (min-width: 1024px)": {
    flexDirection: "row",
    alignItems: "center", // Center align items for desktop
  },
});

const MediaContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1rem",

  img: {
    width: "100%",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },

  iframe: {
    width: "100%",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    height: "300px",

    "@media (min-width: 1024px)": {
      height: "400px", // Larger height on desktop
    },
  },

  "@media (min-width: 1024px)": {
    flex: 1,
  },

  "@media (max-width: 768px)": {
    img: {
      maxWidth: "100%",
    },
    iframe: {
      maxWidth: "100%",
    },
  },
});

const TextContainer = styled("div")({
  flex: 2,
  color: "#333",
  borderRadius: "8px",

  h2: {
    color: "#a81010",
    fontSize: "2rem",
    marginBottom: "1rem",
    textAlign: "center",

    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
  },

  p: {
    fontSize: "1rem",
    lineHeight: "1.6",
    marginBottom: "1rem",

    "@media (max-width: 768px)": {
      fontSize: "0.9rem",
    },
  },

  "@media (min-width: 1024px)": {
    flex: 2,
  },
});

const AboutUs: React.FC = () => {
  const {
    data: partnerCarouselData,
    isLoading: partnerCarouselLoading,
    isError: partnerCarouselError,
  } = useGetPartnerCarousel("true");

  if (partnerCarouselLoading) {
    return <LoadingDots />;
  }

  if (partnerCarouselError) {
    return <ErrorMessage message="Error loading partner carousel images" />;
  }
  return (
    <PageWrapper>
      <Breadcrumb
        items={[{ label: "Home", href: "/" }, { label: "About Us" }]}
      />
      <HeaderImage
        backgroundImageUrl={`${process.env.REACT_APP_CDN_HOST}/uploads/banner/about.png`}
      />
      <AboutUsContainer>
        <MediaContainer>
          <img
            src={`${process.env.REACT_APP_CDN_HOST}/static/about.jpg`}
            alt="National Training Centre"
          />
          <iframe
            src="https://www.youtube.com/embed/KeDHo0c8XW8"
            title="DTN Academy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </MediaContainer>
        <TextContainer>
          <h2>Digital Telecoms Network Academy</h2>
          <p>
            The company was established in 2000 to provide specialist training
            in telecommunications.
          </p>
          <p>
            Digital Telecoms Network Academy provides a comprehensive learning
            experience for the Telecommunications Industry, with blended
            practical training to enhance or embrace new skills for the ongoing
            Digital Development for the Smart City Infrastructure.
          </p>
          <p>
            We deliver a range of nationally recognised qualifications for
            Telecommunications, Construction and specialist training for
            employers developing the digital networks required for smart cities
            and the Urban landscape. Additionally, we are the main provider in
            the country delivering Telecoms Apprentices at level 2/3 and we are
            part of the working party developing new standards for the industry,
            in an ever-expanding area.
          </p>
          <p>
            Our quality processes are of a high standard, as expected of an
            Ofsted Grade 2 provider. We also have quality kitemarks for Matrix
            Accreditation, Investors in People and ISO 9001 – 2015.
          </p>
          <p>
            DTN is a preferred supplier of the Careers Transition Partnership
            (CTP), who are part of the Armed Forces Resettlement. We work with
            the Personal Recovery Centre (PRC) and Help for Heroes at Phoenix
            House in Catterick Garrison, engaging in resettlement training and
            sourcing employment for those leaving the forces.
          </p>
          <p>
            The Academy has fully equipped and up-to-date technology, backed
            with the latest equipment used by the industry. This provides the
            most realistic hands-on experience for our learners both internal
            and external. Alongside this, we also boast a 3.2 km test and
            training network with air-blown fibre and conventional cables. This
            comes complete with simulated faults which are added to enhance the
            learner’s experience and further their skills development.
          </p>
          <p>
            We work with a range of employers who support us in the design of
            courses to ensure they are credible and are fit for purpose. As a
            result, we have a good track record of training people with the
            skills the industry requires and transitioning them into employment
            within the industry.
          </p>
        </TextContainer>
      </AboutUsContainer>
      <MissionStatement />
      <div
        style={{
          marginTop: "2rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {partnerCarouselData &&
        partnerCarouselData.carouselItems &&
        partnerCarouselData.carouselItems.length > 0 ? (
          <CustomCarousel
            images={partnerCarouselData?.carouselItems.map((i) => i.image)}
          />
        ) : (
          <ErrorMessage message="No partner carousel items found" />
        )}
      </div>
    </PageWrapper>
  );
};

export default AboutUs;
