import { useQuery, UseQueryResult } from "react-query";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { SubCategory } from "../../types"; // Import your types for SubCategory

// API fetching logic for sub-categories
const fetchSubCategories = async (
  categoryId: string,
  active: string = "true",
  nrswa: string = "all"
): Promise<SubCategory[]> => {
  const response = await getApiInstance().get<SubCategory[]>(
    `/api/courses/${categoryId}/sub-categories?active=${active}`
  );
  return response.data;
};

// Custom hook using React Query with typed result
export const useSubCategories = (
  categoryId: string,
  active: string = "true"
): UseQueryResult<SubCategory[], unknown> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch sub-categories with typed data
  return useQuery<SubCategory[], unknown>(
    ["subCategories", categoryId, active], // Query key (including categoryId for dynamic fetch)
    () => fetchSubCategories(categoryId, active), // API function call
    {
      enabled: !!categoryId, // Only run query if categoryId is available
      onError: (error: any) => {
        showToast("Error fetching sub-categories", "error");
        console.error("Error fetching sub-categories:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache the result for 5 minutes
      refetchOnWindowFocus: false, // Avoid refetching on window focus
    }
  );
};
