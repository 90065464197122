import { useQuery, UseQueryResult } from "react-query";
import { CourseSubCategoryType } from "../../types";
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

// Define the API call to fetch category by slug
const fetchOtherCourseBySubcategorySlug = async (slug: string) => {
  const response = await getApiInstance().get(
    `/api/courses/sub-categories/${slug}/other-course`
  );
  return response.data; // Assuming response.data contains the category object
};

export const useGetOtherCourseBySubcategorySlug = (
  slug: string
): UseQueryResult<{
  subCategoryId: string;
  otherCourseId: string;
  name: string;
  slug: string;
  type: CourseSubCategoryType;
  duration: string;
  courseCode: string;
  employment: string;
  content: string;
  images: {
    otherCourseImageId: string;
    imageId: string;
    imageUrl: string;
  }[];
  stories: {
    otherCourseStoryId: string;
    imageId: string;
    imageUrl: string;
    content: string;
  }[];
}> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch the course category by slug
  return useQuery<{
    subCategoryId: string;
    otherCourseId: string;
    name: string;
    slug: string;
    type: CourseSubCategoryType;
    duration: string;
    courseCode: string;
    employment: string;
    content: string;
    images: {
      otherCourseImageId: string;
      imageId: string;
      imageUrl: string;
    }[];
    stories: {
      otherCourseStoryId: string;
      imageId: string;
      imageUrl: string;
      content: string;
    }[];
  }>(
    ["otherCourse", slug], // Query key (based on the slug)
    () => fetchOtherCourseBySubcategorySlug(slug), // API fetching function
    {
      enabled: !!slug, // Only fetch if slug is available
      onError: (error: any) => {
        showToast("Error fetching course sub-category", "error");
        console.error("Error fetching course sub-category:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache result for 5 minutes
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );
};
