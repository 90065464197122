import React from "react";
import { Helmet } from "react-helmet-async";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";

interface AdminPageProps {
  // Add any props you need here
}

const AdminPage: React.FC<AdminPageProps> = () => {
  return (
    <>
      <Helmet>
        <title>DTN - Admin</title>
        <meta
          name="description"
          content="Access and manage the DTN Academy administration dashboard. Control content, manage users, upload media, and maintain the website's functionality."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb items={[{ label: "Admin" }]} />
      </AdminPageWrapper>
    </>
  );
};

export default AdminPage;
