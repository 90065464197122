import styled from "@emotion/styled";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { colors } from "../../types/colors";
import { MenuItemType } from "./Navbar";

const Container = styled("div")({
  background: colors.brand.brandBlack.hex,
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 1000,
});

const NavbarContainer = styled("div")<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: colors.brand.brandBlack.hex,
    color: "white",
    height: isCollapsed ? "60px" : "90px",
    margin: "0 auto",
    transition: "height 0.3s ease",
  })
);

const Menu = styled("div")({
  display: "flex",
  gap: "0.15rem",
  margin: "0 0.75rem",

  // Tablet-friendly adjustments
  "@media (max-width: 960px)": {
    gap: "0.25rem", // Reduce gap for tablet screens
    padding: "0 0.5rem", // Optional: Add some padding if necessary
    fontSize: "0.5rem", // Smaller font size for better readability on tablets
  },
});

const MenuItemContainer = styled("div")({
  position: "relative",
  cursor: "pointer",
  padding: "0.5rem 1rem",
  "@media (max-width: 960px)": {
    padding: "0.5rem 0.75rem", // Adjust padding to ensure elements fit well on tablet screens
  },
});

const MenuItemLink = styled("a")({
  fontFamily: "AvanteGarde-Bold",
  color: "white",
  textDecoration: "none",
  fontSize: "1rem",
  padding: "0.5rem 1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",

  "&:hover": {
    backgroundColor: colors.brand.brandDarkRed.hex,
  },

  "@media (max-width: 960px)": {
    padding: "0.5rem 0.75rem",
    fontSize: "0.5rem",
  },
});

const SubMenuContainer = styled("div")({
  position: "relative",
  minWidth: "200px",
});

const SubMenu = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  display: isOpen ? "flex" : "none",
  flexDirection: "column",
  position: "absolute",
  backgroundColor: "#333",
  borderRadius: "4px",
  top: "100%", // Ensure submenu drops below parent menu item
  left: 15, // Align submenu with parent
  minWidth: "200px",
  width: "auto",
  whiteSpace: "nowrap",
  zIndex: 1000,
  overflow: "visible", // Ensure the submenu doesn't get cut off
}));

const SubSubMenu = styled("div")<{ isOpen: boolean }>(({ isOpen }) => ({
  display: isOpen ? "block" : "none",
  position: "absolute",
  backgroundColor: "#333",
  borderRadius: "4px",
  top: 0,
  left: "100%", // Make sure the submenu appears fully to the right of the parent
  minWidth: "200px",
  width: "auto",
  whiteSpace: "nowrap",
  zIndex: 1000,
  overflow: "visible", // Ensure the submenu doesn't get clipped
}));

const SubMenuItemLink = styled("a")(() => ({
  fontFamily: `"AvanteGarde-Bold", sans-serif`,
  padding: "0.5rem 1rem",
  display: "block",
  minWidth: "200px",
  color: "white",
  textDecoration: "none",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#555",
  },
}));

const Logo = styled("img")<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  height: isCollapsed ? "48px" : "86px",
  paddingLeft: "2rem",
  transition: "height 0.3s ease",
}));

const SocialTelContainer = styled("div")<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "0 auto",
    backgroundColor: colors.brand.brandDarkRed.hex,
    padding: isCollapsed ? "3px 1.5rem" : "5px 3rem",
    transition: "padding 0.3s ease, height 0.3s ease, opacity 0.3s ease",
    height: isCollapsed ? "0" : "auto",
    opacity: isCollapsed ? 0 : 1,
    overflow: "hidden",
  })
);

const SocialContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-start",
  a: {
    color: "white",
    marginRight: "10px",
    fontSize: "18px",
    textDecoration: "none",
    "&:hover": {
      color: "#ccc",
    },
  },
}));

const TelNumberContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
  color: "white",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  fontSize: "11px",
  fontWeight: 700,
}));

const ContentContainer = styled("div")(() => ({
  paddingTop: "140px",
}));

interface DesktopNavbarProps {
  menuData: MenuItemType[];
}

const DesktopNavbar: React.FC<DesktopNavbarProps> = ({ menuData }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [openSubSubMenu, setOpenSubSubMenu] = useState<string | null>(null);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderMenuItems = (items: MenuItemType[]) => {
    return items.map((item) => (
      <MenuItemContainer
        key={item.title}
        onMouseEnter={() => setOpenSubMenu(item.title)}
        onMouseLeave={() => setOpenSubMenu(null)}
      >
        <MenuItemLink href={item.url}>
          {item.title}
          {item.submenu && (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ marginLeft: "8px" }}
            />
          )}
        </MenuItemLink>
        {item.submenu && (
          <SubMenu isOpen={openSubMenu === item.title}>
            {item.submenu.map((subItem) => (
              <SubMenuContainer
                key={subItem.title}
                onMouseEnter={() => setOpenSubSubMenu(subItem.title)}
                onMouseLeave={() => setOpenSubSubMenu(null)}
              >
                <SubMenuItemLink href={subItem.url}>
                  {subItem.title}
                  {subItem.submenu && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ marginLeft: "8px" }}
                    />
                  )}
                </SubMenuItemLink>
                {subItem.submenu && (
                  <SubSubMenu isOpen={openSubSubMenu === subItem.title}>
                    {subItem.submenu.map((subSubItem) => (
                      <SubMenuItemLink
                        key={subSubItem.title}
                        href={subSubItem.url}
                      >
                        {subSubItem.title}
                      </SubMenuItemLink>
                    ))}
                  </SubSubMenu>
                )}
              </SubMenuContainer>
            ))}
          </SubMenu>
        )}
      </MenuItemContainer>
    ));
  };

  return (
    <>
      <Container>
        <SocialTelContainer isCollapsed={isCollapsed}>
          <SocialContainer>
            <a
              href="https://www.facebook.com/DTNAcademy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.linkedin.com/company/dtn-academy-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a
              href="https://www.instagram.com/dtnacademy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://x.com/DTN_Academy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=447903202240"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </SocialContainer>
          <TelNumberContainer>
            <span>Head Office (Stockton): 01642 804000</span>
          </TelNumberContainer>
        </SocialTelContainer>

        <NavbarContainer isCollapsed={isCollapsed}>
          <a href="/">
            <Logo src="/logo.png" alt="DTN Academy" isCollapsed={isCollapsed} />
          </a>
          <Menu>{renderMenuItems(menuData)}</Menu>
        </NavbarContainer>

        <div style={{ height: 8, backgroundColor: "#a81010" }} />
      </Container>
      <ContentContainer></ContentContainer>
    </>
  );
};

export default DesktopNavbar;
