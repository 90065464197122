import { Route, Routes, useLocation } from "react-router-dom";
import AdminLayout from "./components/admin/AdminLayout";
import CookieConsent from "./components/CookieConsent";
import CourseDetailsPage from "./components/courses/CourseDetailsPage";
import CourseSubCategoryPage from "./components/courses/CourseSubCategoryPage";
import CourseCategoriesPage from "./components/courses/CouseCategoriesPage";
import Footer from "./components/footer/Footer";
import LiveChat from "./components/LiveChat";
import ScrollToTop from "./components/misc/ScrollToTop";
import NavigateToTop from "./components/NavigateToTop";
import Navbar from "./components/navigation/Navbar";
import SuccessStory from "./components/success/SuccessStory";
import { AuthProvider } from "./context"; // AuthProvider import
import About from "./pages/About";
import AdminCoursesPage from "./pages/admin/AdminCoursesPage";
import AdminPage from "./pages/admin/AdminPage";
import AdminSuccessStoriesPage from "./pages/admin/AdminSuccessStoriesPage";
import CarouselsPage from "./pages/admin/CarouselsPage";
import AdminCourseDetailsPage from "./pages/admin/courses/AdminCourseDetailsPage";
import AdminCourseSubCategoriesPage from "./pages/admin/courses/AdminCourseSubCategoriesPage";
import AdminCourseSubCategoryCoursesPage from "./pages/admin/courses/AdminCourseSubCategoryCoursesPage";
import LoginPage from "./pages/admin/LoginPage";
import SettingsPage from "./pages/admin/SettingsPage";
import AdminNewSuccessStoryPage from "./pages/admin/success-stories/AdminNewSuccessStory";
import AdminSuccessStory from "./pages/admin/success-stories/AdminSuccessStory";
import TestimonialsPage from "./pages/admin/TestimonialsPage";
import UploadsPage from "./pages/admin/UploadsPage";
import Contact from "./pages/Contact";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import OurCoursesPage from "./pages/OurCoursesPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SkillsForLife from "./pages/SkillsForLife";
import SuccessStoriesPage from "./pages/SuccessStoriesPage";
import TermsOfService from "./pages/TermsOfService";

function App() {
  const location = useLocation();

  // Conditionally render Navbar if the current path does not include '/admin'
  const shouldShowNavbar = !location.pathname.includes("/admin");

  return (
    <>
      <CookieConsent />

      {shouldShowNavbar && (
        <>
          <LiveChat />
          <Navbar />
        </>
      )}
      <NavigateToTop />

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/test" element={<ApprenticeshipPage />} /> */}
        {/* <Route path="/our-courses/nrswa" element={<CourseCategoriesPage />} /> */}
        <Route path="/our-courses" element={<OurCoursesPage />} />
        <Route
          path="/our-courses/:categorySlug"
          element={<CourseCategoriesPage />}
        />
        <Route
          path="/our-courses/:categorySlug/:subCategorySlug"
          element={<CourseSubCategoryPage />}
        />
        <Route
          path="/our-courses/:categorySlug/:subCategorySlug/:slug"
          element={<CourseDetailsPage />}
        />
        <Route path="/success-stories" element={<SuccessStoriesPage />} />
        <Route path="/success-stories/:slug" element={<SuccessStory />} />
        <Route path="/skills-for-life" element={<SkillsForLife />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* Admin Routes */}

        <Route
          path="/admin/*"
          element={
            <AuthProvider>
              <Routes>
                {/* Admin Routes */}
                <Route path="/login" element={<LoginPage />} />{" "}
                {/* Login Page */}
                <Route path="/" element={<AdminLayout />}>
                  <Route index element={<AdminPage />} />
                  <Route path="courses" element={<AdminCoursesPage />} />
                  <Route
                    path="courses/:categorySlug"
                    element={<AdminCourseSubCategoriesPage />}
                  />
                  <Route
                    path="courses/:categorySlug/:subCategorySlug"
                    element={<AdminCourseSubCategoryCoursesPage />}
                  />
                  <Route
                    path="courses/:categorySlug/:subCategorySlug/:slug"
                    element={<AdminCourseDetailsPage />}
                  />
                  <Route
                    path="success-stories"
                    element={<AdminSuccessStoriesPage />}
                  />
                  <Route
                    path="success-stories/new"
                    element={<AdminNewSuccessStoryPage />}
                  />
                  <Route
                    path="success-stories/:slug"
                    element={<AdminSuccessStory />}
                  />
                  <Route path="carousels" element={<CarouselsPage />} />
                  <Route path="testimonials" element={<TestimonialsPage />} />
                  <Route path="uploads" element={<UploadsPage />} />
                  <Route path="settings" element={<SettingsPage />} />
                </Route>
              </Routes>
            </AuthProvider>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {shouldShowNavbar && (
        <>
          <ScrollToTop />
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
