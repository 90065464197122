import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourseCategoryBySlug } from "../../hooks/queries/useGetCourseCategoryBySlug";
import { useSubCategories } from "../../hooks/queries/useSubCategories";
import HeaderImage from "../header/HeaderImage";
import Breadcrumb from "../misc/Breadcrumb";
import ErrorMessage from "../misc/ErrorMessage";
import InfoSection from "../misc/InfoSection";
import LoadingDots from "../misc/LoadingDots";
import { PageWrapper } from "../misc/PageWrapper";
import { CourseCardsGrid } from "./CourseCardsGrid";
import CourseCategoryCard from "./CourseCategoryCard";
import { CoursesWrapper } from "./CoursesWrapper";

const CourseCategoriesPage: React.FC = () => {
  const { categorySlug = "" } = useParams();
  const navigate = useNavigate();

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
  } = useGetCourseCategoryBySlug(categorySlug, "true");

  const {
    data: subCategories = [],
    isLoading: isLoadingSubCategories,
    isError: isErrorSubCategories,
    refetch: fetchSubCategories,
  } = useSubCategories(category?.categoryId || "");

  useEffect(() => {
    if (category) {
      fetchSubCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  if (isErrorSubCategories) {
    return <ErrorMessage message={"Error fetching sub-categories"} />;
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isCategoryLoading || isLoadingSubCategories) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`DTN - ${category?.name}`}</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/" },
            { label: "Our Courses", href: "/our-courses" },
            { label: `${category?.name}` },
          ]}
        />
        {category?.headerImageUrl && (
          <HeaderImage
            backgroundImageUrl={category.headerImageUrl.replace(/ /g, "%20")}
          />
        )}
        <InfoSection />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CoursesWrapper>
            <CourseCardsGrid>
              {subCategories.map((subCategory) => (
                <CourseCategoryCard
                  key={subCategory.subCategoryId}
                  imageUrl={subCategory.imageUrl}
                  logoUrl="/logo.png"
                  title={subCategory.name}
                  onClick={() =>
                    navigate(
                      `/our-courses/${category?.slug}/${subCategory.slug}`
                    )
                  }
                />
              ))}
            </CourseCardsGrid>
          </CoursesWrapper>
        </div>
      </PageWrapper>
    </>
  );
};

export default CourseCategoriesPage;
