import styled from "@emotion/styled";

export const PageWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  minHeight: "100vh",
  margin: "0 auto",
  paddingBottom: "60px", // Reserve space for the bottom navbar

  "@media (max-width: 768px)": {
    marginTop: "7.5rem",
    padding: "0 20px",
    marginBottom: "2rem",
    paddingBottom: "50px", // Smaller padding for mobile bottom navbar
  },

  "@media (min-width: 768px) and (max-width: 1024px)": {
    padding: "0 40px", // Slightly more padding for tablets
    marginBottom: "3rem", // Adjust bottom margin for tablets
    paddingBottom: "60px", // Adjust padding for tablets
  },
});
