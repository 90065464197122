import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import LoadingDots from "../misc/LoadingDots";
import { SubmitButton } from "./Styles";

// Modal Overlay styling
const ModalOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
});

// Modal content styling with a scrollable area
const ModalContent = styled("div")({
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "80vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxSizing: "border-box",
});

// Fixed header with "X" button and title
const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  padding: "8px",
  borderBottom: "1px solid #ccc",
  zIndex: 1,
});

// Scrollable content inside the modal
const ScrollableContent = styled("div")({
  flexGrow: 1,
  overflowY: "auto",
  padding: "10px",
  paddingRight: "10px", // Add padding to prevent content from touching scrollbar
  maxHeight: "calc(80vh - 120px)", // Adjust height considering header/footer
});

// Image grid styling
const ImageGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "10px",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

// Image item styling for selected and hover effect
const ImageItem = styled("div")<{ isSelected: boolean }>(({ isSelected }) => ({
  border: isSelected ? "2px solid #007bff" : "1px solid #ccc",
  padding: "10px",
  cursor: "pointer",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "translateY(-5px)",
  },
  img: {
    width: "100%",
    height: "auto",
  },
}));

interface ImageSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectImage: (imageId: string, imageUrl: string) => void;
}

const ImageSelectionModal: React.FC<ImageSelectionModalProps> = ({
  isOpen,
  onClose,
  onSelectImage,
}) => {
  const [images, setImages] = useState<{ imageId: string; url: string }[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true); // To track if there are more images to load
  const observerRef = useRef<IntersectionObserver | null>(null); // Ref for IntersectionObserver

  const itemsPerPage = 10;

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1); // Reset to first page when modal opens
      setImages([]); // Reset images when modal is opened
      setHasMore(true); // Reset hasMore flag
      fetchImages(1); // Fetch the first page of images
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchImages = async (page: number) => {
    if (!hasMore) return; // If there are no more images, stop fetching

    setIsLoading(true);
    try {
      const response = await getApiInstance().get(
        `/api/images?page=${page}&limit=${itemsPerPage}`
      );
      if (response.data.images.length === 0) {
        setHasMore(false); // If no images returned, stop further requests
      } else {
        setImages((prev) => [...prev, ...response.data.images]); // Append new images
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (imageId: string, imageUrl: string) => {
    setSelectedImage(imageId);
    onSelectImage(imageId, imageUrl);
  };

  const lastImageRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observerRef.current) observerRef.current.disconnect(); // Disconnect previous observer

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });

      if (node) observerRef.current.observe(node); // Observe the last image
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    if (currentPage > 1) {
      fetchImages(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        {/* Sticky header with title and close button */}
        <ModalHeader>
          <h2>Select an Image</h2>
          <button
            onClick={onClose}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </ModalHeader>

        {/* Scrollable content */}
        <ScrollableContent>
          <ImageGrid>
            {images.map((image, index) => {
              if (images.length === index + 1) {
                return (
                  <ImageItem
                    ref={lastImageRef} // Add ref to the last image for infinite scroll trigger
                    key={image.imageId}
                    isSelected={image.imageId === selectedImage}
                    onClick={() => handleSelect(image.imageId, image.url)}
                  >
                    <img src={image.url} alt={`${image.imageId}`} />
                  </ImageItem>
                );
              } else {
                return (
                  <ImageItem
                    key={image.imageId}
                    isSelected={image.imageId === selectedImage}
                    onClick={() => handleSelect(image.imageId, image.url)}
                  >
                    <img src={image.url} alt={`${image.imageId}`} />
                  </ImageItem>
                );
              }
            })}
          </ImageGrid>

          {isLoading && <LoadingDots />}
        </ScrollableContent>

        {/* Bottom "Close" Button */}
        <div
          style={{
            paddingTop: 8,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ccc",
          }}
        >
          {" "}
          <SubmitButton onClick={onClose}>Close</SubmitButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ImageSelectionModal;
