import styled from "@emotion/styled";
import React from "react";

interface CourseCategoryCardProps {
  logoUrl: string;
  imageUrl: string;
  title: string;
  onClick: () => void;
}

const CardContainer = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: white;
  width: 300px;
  height: 200px;
  margin-bottom: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  pointer: cursor;
  user-select: none;

  &:hover {
    transform: scale(1.05); /* Slight scaling effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
  }

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    z-index: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Logo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 125px;
  height: 62.5px;
  z-index: 2;
`;

const Title = styled.h2`
  background-color: rgba(43, 42, 41, 0.5);
  font-family: "Neuropolitical";
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  color: white;
  position: absolute;
  bottom: 0; /* Stick it to the bottom of the image */
  left: 0;
  width: 100%; /* Full width */
  padding: 14px 0; /* Add some padding for better readability */
  z-index: 2;
  text-align: center; /* Center the text */
`;

const CourseCategoryCard: React.FC<CourseCategoryCardProps> = ({
  logoUrl,
  imageUrl,
  title,
  onClick,
}) => {
  return (
    <CardContainer onClick={onClick}>
      <ImageContainer>
        <Image src={imageUrl} alt={title} />
        <Logo src={logoUrl} alt="Logo" />
        <Title>{title}</Title>
      </ImageContainer>
    </CardContainer>
  );
};

export default CourseCategoryCard;
