import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CourseCardsGrid } from "../../components/courses/CourseCardsGrid";
import CourseCategoryCard from "../../components/courses/CourseCategoryCard";
import { CoursesWrapper } from "../../components/courses/CoursesWrapper";
import {
  AdminPageWrapper,
  CenteredContainer,
} from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import ErrorMessage from "../../components/misc/ErrorMessage";
import FloatingButton from "../../components/misc/FloatingButton";
import LoadingDots from "../../components/misc/LoadingDots";
import { useGetStories } from "../../hooks/queries";

const AdminSuccessStoriesPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: successStories, isLoading, isError } = useGetStories();

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching stories"} />;
  }

  const handleStoryClick = (slug: string) => {
    navigate(`/admin/success-stories/${slug}`);
  };

  return (
    <>
      <Helmet>
        <title>DTN - Admin - Success Stories</title>
        <meta
          name="description"
          content="Discover inspiring success stories from students and professionals who enhanced their telecom skills with DTN Academy's courses and bootcamps."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Admin", href: "/admin" },
            { label: "Success Stories" },
          ]}
        />
        <CenteredContainer>
          <CoursesWrapper>
            <CourseCardsGrid>
              {successStories?.length &&
                successStories.map((story) => (
                  <CourseCategoryCard
                    key={story.successStoryId}
                    imageUrl={story.imageUrl}
                    logoUrl="/logo.png"
                    title={story.name}
                    onClick={() => handleStoryClick(story.slug)}
                  />
                ))}
            </CourseCardsGrid>
          </CoursesWrapper>
        </CenteredContainer>
        <FloatingButton
          icon={<FontAwesomeIcon icon={faAdd} />}
          onClick={() => {
            navigate("/admin/success-stories/new");
          }}
        />
      </AdminPageWrapper>
    </>
  );
};

export default AdminSuccessStoriesPage;
