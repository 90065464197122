import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import AcademySectionDesktop from "../components/home/AcademySectionDesktop";
import AcademySectionTest from "../components/home/AcademySectionMobileTest";
import Carousel from "../components/home/Carousel";
import CoursesSection from "../components/home/CoursesSection";
import TestimonialsSection from "../components/home/TestimonialsSection";
import ErrorMessage from "../components/misc/ErrorMessage";
import LoadingDots from "../components/misc/LoadingDots";
import CustomCarousel from "../components/partner-carousel/CustomCarousel";
import { useGetHeroCarousel, useGetPartnerCarousel } from "../hooks/queries";
import useIsMobile from "../hooks/useIsMobile";

const PageWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 2rem;
  padding-bottom: 80px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CarouselWrapper = styled.div`
  padding-top: 2rem;

  @media (max-width: 768px) {
    padding-top: 6.5rem;
  }

  @media (max-width: 1024px) {
    padding-top: 6.5rem;

    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  const isMobile = useIsMobile();

  const {
    data: heroCarouselData,
    isLoading: heroCarouselLoading,
    isError: heroCarouselError,
  } = useGetHeroCarousel("true");

  const {
    data: partnerCarouselData,
    isLoading: partnerCarouselLoading,
    isError: partnerCarouselError,
  } = useGetPartnerCarousel("true");

  if (heroCarouselLoading) {
    return <LoadingDots />;
  }

  if (heroCarouselError) {
    return <ErrorMessage message="Error loading hero carousel images" />;
  }

  if (partnerCarouselLoading) {
    return <LoadingDots />;
  }

  if (partnerCarouselError) {
    return <ErrorMessage message="Error loading partner carousel images" />;
  }

  return (
    <>
      <Helmet>
        <title>DTN - Digital Network Academy</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <PageWrapper>
        <CarouselWrapper>
          {heroCarouselData &&
          heroCarouselData.carouselItems &&
          heroCarouselData.carouselItems.length > 0 ? (
            <Carousel data={heroCarouselData.carouselItems} interval={5000} />
          ) : (
            <ErrorMessage message="No carousel items found" />
          )}
        </CarouselWrapper>
        <div>
          <CoursesSection />
        </div>
        {isMobile || window.innerWidth <= 430 ? (
          <AcademySectionTest />
        ) : (
          <AcademySectionDesktop />
        )}
        <div>
          <TestimonialsSection />
        </div>
        <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
          {partnerCarouselData &&
          partnerCarouselData.carouselItems &&
          partnerCarouselData.carouselItems.length > 0 ? (
            <CustomCarousel
              images={partnerCarouselData?.carouselItems.map((i) => i.image)}
            />
          ) : (
            <ErrorMessage message="No partner carousel items found" />
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default HomePage;
