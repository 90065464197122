import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

const CarouselContainer = styled("div")({
  position: "relative",
  width: "100%",
  overflow: "hidden",
  borderRadius: "8px", // Rounded corners
  "@media (max-width: 768px)": {
    borderRadius: "0",
  },
});

const CarouselInner = styled("div")({
  display: "flex",
  transition: "transform 0.5s ease-in-out", // Slide animation
});

const CarouselItem = styled("div")({
  minWidth: "100%", // Each item takes up full width
  position: "relative", // Ensure content layers properly
  cursor: "pointer", // Add pointer cursor to indicate clickability

  img: {
    width: "100%", // Image takes up full width
    display: "block",
    objectFit: "cover", // Ensure images scale properly
  },
});

const ArrowButton = styled("button")({
  position: "absolute",
  top: "50%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  border: "none",
  color: "white",
  padding: "10px",
  cursor: "pointer",
  zIndex: 10,
  transform: "translateY(-50%)", // Center button vertically
  "&:hover": {
    background: "rgba(0, 0, 0, 0.8)",
  },
  "@media (max-width: 768px)": {
    padding: "5px", // Smaller arrow buttons on mobile screens
  },
});

const DotsContainer = styled("div")({
  position: "absolute",
  bottom: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  gap: "10px",
});

const Dot = styled("div")<{ isActive: boolean }>(({ isActive }) => ({
  width: "10px",
  height: "10px",
  backgroundColor: isActive ? "#333" : "#bbb",
  borderRadius: "50%",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#555",
  },
  "@media (max-width: 768px)": {
    width: "8px", // Smaller dots on mobile screens
    height: "8px",
  },
}));

interface CarouselData {
  image: string;
  link?: string;
}

interface CarouselProps {
  data: CarouselData[];
  interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({ data, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, interval);
    return () => clearInterval(timer);
  }, [data.length, interval]);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  const goToPrevious = () => {
    const newIndex = (currentIndex - 1 + data.length) % data.length;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = (currentIndex + 1) % data.length;
    setCurrentIndex(newIndex);
  };

  return (
    <CarouselContainer ref={carouselRef}>
      <CarouselInner
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {data.map((item, index) => (
          <CarouselItem
            key={index}
            style={{ cursor: item?.link ? "pointer" : "default" }}
          >
            {item.link ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img src={item.image} alt={`Slide ${index}`} />
              </a>
            ) : (
              <div>
                <img src={item.image} alt={`Slide ${index}`} />
              </div>
            )}
          </CarouselItem>
        ))}
      </CarouselInner>

      <ArrowButton onClick={goToPrevious} style={{ left: "10px" }}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </ArrowButton>
      <ArrowButton onClick={goToNext} style={{ right: "10px" }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </ArrowButton>

      <DotsContainer>
        {data.map((_, index) => (
          <Dot
            key={index}
            isActive={index === currentIndex}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

export default Carousel;
