import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CourseCardsGrid } from "../../components/courses/CourseCardsGrid";
import CourseCategoryCard from "../../components/courses/CourseCategoryCard";
import { CoursesWrapper } from "../../components/courses/CoursesWrapper";
import AddCourseCategoryForm from "../../components/form/AddCourseCategoryForm";
import Button from "../../components/form/Button";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import ErrorMessage from "../../components/misc/ErrorMessage";
import FloatingButton from "../../components/misc/FloatingButton";
import LoadingDots from "../../components/misc/LoadingDots";
import Modal from "../../components/misc/Modal";
import { useCategories } from "../../hooks/queries";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { Category, colors } from "../../types";

const AdminCoursesPage: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<Category>();
  const { showToast } = useToast();

  const {
    data: categories,
    isLoading,
    isError,
    refetch: fetchCategories,
  } = useCategories("all", "all");

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryClick = (slug: string) => {
    navigate(`/admin/courses/${slug}`);
  };

  const handlePatchActive = (categoryId: string, active: boolean) => {
    getApiInstance()
      .patch(`/api/courses/categories/${categoryId}/active`, { active })
      .then((response) => {
        if (response.status === 204) {
          fetchCategories();
          showToast("Category updated", "success");
        } else {
          showToast("Error updating category", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        showToast("Error updating category", "error");
      });
  };

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching categories"} />;
  }

  return (
    <>
      <Helmet>
        <title>DTN - Admin - Our Courses</title>
        <meta
          name="description"
          content="Manage and update the courses offered by DTN Academy. Add new courses, edit existing ones, and organise course categories for the DTN Academt website."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[{ label: "Admin", href: "/admin" }, { label: "Our Courses" }]}
        />
        <CoursesWrapper>
          <CourseCardsGrid>
            {categories?.length &&
              categories.length > 0 &&
              categories.map((category) => (
                <div style={{ position: "relative" }} key={category.categoryId}>
                  <Button
                    label={category.isActive ? "Active" : "Inactive"}
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "15px",
                      backgroundColor: category.isActive
                        ? "#4caf50"
                        : colors.brand.brandDarkRed.hex,
                      fontSize: "12px",
                      cursor: "pointer",
                      width: "65px",
                      zIndex: 100,
                    }}
                    onClick={() =>
                      handlePatchActive(category.categoryId, !category.isActive)
                    }
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "15px",
                      zIndex: 100,
                      cursor: "pointer",
                      backgroundColor: "rgba(255,255,255,0.7)",
                      borderRadius: "50%",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setSelectedCategory(category);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} color="gray" size="lg" />
                  </div>
                  <CourseCategoryCard
                    logoUrl="/logo.png"
                    imageUrl={category.imageUrl}
                    title={category.name}
                    key={category.categoryId}
                    onClick={() => handleCategoryClick(category.slug)}
                  />
                </div>
              ))}
          </CourseCardsGrid>
        </CoursesWrapper>

        <FloatingButton onClick={() => setIsModalOpen(true)} />

        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Add Course Category"}
        >
          <AddCourseCategoryForm
            onSuccess={() => {
              fetchCategories();
              setIsModalOpen(false);
            }}
            onClose={() => setIsModalOpen(false)}
          />
        </Modal>
        <Modal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          title={"Edit Course Category"}
        >
          <AddCourseCategoryForm
            initialCategory={selectedCategory}
            onSuccess={() => {
              fetchCategories();
              setIsEditModalOpen(false);
            }}
            onClose={() => setIsEditModalOpen(false)}
          />
        </Modal>
      </AdminPageWrapper>
    </>
  );
};

export default AdminCoursesPage;
