import styled from "@emotion/styled";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { colors, PDF } from "../../types";
import Button from "../form/Button";
import {
  FormWrapper,
  HR,
  ImageButton,
  ImageContainer,
  ImageSelectionWrapper,
} from "../form/Styles";
import Modal from "../misc/Modal";
import PaginationControls from "../Pagination";
import { FilterInput, FilterInputWrapper, FilterLabel } from "./ImagesTab";

const SearchBarWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "space-between",
  marginBottom: "16px",

  "@media (min-width: 1025px)": {
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },

  "media (min-width: 768px)": {
    marginBottom: "32px",
    gap: 0,
  },
});

const GalleryContainer = styled.div({
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "1fr",
  backgroundColor: "#f9f9f9",
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",

  "@media (min-width: 601px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (min-width: 1025px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
});

const PDFItemContainer = styled.div({
  display: "flex",
  alignItems: "center",
  padding: "12px",
  margin: "8px 0",
  border: "1px solid #ddd",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
  },
});

const PDFIconWrapper = styled.div({
  marginRight: "16px",
  fontSize: "24px",
  color: "#d9534f",
});

const PDFLink = styled.a({
  textDecoration: "none",
  color: colors.brand.brandBlack.hex,
  fontSize: "12px",
  fontWeight: "500",
  "&:hover": {
    textDecoration: "underline",
    color: colors.brand.brandDarkRed.hex,
  },
});

const PDFTab: React.FC = () => {
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [pdfs, setPDFs] = useState<PDF[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { showToast } = useToast();

  const ITEMS_PER_PAGE = 15;

  const getPDFs = async (page: number = 1) => {
    try {
      const response = await getApiInstance().get(
        `/api/dtn/pdfs?page=${page}&limit=${ITEMS_PER_PAGE}&search=${search}`
      );

      const { items, totalPages } = response.data;
      setPDFs(items);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching PDFs:", error);
      showToast("Error fetching PDFs", "error");
    }
  };

  useEffect(() => {
    getPDFs(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      showToast("Please select a PDF file to upload", "error");
      return;
    }

    setIsUploading(true);

    try {
      const fileData = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(selectedFile);
      });

      const base64Data = fileData.split(",")[1];

      const payload = {
        fileName: selectedFile.name,
        fileData: base64Data,
      };

      const response = await getApiInstance().put(
        "/api/dtn/pdf/upload",
        payload
      );

      if (response.status === 204) {
        showToast("PDF uploaded successfully", "success");
        setModalOpen(false);
        setSelectedFile(null);
        getPDFs(currentPage);
      } else {
        throw new Error("Failed to upload PDF");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      showToast("Error uploading PDF", "error");
    } finally {
      setIsUploading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <SearchBarWrapper>
        <FilterInputWrapper>
          <FilterInput
            type="text"
            name="search"
            placeholder=" "
            value={search}
            onChange={handleSearchChange}
          />
          <FilterLabel>Search</FilterLabel>
        </FilterInputWrapper>
        <Button
          style={{ width: 100 }}
          onClick={() => setModalOpen(true)}
          label={"Add PDF"}
        />
      </SearchBarWrapper>
      <HR style={{ marginBottom: 16 }} />
      <>
        {totalPages > 1 && (
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </>
      <GalleryContainer>
        {pdfs.map((pdf) => (
          <PDFItemContainer key={pdf.pdfId}>
            <PDFIconWrapper>
              <FontAwesomeIcon icon={faFilePdf} />
            </PDFIconWrapper>
            <PDFLink href={pdf.url} target="_blank" rel="noopener noreferrer">
              {pdf.filename}
            </PDFLink>
          </PDFItemContainer>
        ))}
      </GalleryContainer>

      <Modal
        title="Add PDF"
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedFile(null);
        }}
      >
        <FormWrapper>
          <HR style={{ marginBottom: 8 }} />
          <ImageSelectionWrapper
            style={{ alignSelf: "center", justifyContent: "center" }}
          >
            <ImageContainer onClick={handleFileClick}>
              <ImageButton type="button">
                {selectedFile ? "Change PDF" : "Select PDF"}
              </ImageButton>
            </ImageContainer>
            {selectedFile && (
              <p style={{ marginTop: 8, textAlign: "center" }}>
                Selected file: <br />
                <strong>{selectedFile.name}</strong>
              </p>
            )}
            <input
              type="file"
              accept="application/pdf"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </ImageSelectionWrapper>
          <HR style={{ margin: 0 }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ width: 100 }}
              label={"Cancel"}
              onClick={() => {
                setModalOpen(false);
                setSelectedFile(null);
              }}
            />
            <Button
              style={{ width: 100 }}
              label={isUploading ? "Uploading..." : "Add"}
              onClick={handleUpload}
              disabled={isUploading}
            />
          </div>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default PDFTab;
