import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/login/LoginForm";
import useAuth from "../../hooks/useAuth";

// Styled component for the container
const LoginContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: "1rem",
  margin: "0 auto",
});

const LoginPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated()) {
      navigate("/admin");
    }
  }, [auth, navigate]);

  return (
    <>
      <Helmet>
        <title>{`DTN - Admin - Login`}</title>
        <meta
          name="description"
          content="Login to the DTN Academy admin dashboard to manage content, courses, and other administrative functions."
        />
      </Helmet>
      <LoginContainer>
        <LoginForm />
      </LoginContainer>
    </>
  );
};

export default LoginPage;
