import React from "react";
import { useNavigate } from "react-router-dom";
import { CourseCardsGrid } from "../courses/CourseCardsGrid";
import CourseCategoryCard from "../courses/CourseCategoryCard";
import { CoursesWrapper } from "../courses/CoursesWrapper";

interface CourseCardProps {
  title: string;
  imageUrl: string;
  url: string;
}

const courseCardsData: CourseCardProps[] = [
  {
    title: "Courses",
    imageUrl: `${process.env.REACT_APP_CDN_HOST}/uploads/card/Black.png`,
    url: "/our-courses",
  },
  {
    title: "DTN Online (E-Learning)",
    imageUrl: `${process.env.REACT_APP_CDN_HOST}/uploads/card/red.png`,
    url: "https://www.dtna.co.uk",
  },
  {
    title: "Apprenticeships",
    imageUrl: `${process.env.REACT_APP_CDN_HOST}/uploads/card/Black.png`,
    url: "/our-courses/apprenticeships",
  },
  {
    title: "Armed Forces Resettlement",
    imageUrl: `${process.env.REACT_APP_CDN_HOST}/uploads/card/red.png`,
    url: "/our-courses/armed-forces-resettlement",
  },
];

const CoursesSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <CoursesWrapper>
      <h1>Our Courses</h1>
      <CourseCardsGrid>
        {courseCardsData.map((course, index) => (
          <CourseCategoryCard
            key={index}
            logoUrl={"/logo.png"}
            imageUrl={course.imageUrl}
            title={course.title}
            onClick={() => {
              if (course.url.startsWith("http")) {
                window.open(course.url, "_blank");
              } else {
                navigate(course.url);
              }
            }}
          />
        ))}
      </CourseCardsGrid>
    </CoursesWrapper>
  );
};

export default CoursesSection;
