import { Category } from "./Category";

export enum CourseSubCategoryType {
  COURSE = "COURSE",
  APPRENTICESHIP = "APPRENTICESHIP",
  ARMY = "ARMY",
  CITY_AND_GUILDS = "CITY_AND_GUILDS",
  EUSR = "EUSR",
}

export interface SubCategory extends Category {
  subCategoryId: string;
  type: CourseSubCategoryType;
}
