import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

const Button = styled("button")({
  position: "fixed",
  bottom: "30px",
  right: "30px",
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  fontSize: "24px",
  boxShadow: "0 4px 8px rgba(43, 42, 41, 0.2)",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,

  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
  },
  "@media (max-width: 900px)": {
    bottom: "15px",
    right: "15px",
    width: "40px",
    height: "40px",
  },
});

interface FloatingButtonProps {
  onClick: () => void;
  icon?: React.ReactNode; // This will allow passing in a Font Awesome icon or any other icon as a React element
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick, icon }) => {
  return <Button onClick={onClick}>{icon ? icon : "+"}</Button>;
};

export default FloatingButton;
