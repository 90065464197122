import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { Category } from "../../types";
import ImageSelectionModal from "./ImageSelectionModal";
import {
  FormWrapper,
  HR,
  ImageButton,
  ImageContainer,
  ImagePreview,
  ImageSelectionWrapper,
  InputField,
  InputWrapper,
  Label,
  ModalContent,
  NavigationButtons,
  SubmitButton,
} from "./Styles";

interface AddCourseCategoryFormProps {
  initialCategory?: Category;
  onSuccess: () => void;
  onClose: () => void;
}

const AddCourseCategoryForm: React.FC<AddCourseCategoryFormProps> = ({
  initialCategory,
  onSuccess,
  onClose,
}) => {
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isHeaderImageModalOpen, setIsHeaderImageModalOpen] = useState(false);

  const [category, setCategory] = useState<Category>({
    categoryId: initialCategory?.categoryId || "",
    name: initialCategory?.name || "",
    imageUrl: initialCategory?.imageUrl || "",
    imageId: initialCategory?.imageId || "",
    headerImageUrl: initialCategory?.headerImageUrl || "",
    headerImageId: initialCategory?.headerImageId || "",
    headerText: initialCategory?.headerText || "",
    order: initialCategory?.order || 0,
    slug: initialCategory?.slug || "",
    isActive: initialCategory?.isActive ?? true,
  });

  // Generate slug when name changes
  useEffect(() => {
    if (
      category.name &&
      (!initialCategory || category.name !== initialCategory.name)
    ) {
      const generatedSlug = category.name
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "");
      setCategory((prev) => ({
        ...prev,
        slug: generatedSlug,
      }));
    }
  }, [category.name, initialCategory]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setCategory((prev) => ({ ...prev, imageId, imageUrl }));
    setIsImageModalOpen(false);
  };

  const handleHeaderImageSelect = (
    headerImageId: string,
    headerImageUrl: string
  ) => {
    console.log(headerImageId, headerImageUrl);
    setCategory((prev) => ({ ...prev, headerImageId, headerImageUrl }));
    setIsHeaderImageModalOpen(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const api = getApiInstance();

      const payload = {
        name: category.name,
        slug: category.slug,
        imageId: category.imageId,
        headerImageId: category.headerImageId,

        active: category.isActive,
      };

      if (initialCategory?.categoryId) {
        // Update existing category
        const resp = await api.patch(
          `/api/courses/categories/${initialCategory.categoryId}`,
          payload
        );

        if (resp.status === 204) {
          showToast("Course category updated", "success");
          onSuccess();
        } else {
          showToast("Failed to update category", "error");
        }
      } else {
        // Create new category
        const resp = await api.post(`/api/courses/categories`, payload);

        if (resp.status === 204) {
          showToast("Course category added", "success");
          onSuccess();
        } else {
          showToast("Failed to add category", "error");
        }
      }
    } catch (error) {
      showToast(
        initialCategory
          ? "Failed to update category"
          : "Failed to add category",
        "error"
      );
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalContent>
      <HR style={{ marginTop: 0 }} />
      <FormWrapper onSubmit={handleSubmit}>
        <InputWrapper>
          <InputField
            type="text"
            name="name"
            value={category.name}
            onChange={handleInputChange}
            placeholder=" "
            required
          />
          <Label>Name</Label>
        </InputWrapper>

        <InputWrapper>
          <InputField
            type="text"
            name="slug"
            value={category.slug}
            onChange={handleInputChange}
            placeholder=" "
            required
          />
          <Label>Slug</Label>
        </InputWrapper>

        {/* <InputWrapper>
          <InputField
            type="text"
            name="headerText"
            value={category.headerText}
            onChange={handleInputChange}
            placeholder=" "
          />
          <Label>Header Text</Label>
        </InputWrapper> */}

        {/* {!!initialCategory && (
          <InputWrapper>
            <InputField
              type="number"
              name="order"
              value={category.order}
              onChange={handleInputChange}
              placeholder=" "
            />
            <Label>Order</Label>
          </InputWrapper>
        )} */}

        <ImageSelectionWrapper>
          <ImageContainer onClick={() => setIsImageModalOpen(true)}>
            {category.imageUrl ? (
              <ImagePreview src={category.imageUrl} alt="Selected" />
            ) : (
              <ImageButton type="button">Select Card Image</ImageButton>
            )}
          </ImageContainer>

          <ImageContainer onClick={() => setIsHeaderImageModalOpen(true)}>
            {category.headerImageUrl ? (
              <ImagePreview src={category.headerImageUrl} alt="Selected" />
            ) : (
              <ImageButton type="button">Select Header Image</ImageButton>
            )}
          </ImageContainer>
        </ImageSelectionWrapper>

        <ImageSelectionModal
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
          onSelectImage={handleImageSelect}
        />

        <ImageSelectionModal
          isOpen={isHeaderImageModalOpen}
          onClose={() => setIsHeaderImageModalOpen(false)}
          onSelectImage={handleHeaderImageSelect}
        />
        <HR style={{ margin: 0 }} />
        <NavigationButtons>
          <SubmitButton type="button" onClick={onClose}>
            Close
          </SubmitButton>
          <SubmitButton
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {initialCategory ? "Update" : "Save"}
          </SubmitButton>
        </NavigationButtons>
      </FormWrapper>
    </ModalContent>
  );
};

export default AddCourseCategoryForm;
