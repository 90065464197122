import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import AddUserForm from "../../components/admin/AddUserForm";
import ChangePasswordForm from "../../components/admin/ChangePasswordForm";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import { getApiInstance } from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";

const PageContainer = styled("div")<{ isAdmin: boolean }>(({ isAdmin }) => ({
  display: "grid",
  gridTemplateColumns: isAdmin ? "1fr 1fr" : "1fr",
  gap: "2rem",
  marginTop: "1rem",
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "1200px",

  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
    gap: "1rem",
  },
}));

const SettingsPage: React.FC = () => {
  const { user } = useAuth();

  const handleAddUser = async (formData: FormData) => {
    try {
      const response = await getApiInstance().post("/admin/user", formData);
      return response.data;
    } catch (error) {
      console.error("Failed to add user", error);
      throw new Error("Failed to add user");
    }
  };

  return (
    <>
      <Helmet>
        <title>DTN - Admin - Settings</title>
        <meta
          name="description"
          content="Access the DTN Academy admin settings to manage your profile, change your password, and manage users for the platform."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/admin" }, { label: "Settings" }]}
        />
        <PageContainer isAdmin={user?.role === "ADMIN"}>
          <ChangePasswordForm />
          {user?.role === "ADMIN" && <AddUserForm onSubmit={handleAddUser} />}
        </PageContainer>
      </AdminPageWrapper>
    </>
  );
};

export default SettingsPage;
