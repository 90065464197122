import styled from "@emotion/styled";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import { PageWrapper } from "../components/misc/PageWrapper";
import { colors } from "../types/colors";

const ContactAndFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const ContactSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-right: 2rem; /* Add spacing between contact section and form */

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 2rem; /* Add spacing between sections on mobile */
  }
`;

const ContactItem = styled.div`
  text-align: center;
  flex: 1;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align icon and text */
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const ContactTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ContactInfo = styled.p`
  font-size: 0.9rem;
  color: #555;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: inherit; /* Inherit color from parent */
`;

const HighlightedText = styled.span`
  color: #a81010;
  font-weight: 700;
`;

const ContactForm = styled.div`
  flex: 1;

  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const MapSection = styled.div`
  margin: 2rem 0;

  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>DTN - Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Digital Telecoms Network Academy. We are here to assist you with any questions regarding our telecom courses, training programs, and services. Contact us today for more information."
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/" }, { label: "Contact" }]}
        />
        <HeaderImage
          backgroundImageUrl={`${process.env.REACT_APP_CDN_HOST}/uploads/banner/about.png`}
        />
        <ContactAndFormWrapper>
          <ContactSection>
            <ContactItem>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  color="#a81010"
                  size="2x"
                />
              </IconWrapper>
              <ContactTitle>Our Address</ContactTitle>
              <ContactInfo>1 Wylam Court</ContactInfo>
              <ContactInfo>Westland Way</ContactInfo>
              <ContactInfo>Preston Farm Industrial Estate</ContactInfo>
              <ContactInfo>Stockton-On-Tees</ContactInfo>
              <ContactInfo>TS18 3FB</ContactInfo>
            </ContactItem>

            <ContactItem>
              <IconWrapper>
                <FontAwesomeIcon icon={faPhoneAlt} color="#a81010" size="2x" />
              </IconWrapper>
              <ContactTitle>Phone Number</ContactTitle>
              <ContactInfo>
                <ContactLink href="tel:+441642804000">01642 804000</ContactLink>
              </ContactInfo>
            </ContactItem>

            <ContactItem>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  color={colors.brand.brandDarkRed.hex}
                  size="2x"
                />
              </IconWrapper>
              <ContactTitle>Mobile</ContactTitle>
              <ContactInfo>
                <ContactLink href="tel:+447903202240">
                  <HighlightedText>07903202240</HighlightedText>
                </ContactLink>
              </ContactInfo>
            </ContactItem>
          </ContactSection>
          {/* <ContactForm>
            <EnquiryForm />
          </ContactForm> */}
        </ContactAndFormWrapper>
        <MapSection>
          <div style={{ width: "100%", height: "500px" }}>
            <iframe
              title="DTN Academy Ltd"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2314.1482587903347!2d-1.3225629870526956!3d54.548511883985306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e92ce0cb44bc3%3A0x48b6f9ee6e6ca5e2!2sDTN%20Academy%20Ltd!5e0!3m2!1sen!2sde!4v1727256758296!5m2!1sen!2sde"
              width="100%"
              height="450"
              style={{
                border: 0,
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              loading="lazy"
            />
          </div>
        </MapSection>
      </PageWrapper>
    </>
  );
};

export default Contact;
