import styled from "@emotion/styled";

export const CoursesWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
  alignSelf: "center",
  maxWidth: "1280px",
  margin: "0 auto",
  paddingTop: "1rem",
  paddingBottom: "1rem",

  // Mobile-friendly adjustments
  "@media (max-width: 768px)": {
    padding: "1rem", // Reduce padding on smaller screens
    maxWidth: "100%", // Ensure full width on smaller devices
  },
});
