/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React, { useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import Button from "../form/Button";

// Styled Components with Mobile-Friendly Styles
const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  margin: 2rem;

  @media (max-width: 768px) {
    margin: 1rem;
    padding: 1rem;
  }
`;

const FormWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 360px;

  @media (max-width: 768px) {
    padding: 1rem;
    max-width: 100%;
  }
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.25rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.15rem;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const ErrorMessage = styled.p`
  color: #d9534f;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const SuccessMessage = styled.p`
  color: #5cb85c;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const ChangePasswordForm: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { showToast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Clear previous error or success message
    setError(null);
    setSuccessMessage(null);

    if (newPassword !== confirmPassword) {
      showToast("Passwords do not match", "error");
      return;
    }

    try {
      const response = await getApiInstance().post(
        "/api/admin/auth/change-password",
        {
          currentPassword,
          newPassword,
        }
      );

      if (response.status === 204) {
        setError(null);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        showToast("Password changed successfully", "success");
      } else {
        throw new Error("Failed to change password");
      }
    } catch (error: any) {
      setError(
        error?.response?.data?.message ||
          "Failed to change password. Please try again."
      );
    }
  };

  return (
    <FormContainer>
      <FormWrapper>
        <Title>Change Password</Title>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="currentPassword">Current Password:</Label>
            <Input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="newPassword">New Password:</Label>
            <Input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword">Confirm Password:</Label>
            <Input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </FormGroup>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
          <Button onClick={() => handleSubmit} label={"Change Password"} />
        </form>
      </FormWrapper>
    </FormContainer>
  );
};

export default ChangePasswordForm;
