import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { CourseCardsGrid } from "../../../components/courses/CourseCardsGrid";
import { CoursesWrapper } from "../../../components/courses/CoursesWrapper";
import OtherCoursePage from "../../../components/courses/OtherCoursePage";
import AddCourseForm from "../../../components/form/AddCourseForm";
import Button from "../../../components/form/Button";
import { AdminPageWrapper } from "../../../components/form/Styles";
import Breadcrumb from "../../../components/misc/Breadcrumb";
import ErrorMessage from "../../../components/misc/ErrorMessage";
import FloatingButton from "../../../components/misc/FloatingButton";
import LoadingDots from "../../../components/misc/LoadingDots";
import Modal from "../../../components/misc/Modal";
import { useGetCoursesBySubCategoryId } from "../../../hooks/queries";
import { useGetCourseCategoryBySlug } from "../../../hooks/queries/useGetCourseCategoryBySlug";
import { useGetCourseSubCategoryBySlug } from "../../../hooks/queries/useGetCourseSubCategoryBySlug";
import { getApiInstance } from "../../../hooks/useApi";
import { useToast } from "../../../hooks/useToast";
import { colors, CourseSubCategoryType } from "../../../types";
import AdminOtherCoursePage from "./AdminOtherCoursePage";
import CourseCard from "./CourseCard";

const AdminCourseSubCategoryCoursesPage = () => {
  const navigate = useNavigate();
  const { categorySlug = "", subCategorySlug = "" } = useParams();
  const [otherCourseEdit, setOtherCourseEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToast } = useToast();

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    refetch: fetchCategory,
  } = useGetCourseCategoryBySlug(categorySlug);

  useEffect(() => {
    if (categorySlug) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug]);

  const {
    data: subCategory,
    isLoading: isSubCategoryLoading,
    isError: isSubCategoryError,
    refetch: fetchSubCategory,
  } = useGetCourseSubCategoryBySlug(subCategorySlug);

  useEffect(() => {
    if (subCategorySlug) {
      fetchSubCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategorySlug]);

  const {
    data: courses = [],
    isLoading: isCoursesLoading,
    isError: isCoursesError,
    refetch: fetchCourses,
  } = useGetCoursesBySubCategoryId(subCategory?.subCategoryId || "", "all");

  useEffect(() => {
    if (
      subCategory?.subCategoryId &&
      subCategory?.type === CourseSubCategoryType.COURSE
    ) {
      fetchCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory?.subCategoryId]);

  if (isCategoryLoading || isSubCategoryLoading || isCoursesLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isSubCategoryError) {
    return <ErrorMessage message={"Error fetching sub-category"} />;
  }

  if (isCoursesError) {
    return <ErrorMessage message={"Error fetching courses"} />;
  }

  const handleCategoryClick = (
    category: string,
    subCategory: string,
    slug: string
  ) => {
    navigate(`/admin/courses/${category}/${subCategory}/${slug}`);
  };

  const handlePatchActive = (courseId: string, active: boolean) => {
    getApiInstance()
      .patch(`/api/courses/${courseId}/active`, { active })
      .then((response) => {
        if (response.status === 204) {
          fetchCourses();
          showToast("Course updated", "success");
        } else {
          showToast("Error updating course", "error");
        }
      })
      .catch((error) => {
        console.error("Error updating course:", error);
        showToast("Error updating course", "error");
      });
  };

  return (
    <>
      <Helmet>
        <title>{`DTN - Admin - ${subCategory?.name}`}</title>
        <meta
          name="description"
          content="Manage and update the courses offered by DTN Academy. Add new courses, edit existing ones, and organise course categories for the DTN Academt website."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Home", href: "/admin" },
            { label: "Courses", href: "/admin/courses" },
            {
              label: `${category?.name}`,
              href: `/admin/courses/${category?.slug}`,
            },
            { label: `${subCategory?.name}` },
          ]}
        />

        <CoursesWrapper>
          {subCategory &&
            [CourseSubCategoryType.COURSE].includes(subCategory?.type) && (
              <>
                <CourseCardsGrid>
                  {courses?.length &&
                    courses.map((course) => (
                      <div
                        style={{ position: "relative" }}
                        key={course.courseId}
                      >
                        <Button
                          label={course.isActive ? "Active" : "Inactive"}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: course.isActive
                              ? "#4caf50"
                              : colors.brand.brandDarkRed.hex,
                            fontSize: "12px",
                            cursor: "pointer",
                            width: "65px",
                            zIndex: 100,
                          }}
                          onClick={() =>
                            handlePatchActive(course.courseId, !course.isActive)
                          }
                        />
                        <CourseCard
                          imageUrl={course.imageUrl}
                          logoUrl="/logo.png"
                          title={course.name}
                          description={course.description}
                          key={course.courseId}
                          onClick={() =>
                            handleCategoryClick(
                              categorySlug,
                              subCategorySlug,
                              course.slug
                            )
                          }
                        />
                      </div>
                    ))}
                </CourseCardsGrid>
                <FloatingButton onClick={() => setIsModalOpen(true)} />
                <Modal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  title={"Add New Course"}
                >
                  {category && (
                    <AddCourseForm
                      categoryId={category.categoryId}
                      onSuccess={fetchCourses}
                      onClose={() => setIsModalOpen(false)}
                    />
                  )}
                </Modal>
              </>
            )}
        </CoursesWrapper>

        {subCategory &&
          [
            CourseSubCategoryType.APPRENTICESHIP,
            CourseSubCategoryType.ARMY,
            CourseSubCategoryType.CITY_AND_GUILDS,
            CourseSubCategoryType.EUSR,
          ].includes(subCategory.type) && (
            <>
              {otherCourseEdit ? (
                <AdminOtherCoursePage
                  subCategorySlug={subCategorySlug}
                  setOtherCourseEdit={setOtherCourseEdit}
                />
              ) : (
                <>
                  <FloatingButton
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => setOtherCourseEdit(true)}
                  />
                  <OtherCoursePage
                    admin={true}
                    subCategorySlug={subCategorySlug}
                  />
                </>
              )}
            </>
          )}
      </AdminPageWrapper>
    </>
  );
};

export default AdminCourseSubCategoryCoursesPage;
