import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import { PageWrapper } from "../components/misc/PageWrapper";
import { colors } from "../types/colors";

const TermsParagraph = styled("p")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  marginBottom: "1rem",
});

const PhoneLink = styled("a")({
  color: colors.brand.brandDarkRed.hex,
  textDecoration: "none",
  fontWeight: "bold",
  "&:hover": {
    textDecoration: "underline",
  },
});

const TermsOfService: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>DTN - Terms Of Service</title>
        <meta
          name="description"
          content="Review the Terms of Service for Digital Telecoms Network Academy. Learn about the guidelines, rules, and conditions for using our website and enrolling in our telecom training programs."
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/" }, { label: "Terms of Service" }]}
        />
        <HeaderImage
          backgroundImageUrl={`https://dtnacademy.lon1.cdn.digitaloceanspaces.com/uploads/banner/SERVICE.png`}
        />

        <TermsParagraph>
          <strong>Last updated: 2nd March 2019</strong>
        </TermsParagraph>
        <TermsParagraph>
          Please read these Terms and Conditions (“Terms”, “Terms and
          Conditions”) carefully before using the www.dtnacademy.com website
          (the “Service”) operated by DTN Academy (“us”, “we”, or “our”).
        </TermsParagraph>
        <TermsParagraph>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users, and others who access or use the Service.
        </TermsParagraph>
        <TermsParagraph>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service. This Terms and Conditions agreement for DTN
          Academy is managed by DTN Academy.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Accounts</strong>
          <br />
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </TermsParagraph>
        <TermsParagraph>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </TermsParagraph>
        <TermsParagraph>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Links To Other Web Sites</strong>
          <br />
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by DTN Academy.
        </TermsParagraph>
        <TermsParagraph>
          DTN Academy has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third-party web
          sites or services. You further acknowledge and agree that DTN Academy
          shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with
          use of or reliance on any such content, goods, or services available
          on or through any such web sites or services.
        </TermsParagraph>
        <TermsParagraph>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Termination</strong>
          <br />
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </TermsParagraph>
        <TermsParagraph>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </TermsParagraph>
        <TermsParagraph>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </TermsParagraph>
        <TermsParagraph>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </TermsParagraph>
        <TermsParagraph>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Governing Law</strong>
          <br />
          These Terms shall be governed and construed in accordance with the
          laws of the United Kingdom, without regard to its conflict of law
          provisions.
        </TermsParagraph>
        <TermsParagraph>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Changes</strong>
          <br />
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </TermsParagraph>
        <TermsParagraph>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </TermsParagraph>
        <TermsParagraph>
          <strong>Contact Us</strong>
          <br />
          If you have any questions about these Terms, please contact us on:
          <PhoneLink href="tel:+441642804000"> 01642 804000</PhoneLink>
          <br />
          <br />
        </TermsParagraph>
      </PageWrapper>
    </>
  );
};

export default TermsOfService;
