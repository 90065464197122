export const decodeBase64Markdown = (base64Content: string) => {
  try {
    const decodedString = atob(base64Content);

    // Convert the decoded string from binary to text with proper encoding
    const utf8String = decodeURIComponent(escape(decodedString));
    return utf8String;
  } catch (error) {
    console.error("Failed to decode base64 markdown", error);
    return "";
  }
};

export const encodeBase64Markdown = (content: string) => {
  try {
    // Convert the content into a URI-encoded string to ensure proper encoding
    const utf8String = unescape(encodeURIComponent(content));

    // Encode the string to base64
    const base64Content = btoa(utf8String);
    return base64Content;
  } catch (error) {
    console.error("Failed to encode markdown to base64", error);
    return "";
  }
};
