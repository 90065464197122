import styled from "@emotion/styled";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import {
  useGetCourseBySlug,
  useGetCourseCategoryBySlug,
  useGetCourseSubCategoryBySlug,
} from "../../hooks/queries";
import { colors } from "../../types/colors";
import HeaderImage from "../header/HeaderImage";
import Breadcrumb from "../misc/Breadcrumb";
import ErrorMessage from "../misc/ErrorMessage";
import InfoSection from "../misc/InfoSection";
import LoadingDots from "../misc/LoadingDots";
import { PageWrapper } from "../misc/PageWrapper";
import CourseDetailPart from "./CourseDetailPart";
import RelatedCourses from "./RelatedCourses";

export const CourseWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  margin: "0 auto",
  marginBottom: "1rem",
  padding: "1rem",
  backgroundColor: "#f9f9f9",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "1px solid #e0e0e0",

  // Mobile-friendly adjustments
  "@media (max-width: 768px)": {
    padding: "1rem",
    maxWidth: "100%",
    boxShadow: "none",
    border: "none",
  },

  // Tablet devices (max-width: 960px)
  "@media (max-width: 960px)": {
    padding: "8px", // Slightly smaller padding for tablets
  },
});

const DeliveryInformation = styled("div")({
  display: "flex",
  flexDirection: "column", // Keep the text in a column layout
  alignItems: "center", // Keep the text centered
  justifyContent: "center", // Vertically center the text
  // textDecoration: "underline",
  textAlign: "center",
  fontFamily: "Neuropolitical, sans-serif",
  fontSize: "1.5rem",
  padding: "1rem 1.25rem 1.25rem", // Reduced top padding
  color: colors.brand.brandBlack.hex,
  backgroundColor: colors.brand.brandSilver.rgbaString({ alpha: 0.1 }),
  borderRadius: "8px",
  border: "2px solid #e0e0e0",
  margin: "0.5rem 0", // Adjusted margins to reduce space between other elements
  marginBottom: "1rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  position: "relative", // Added to enable absolute positioning for the link

  // Media query for mobile devices (max-width: 600px)
  "@media (max-width: 600px)": {
    fontSize: "1rem",
    padding: "0.75rem 1rem", // Reduce padding for mobile
    margin: "1rem 0",
  },

  // Media query for tablet devices (max-width: 960px)
  "@media (max-width: 960px)": {
    fontSize: "14px",
    padding: "1rem",
    margin: "8px",
  },
});

const DownloadLink = styled("a")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  position: "absolute",
  top: "16px",
  right: "16px",
  textDecoration: "underline",
  fontSize: "1rem",
  display: "flex", // Use flex to align the icon and text
  alignItems: "center", // Vertically align icon and text
  gap: "8px", // Space between the icon and the text
  color: colors.brand.brandDarkRed.hex,
  "@media (max-width: 600px)": {
    display: "none", // Hide the link on mobile
  },
});

const MobileDownloadContainer = styled("div")({
  display: "none", // Hide the container by default
  // Media query for mobile devices (max-width: 600px)
  "@media (max-width: 600px)": {
    display: "flex", // Show the container on mobile
    justifyContent: "center", // Center the link
    alignItems: "center", // Center the link
  },
});

const MobileDownloadLink = styled("a")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  textDecoration: "underline",
  fontSize: "0.75rem",
  color: colors.brand.brandDarkRed.hex,
  "@media (max-width: 600px)": {
    display: "block",
  },
});

const CourseDetailsPage: React.FC = () => {
  const { categorySlug = "", subCategorySlug = "", slug = "" } = useParams();

  const {
    data: category,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    refetch: fetchCategory,
  } = useGetCourseCategoryBySlug(categorySlug);

  useEffect(() => {
    if (categorySlug) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySlug]);

  const {
    data: subCategory,
    isLoading: isSubCategoryLoading,
    isError: isSubCategoryError,
    refetch: fetchSubCategory,
  } = useGetCourseSubCategoryBySlug(subCategorySlug);

  useEffect(() => {
    if (subCategorySlug) {
      fetchSubCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategorySlug]);

  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
    refetch: fetchCourse,
  } = useGetCourseBySlug(slug);

  useEffect(() => {
    if (slug) {
      fetchCourse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (isCategoryLoading || isSubCategoryLoading || isCourseLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCategoryError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  if (isSubCategoryError) {
    return <ErrorMessage message={"Error fetching sub-category"} />;
  }

  if (isCourseError) {
    return <ErrorMessage message={"Error fetching course"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`DTN - ${course?.name}`}</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <PageWrapper>
        <div>
          <Breadcrumb
            items={[
              { label: "Home", href: "/" },
              { label: "Our Courses", href: "/our-courses" },
              {
                label: `${category?.name}`,
                href: `/our-courses/${categorySlug}`,
              },
              {
                label: `${subCategory?.name}`,
                href: `/our-courses/${categorySlug}/${subCategorySlug}`,
              },
              { label: `${course?.name}` },
            ]}
          />
          <HeaderImage
            backgroundImageUrl={`${subCategory?.headerImageUrl.replace(
              / /g,
              "%20"
            )}`}
          />
          <InfoSection />
          <MobileDownloadContainer>
            <MobileDownloadLink
              href={course?.details?.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFilePdf} size="xl" /> {/* PDF icon */}
              Download PDF
            </MobileDownloadLink>
          </MobileDownloadContainer>
          <CourseWrapper>
            <DeliveryInformation>
              Course Delivery Information
              <DownloadLink
                href={course?.details?.pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFilePdf} size="xl" /> {/* PDF icon */}
                Download PDF
              </DownloadLink>
            </DeliveryInformation>
            {course && (
              <>
                <CourseDetailPart
                  title="Qualification"
                  value={course?.details?.qualification}
                />
                <CourseDetailPart
                  title="Qualification Title"
                  value={course?.details?.title}
                />
                <CourseDetailPart
                  title="Duration"
                  value={course?.details?.duration}
                />
                {course?.details?.max !== 0 && (
                  <CourseDetailPart
                    title="Maximum Participants"
                    value={course?.details?.max?.toString() ?? ""}
                  />
                )}
                <CourseDetailPart
                  title="Pre-requisite"
                  value={course?.details?.prerequisites}
                />
                <CourseDetailPart
                  title="Covers"
                  value={course?.details?.covers}
                />
                <CourseDetailPart
                  title="Cost Per Person"
                  value={course?.details?.cost}
                />
                <CourseDetailPart
                  title="Notes"
                  value={course?.details?.notes}
                />
              </>
            )}
          </CourseWrapper>
          <MobileDownloadContainer>
            <MobileDownloadLink
              href={course?.details?.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFilePdf} size="xl" /> {/* PDF icon */}
              Download PDF
            </MobileDownloadLink>
          </MobileDownloadContainer>
        </div>

        <RelatedCourses
          courseId={course?.courseId ?? ""}
          subCategoryId={subCategory?.subCategoryId ?? ""}
        />
      </PageWrapper>
    </>
  );
};

export default CourseDetailsPage;
