import styled from "@emotion/styled"; // Using @emotion/styled
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import HeroCarouselTab from "../../components/admin/HeroCarouselTab";
import PartnerCarouselTab from "../../components/admin/PartnerCarouselTab";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import { colors } from "../../types";

export const TabContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: 16,
  borderBottom: `2px solid ${colors.brand.brandSilver.hex}`,
});

export const Tab = styled("div")<{ active: boolean }>(({ active }) => ({
  padding: "10px 20px",
  cursor: "pointer",
  fontFamily: "AvanteGarde-Bold, sans-serif",
  fontSize: "13px",
  borderBottom: active ? `2px solid ${colors.brand.brandDarkRed.hex}` : "none",
  "&:hover": {
    color: colors.brand.brandDarkRed.hex,
  },
  "@media (max-width: 768px)": {
    padding: "10px 15px",
    fontSize: "12px",
  },
}));

export const ContentWrapper = styled("div")({
  paddingTop: "16px",
  paddingBottom: "2rem",
});

const CarouselsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0); // State to track selected tab

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <>
      <Helmet>
        <title>DTN - Admin - Carousels</title>
        <meta
          name="description"
          content="Access and manage the DTN Academy administration dashboard. Control content, manage users, upload media, and maintain the website's functionality."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[{ label: "Admin", href: "/admin" }, { label: "Carousels" }]}
        />

        <TabContainer>
          <Tab active={selectedTab === 0} onClick={() => handleTabChange(0)}>
            Hero
          </Tab>
          <Tab active={selectedTab === 1} onClick={() => handleTabChange(1)}>
            Partner
          </Tab>
        </TabContainer>

        <ContentWrapper>
          {selectedTab === 0 && <HeroCarouselTab />}
          {selectedTab === 1 && <PartnerCarouselTab />}
        </ContentWrapper>
      </AdminPageWrapper>
    </>
  );
};

export default CarouselsPage;
