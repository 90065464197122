import styled from "@emotion/styled";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { v4 } from "uuid";
import TestimonialCard from "../../../components/courses/TestimonialCard";
import ImageSelectionModal from "../../../components/form/ImageSelectionModal";
import {
  DeleteIcon,
  InputField,
  InputWrapper,
  Label,
  SubmitButton,
} from "../../../components/form/Styles";
import ErrorMessage from "../../../components/misc/ErrorMessage";
import LoadingDots from "../../../components/misc/LoadingDots";
import { useGetOtherCourseBySubcategorySlug } from "../../../hooks/queries/useGetOtherCourseBySubcategorySlug";
import { getApiInstance } from "../../../hooks/useApi";
import { colors } from "../../../types/colors";
import {
  decodeBase64Markdown,
  encodeBase64Markdown,
} from "../../../utils/decodeBase64Markdown";

const FlexRow = styled("div")<{ isFirst?: boolean; isLast?: boolean }>(
  ({ isFirst, isLast }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "15px 20px",
    boxSizing: "border-box",
    fontFamily: "AvanteGarde-Bold, sans-serif",
    borderTop: isFirst ? `1px solid ${colors.brand.brandSilver.hex}` : "none",
    borderBottom: isLast
      ? `1px solid ${colors.brand.brandSilver.hex}`
      : `1px solid ${colors.brand.brandSilver.rgbaString({ alpha: 0.4 })}`,
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "10px 15px",
      fontSize: "14px",
    },
  })
);

const LayoutWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
  gap: "20px",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    padding: "0 15px",
  },
});

const LeftColumn = styled("div")({
  flex: 2,
  fontSize: "16px",
  lineHeight: "1.6",
  "@media (max-width: 768px)": {
    fontSize: "14px",
  },
});

const RightColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  img: {
    width: "100%",
    borderRadius: "8px",
    boxShadow: `0px 4px 10px ${colors.brand.brandBlack.rgbaString({
      alpha: 0.5,
    })}`,
    marginTop: "20px",
    marginBottom: "10px",
  },
  "@media (max-width: 768px)": {
    img: {
      marginBottom: "10px",
    },
  },
});

const FormerSection = styled("div")({
  display: "grid",
  width: "100%",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "20px",
  marginTop: "1rem",
  marginBottom: "1rem",

  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr",
  },
});

const ValueWrapper = styled(InputWrapper)({
  color: colors.brand.brandDarkRed.hex,
  width: "50%",
  "@media (max-width: 768px)": {
    marginTop: "5px",
    width: "90%",
  },
});

const SubmitButtonRow = styled("div")({
  display: "flex",
  justifyContent: "flex-end", // Align the button to the right
  width: "100%",
  padding: "15px 20px", // Adjust padding to match other rows
  boxSizing: "border-box",
});

interface AdminOtherCoursePageProps {
  setOtherCourseEdit: (value: boolean) => void;
  subCategorySlug: string;
}

const AdminOtherCoursePage: React.FC<AdminOtherCoursePageProps> = ({
  setOtherCourseEdit,
  subCategorySlug,
}) => {
  const [_course, setCourse] = useState<{
    otherCourseId?: string;
    duration: string;
    courseCode: string;
    employment: string;
    content: string;
    images?: {
      otherCourseImageId: string;
      imageId: string;
      imageUrl: string;
    }[];
    imagesToAdd?: {
      imageId: string;
    }[];
    imagesToRemove?: { otherCourseImageId: string }[];
    stories?: {
      otherCourseStoryId: string;
      imageId: string;
      imageUrl: string;
      content: string;
    }[];
  }>({
    otherCourseId: "",
    duration: "",
    courseCode: "",
    employment: "",
    content: "",
    images: [],
    imagesToAdd: [],
    imagesToRemove: [],
    stories: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
    refetch: fetchCourse,
  } = useGetOtherCourseBySubcategorySlug(subCategorySlug);

  useEffect(() => {
    if (course) {
      setCourse({
        otherCourseId: course.otherCourseId,
        duration: course.duration,
        courseCode: course.courseCode,
        employment: course.employment,
        content: course.content,
        images: course.images,
        stories: course.stories,
      });
    }
  }, [course]);

  // State to hold the markdown content
  const [markdownContent, setMarkdownContent] = useState<string>("");

  useEffect(() => {
    if (subCategorySlug) {
      fetchCourse();
    }
  }, [fetchCourse, subCategorySlug]);

  useEffect(() => {
    if (_course?.content) {
      setMarkdownContent(decodeBase64Markdown(_course?.content));
    }
  }, [_course?.content]);

  const handleSave = useCallback(async () => {
    // Save the course
    setIsSubmitting(true);
    try {
      const response = await getApiInstance().patch(
        `/api/courses/other-courses/${_course?.otherCourseId}`,
        {
          duration: _course?.duration,
          courseCode: _course?.courseCode,
          employment: _course?.employment,
          content: _course?.content,
          imagesToAdd: _course?.imagesToAdd,
          imagesToRemove: _course?.imagesToRemove,
        }
      );

      if (response.status === 204) {
        setOtherCourseEdit(false);
      } else {
        alert("Failed to save course");
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  }, [_course, setOtherCourseEdit]);

  const handleImageSelect = (imageId: string, imageUrl: string) => {
    setCourse((prev) => {
      // Ensure a new array is created for both 'images' and 'imagesToAdd' to trigger re-render
      const updatedImages = [
        ...(prev.images || []),
        {
          otherCourseImageId: v4(), // Generate a unique ID for the new image
          imageId,
          imageUrl,
        },
      ];

      const updatedImagesToAdd = [
        ...(prev.imagesToAdd || []),
        {
          imageId,
        },
      ];

      return {
        ...prev,
        images: updatedImages,
        imagesToAdd: updatedImagesToAdd,
      };
    });

    // Close the image modal after selecting the image
    setIsImageModalOpen(false);
  };

  const handleImageRemove = (otherCourseImageId: string) => {
    setCourse((prev) => {
      const isImageMarkedForRemoval = prev.imagesToRemove?.some(
        (image) => image.otherCourseImageId === otherCourseImageId
      );

      let updatedImagesToRemove;
      if (isImageMarkedForRemoval) {
        // Undo the removal by filtering it out from the imagesToRemove list
        updatedImagesToRemove = prev.imagesToRemove?.filter(
          (image) => image.otherCourseImageId !== otherCourseImageId
        );
      } else {
        // Add to the imagesToRemove list
        updatedImagesToRemove = [
          ...(prev.imagesToRemove || []),
          {
            otherCourseImageId,
          },
        ];
      }

      // Return the new state with the updated imagesToRemove
      return {
        ...prev,
        imagesToRemove: updatedImagesToRemove,
      };
    });
  };

  if (isCourseLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isCourseError) {
    return <ErrorMessage message={"Error fetching category"} />;
  }

  const handleEditorChange = ({ text }: { text: string }) => {
    setMarkdownContent(text);
    setCourse((prev) => ({ ...prev, content: encodeBase64Markdown(text) }));
  };

  return (
    <>
      <SubmitButtonRow>
        <SubmitButton onClick={handleSave}>
          {isSubmitting ? "Saving..." : "Save"}
        </SubmitButton>
      </SubmitButtonRow>
      <FlexRow isFirst={false}>
        <ValueWrapper>
          <InputField
            type="text"
            name="name"
            value={_course?.duration}
            onChange={(e) => {
              setCourse((prev) => ({ ...prev, duration: e.target.value }));
            }}
            placeholder=" "
            required
          />
          <Label>Duration</Label>
        </ValueWrapper>
      </FlexRow>
      <FlexRow>
        <ValueWrapper>
          <InputField
            type="text"
            name="courseCode"
            value={_course?.courseCode}
            onChange={(e) => {
              setCourse((prev) => ({ ...prev, courseCode: e.target.value }));
            }}
            placeholder=" "
            required
          />
          <Label>Course Code</Label>
        </ValueWrapper>
      </FlexRow>
      <FlexRow isLast={true}>
        <ValueWrapper>
          <InputField
            type="text"
            name="employment"
            value={_course?.employment}
            onChange={(e) => {
              setCourse((prev) => ({ ...prev, employment: e.target.value }));
            }}
            placeholder=" "
            required
          />
          <Label>FT/PT</Label>
        </ValueWrapper>
      </FlexRow>
      <LayoutWrapper>
        <LeftColumn>
          <MarkdownEditor
            value={markdownContent}
            style={{ height: "700px" }}
            renderHTML={(text: string | null | undefined) => (
              <ReactMarkdown>{text}</ReactMarkdown>
            )}
            onChange={handleEditorChange}
          />
        </LeftColumn>
        <RightColumn>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton
              onClick={() => {
                setIsImageModalOpen(true);
              }}
            >
              Add Image
            </SubmitButton>
          </div>
          {course?.images.map((image) => {
            const isMarkedForRemoval = _course.imagesToRemove?.some(
              (img) => img.otherCourseImageId === image.otherCourseImageId
            );

            return (
              <div
                key={image?.otherCourseImageId}
                style={{
                  position: "relative",
                  opacity: isMarkedForRemoval ? 0.5 : 1, // Make the image semi-transparent if marked for removal
                }}
              >
                <img src={image.imageUrl} alt="Course" />
                <DeleteIcon
                  style={{ top: "30px", right: "10px" }}
                  icon={isMarkedForRemoval ? faUndo : faTrash}
                  onClick={() => handleImageRemove(image.otherCourseImageId)}
                />
              </div>
            );
          })}
        </RightColumn>
      </LayoutWrapper>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start", // Align button to the left
              marginTop: "1rem",
            }}
          >
            <SubmitButton>Add Story</SubmitButton>
          </div>
          <FormerSection>
            {_course?.stories?.map((story) => (
              <>
                <TestimonialCard
                  key={story.otherCourseStoryId}
                  imageUrl={story.imageUrl}
                  content={decodeBase64Markdown(story.content)}
                />
              </>
            ))}
          </FormerSection>
        </div>
      </div>
      <ImageSelectionModal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        onSelectImage={handleImageSelect}
      />
    </>
  );
};

export default AdminOtherCoursePage;
