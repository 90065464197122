import { useQuery, UseQueryResult } from "react-query";
import { PartnerCarousel } from "../../types";
import { getApiInstance } from "../useApi";
import { useToast } from "../useToast";

const fetchPartnerCarousel = async (
  active: "all" | "true" | "false" = "all"
): Promise<{
  carouselItems: PartnerCarousel[];
}> => {
  const response = await getApiInstance().get<{
    carouselItems: PartnerCarousel[];
  }>("/api/dtn/carousel/partner", { params: { active } });
  return response.data;
};

// Custom hook using React Query with typed result
export const useGetPartnerCarousel = (
  active: "all" | "true" | "false" = "all"
): UseQueryResult<{ carouselItems: PartnerCarousel[] }> => {
  const { showToast } = useToast();

  return useQuery<{ carouselItems: PartnerCarousel[] }>(
    "partnerCarousel",
    () => fetchPartnerCarousel(active),
    {
      onError: (error: any) => {
        showToast("Error fetching partner carousel items", "error");
        console.error("Error fetching partner carousel items", error);
      },
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );
};
