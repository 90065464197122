import styled from "@emotion/styled";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getApiInstance } from "../../hooks/useApi";
import { colors } from "../../types";
import LoadingDots from "../misc/LoadingDots";
import { SubmitButton } from "./Styles";

// Modal Overlay styling
const ModalOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
});

// Modal content styling with a scrollable area
const ModalContent = styled("div")({
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "80vh",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxSizing: "border-box",
});

// Fixed header with "X" button and title
const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  padding: "8px",
  borderBottom: "1px solid #ccc",
  zIndex: 1,
});

const ScrollableContent = styled("div")({
  flexGrow: 1,
  overflowY: "auto",
  padding: "10px",
  paddingRight: "10px", // Add padding to prevent content from touching scrollbar
  maxHeight: "calc(80vh - 120px)", // Adjust height considering header/footer
});

const ImageGrid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "10px",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

const ImageItem = styled("div")<{ isSelected: boolean }>(({ isSelected }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "10px",
  border: isSelected
    ? `2px solid ${colors.brand.brandDarkRed.hex}`
    : "1px solid #ccc",
  borderRadius: "4px",
  cursor: "pointer",
  transition: "transform 0.2s, border-color 0.2s",
  "&:hover": {
    transform: "translateY(-5px)",
    borderColor: "#007bff",
  },
  overflow: "hidden",
}));

const PDFIcon = styled(FontAwesomeIcon)({
  fontSize: "24px",
  color: "#e53e3e",
  flexShrink: 0, // Ensures the icon doesn't shrink
});

const PDFName = styled("p")({
  fontSize: "14px",
  color: "#333",
  margin: 0,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  flexGrow: 1, // Allows the text to take up available space
});

interface PDFSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectPDF: (pdfId: string, filename: string, url: string) => void;
}

const PDFSelectionModal: React.FC<PDFSelectionModalProps> = ({
  isOpen,
  onClose,
  onSelectPDF,
}) => {
  const [pdfs, setPDFs] = useState<
    { uploaded: string; pdfId: string; filename: string; url: string }[]
  >([]);
  const [selectedPDF, setSelectedPDF] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true); // To track if there are more images to load
  const observerRef = useRef<IntersectionObserver | null>(null); // Ref for IntersectionObserver

  const itemsPerPage = 10;

  useEffect(() => {
    if (!isOpen) return;

    setCurrentPage(1);
    setPDFs([]);
    setHasMore(true);
    fetchPDFs(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchPDFs = async (page: number) => {
    if (!hasMore) return;

    setIsLoading(true);
    try {
      const response = await getApiInstance().get(
        `/api/dtn/pdfs?page=${page}&limit=${itemsPerPage}`
      );
      if (response.data.items.length === 0) {
        setHasMore(false);
      } else {
        setPDFs((prev) => [...prev, ...response.data.items]);
      }
    } catch (error) {
      console.error("Error fetching pdfs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (pdfId: string, filename: string, url: string) => {
    setSelectedPDF(pdfId);
    onSelectPDF(pdfId, filename, url);
  };

  const lastPDFRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observerRef.current) observerRef.current.disconnect(); // Disconnect previous observer

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });

      if (node) observerRef.current.observe(node); // Observe the last image
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    if (currentPage > 1) {
      fetchPDFs(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    setPDFs([]);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <h2>Select a PDF</h2>
          <button
            onClick={onClose}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </ModalHeader>

        <ScrollableContent>
          <ImageGrid>
            {pdfs.map((pdf, index) => {
              const isLastPDF = pdfs.length === index + 1;

              return (
                <ImageItem
                  ref={isLastPDF ? lastPDFRef : undefined}
                  key={pdf.pdfId}
                  isSelected={pdf.pdfId === selectedPDF}
                  onClick={() => handleSelect(pdf.pdfId, pdf.filename, pdf.url)}
                >
                  <PDFIcon icon={faFilePdf} />
                  <PDFName title={pdf.filename}>{pdf.filename}</PDFName>
                </ImageItem>
              );
            })}
          </ImageGrid>

          {isLoading && <LoadingDots />}
        </ScrollableContent>

        <div
          style={{
            paddingTop: 8,
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ccc",
          }}
        >
          <SubmitButton onClick={onClose}>Close</SubmitButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PDFSelectionModal;
