import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { CourseCardsGrid } from "../components/courses/CourseCardsGrid";
import CourseCategoryCard from "../components/courses/CourseCategoryCard";
import { CoursesWrapper } from "../components/courses/CoursesWrapper";
import { CenteredContainer } from "../components/form/Styles";
import HeaderImage from "../components/header/HeaderImage";
import Breadcrumb from "../components/misc/Breadcrumb";
import ErrorMessage from "../components/misc/ErrorMessage";
import InfoSection from "../components/misc/InfoSection";
import LoadingDots from "../components/misc/LoadingDots";
import { PageWrapper } from "../components/misc/PageWrapper";
import { useCategories } from "../hooks/queries";

const OurCoursesPage: React.FC = () => {
  const navigate = useNavigate();

  const {
    data: categories,
    isLoading,
    isError,
    refetch: fetchCategories,
  } = useCategories();

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryClick = (slug: string) => {
    navigate(`/our-courses/${slug}`);
  };

  if (isLoading) {
    return (
      <div>
        <LoadingDots />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={"Error fetching categories"} />;
  }

  return (
    <>
      <Helmet>
        <title>DTN - Our Courses</title>
        <meta
          name="description"
          content="Digital Telecoms Network Academy provides a comprehensive learning experience for the Telecommunications Industry, with blended practical training to enhance or embrace new skills for the ongoing Digital Development for the Smart City Infrastructure"
        />
      </Helmet>
      <PageWrapper>
        <Breadcrumb
          items={[{ label: "Home", href: "/" }, { label: "Our Courses" }]}
        />
        <HeaderImage
          backgroundImageUrl={`https://dtnacademy.lon1.cdn.digitaloceanspaces.com/uploads/banner/COURSES%20BANNER.png`}
        />
        <InfoSection />
        <CenteredContainer>
          <CoursesWrapper>
            <CourseCardsGrid>
              {categories?.length &&
                categories.map((category) => (
                  <CourseCategoryCard
                    key={category.categoryId}
                    imageUrl={category.imageUrl}
                    logoUrl="/logo.png"
                    title={category.name}
                    onClick={() => handleCategoryClick(category.slug)}
                  />
                ))}
            </CourseCardsGrid>
          </CoursesWrapper>
        </CenteredContainer>
      </PageWrapper>
    </>
  );
};

export default OurCoursesPage;
