import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { colors } from "../../types/colors";

// Define the animation for the dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Style the container that holds the dots and center it on the screen
const LoadingContainer = styled.div`
  position: fixed; // This will position the container relative to the viewport
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); // Center it horizontally and vertically
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Style each dot and apply the animation
const Dot = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
  &:nth-child(3) {
    animation-delay: 0s;
  }
`;

const LoadingDots: React.FC = () => {
  return (
    <LoadingContainer>
      <Dot color={colors.brand.brandDarkRed.hex} />
      <Dot color={colors.brand.brandBlack.hex} />
      <Dot color={colors.brand.brandSilver.hex} />
    </LoadingContainer>
  );
};

export default LoadingDots;
