import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../types/colors";

export const FormWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  justifyItems: "center",
  gap: "15px",
  backgroundColor: "#fff",
  padding: "10px",
  borderRadius: "8px",
  width: "100%",
  boxSizing: "border-box",
});

export const InputWrapper = styled("div")({
  position: "relative",
  marginBottom: "8px",
  width: "100%",
  boxSizing: "border-box",
});

export const Label = styled("label")({
  position: "absolute",
  left: "12px",
  top: "50%",
  transform: "translateY(-50%)",
  color: "#aaa",
  transition: "0.3s ease all",
  backgroundColor: "#fff",
  padding: "0 4px",
  pointerEvents: "none",
});

export const InputField = styled("input")({
  width: "100%",
  padding: "12px",
  fontSize: "12px",
  border: "2px solid #ddd",
  borderRadius: "8px",
  outline: "none",
  transition: "border-color 0.3s ease",
  backgroundColor: "transparent",

  "&:focus": {
    borderColor: colors.brand.brandDarkRed.hex,
  },

  "&:focus + label, &:not(:placeholder-shown) + label": {
    top: "0px",
    fontSize: "12px",
    color: colors.brand.brandDarkRed.hex,
  },
});

export const TextAreaField = styled("textarea")({
  width: "100%",
  padding: "12px",
  fontSize: "12px",
  border: "2px solid #ddd",
  borderRadius: "8px",
  outline: "none",
  resize: "vertical",
  minHeight: "100px",
  transition: "border-color 0.3s ease",
  backgroundColor: "transparent",

  "&:focus": {
    borderColor: colors.brand.brandDarkRed.hex,
  },

  "&:focus + label, &:not(:placeholder-shown) + label": {
    top: "0px",
    fontSize: "12px",
    color: colors.brand.brandDarkRed.hex,
  },
});

export const SubmitButton = styled("button")({
  fontFamily: "AvanteGarde-Bold, sans-serif",
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  padding: "12px",
  border: "none",
  borderRadius: "8px",
  fontSize: "12px",
  cursor: "pointer",
  transition: "background-color 0.3s ease, transform 0.2s ease",

  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
    transform: "translateY(-2px)",
  },

  "&:disabled": {
    backgroundColor: colors.brand.brandSilver.hex,
    cursor: "not-allowed",
    transform: "none",
  },
});

export const ImageContainer = styled("div")({
  width: "200px",
  height: "100px",
  borderRadius: "8px",
  border: `2px solid ${colors.brand.brandDarkRed.hex}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  backgroundColor: "#f9f9f9",
  cursor: "pointer",
});

export const ImageButton = styled("button")({
  position: "absolute",
  backgroundColor: colors.brand.brandDarkRed.hex,
  color: "white",
  padding: "8px 16px",
  border: "none",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "bold",
  cursor: "pointer",
  zIndex: 2,
  transition: "background-color 0.3s ease, transform 0.2s ease",

  "&:hover": {
    backgroundColor: colors.brand.brandBlack.hex,
  },
});

export const ImagePreview = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "8px",
  border: `2px solid ${colors.brand.brandDarkRed.hex}`,
});

export const PDFPreview = styled("div")({
  width: "100%",
  height: "100%",
  borderRadius: "8px",
  border: `2px solid ${colors.brand.brandDarkRed.hex}`,
});

export const ModalContent = styled("div")({
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  width: "100%",
  boxSizing: "border-box",
});

export const NavigationButtons = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const SelectField = styled("select")({
  width: "100%",
  padding: "12px",
  fontSize: "12px",
  border: "2px solid #ddd",
  borderRadius: "8px",
  outline: "none",
  backgroundColor: "transparent",
  transition: "border-color 0.3s ease",

  "&:focus": {
    borderColor: colors.brand.brandDarkRed.hex,
  },

  "&:hover": {
    borderColor: colors.brand.brandDarkRed.hex,
  },
});

export const ImageSelectionWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between", // Space between the two containers
  gap: "15px", // Add a gap between the images
  marginBottom: "15px", // Ensure there's some margin at the bottom
  "@media (max-width: 768px)": {
    flexDirection: "column", // Stack images vertically on smaller screens
    gap: "10px",
  },
});

export const DeleteIcon = styled(FontAwesomeIcon)({
  position: "absolute",
  top: "8px",
  right: "8px",
  color: colors.brand.brandDarkRed.hex,
  backgroundColor: colors.brand.brandBlack.hex,
  cursor: "pointer",
  borderRadius: "50%",
  padding: "8px",
  transition: "background-color 0.3s, color 0.3s",
  "&:hover": {
    backgroundColor: colors.brand.brandSilver.hex,
    color: colors.brand.brandBlack.hex,
  },
  zIndex: 2,
});

export const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

export const HR = styled("hr")({
  margin: "8px 0",
  border: "none",
  borderBottom: `1px solid ${colors.brand.brandDarkRed.hex}`,
});

export const AdminPageWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  // fontFamily: "AvanteGarde-Bold, sans-serif",
  margin: "0 auto",
  paddingBottom: "2rem",
  "@media (max-width: 768px)": {
    padding: "0 20px", // Reduced padding for mobile screens
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    // Tablet-specific styling
    padding: "0 30px", // Moderate padding for tablets
  },
});
