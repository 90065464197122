import styled from "@emotion/styled";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { colors } from "../../types";

const BackToTopButton = styled("button")({
  position: "fixed",
  right: "15px",
  width: "40px",
  height: "40px",
  bottom: "70px",
  backgroundColor: colors.brand.brandBlack.hex,
  color: "#fff",
  border: "none",
  borderRadius: "50%",
  padding: "10px",
  cursor: "pointer",
  display: "none",
  zIndex: "1000",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",

  "&:hover": {
    backgroundColor: colors.brand.brandDarkRed.hex,
  },

  "&:focus": {
    outline: "none",
  },

  "@media (max-width: 768px)": {
    right: "10px",
  },
});

const ScrollToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <BackToTopButton
      onClick={scrollToTop}
      style={{ display: isVisible ? "block" : "none" }} // Toggle visibility using inline style
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </BackToTopButton>
  );
};

export default ScrollToTop;
