import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AdminPageWrapper } from "../../components/form/Styles";
import Breadcrumb from "../../components/misc/Breadcrumb";
import ErrorMessage from "../../components/misc/ErrorMessage";
import FloatingButton from "../../components/misc/FloatingButton";
import LoadingDots from "../../components/misc/LoadingDots";
import { useGetTestimonials } from "../../hooks/queries";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { colors, Testimonial } from "../../types";

const TestimonialsPage: React.FC = () => {
  const { showToast } = useToast();
  const { data: testimonials, isLoading, isError } = useGetTestimonials();

  const [rowData, setRowData] = useState<Testimonial[]>([]);
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (testimonials?.testimonials) {
      setRowData(testimonials.testimonials);
    }
  }, [testimonials]);

  const handleSave = (testimonial: Testimonial) => {
    setIsSaving(true);

    const isNew = testimonial.testimonialId.startsWith("new_");

    const request = isNew
      ? getApiInstance().post("/api/dtn/testimonials", {
          author: testimonial.author,
          testimonial: testimonial.testimonial,
          active: testimonial.active,
        })
      : getApiInstance().patch(
          `/api/dtn/testimonials/${testimonial.testimonialId}`,
          {
            author: testimonial.author,
            testimonial: testimonial.testimonial,
            active: testimonial.active,
          }
        );

    request
      .then((response) => {
        if (isNew && response.status === 201) {
          const savedTestimonial = response.data;
          setRowData((prev) =>
            prev.map((row) =>
              row.testimonialId === testimonial.testimonialId
                ? savedTestimonial
                : row
            )
          );
          showToast("Testimonial added successfully", "success");
        } else if (response.status === 204) {
          showToast("Testimonial updated successfully", "success");
        }
      })
      .catch((e) => {
        console.error("Error saving testimonial", e);
        showToast("Error saving testimonial", "error");
      })
      .finally(() => {
        setIsSaving(false);
      });

    setEditingRowId(null);
  };

  const handleDelete = (testimonialId: string) => {
    if (window.confirm("Are you sure you want to delete this testimonial?")) {
      setIsSaving(true);
      getApiInstance()
        .delete(`/api/dtn/testimonials/${testimonialId}`)
        .then((response) => {
          if (response.status === 204) {
            showToast("Testimonial deleted successfully", "success");
            setRowData((prev) =>
              prev.filter((row) => row.testimonialId !== testimonialId)
            );
          }
        })
        .catch((e) => {
          console.error("Error deleting testimonial", e);
          showToast("Error deleting testimonial", "error");
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const handleAddRow = () => {
    const newTestimonial: Testimonial = {
      created: new Date().toISOString(),
      testimonialId: `new_${Date.now()}`, // Generate a temporary unique ID
      author: "",
      testimonial: "",
      active: true,
    };

    setRowData((prev) => [newTestimonial, ...prev]); // Add the new row at the top
    setEditingRowId(newTestimonial.testimonialId); // Automatically enter edit mode
  };

  const colDefs: ColDef<Testimonial>[] = [
    {
      field: "author",
      headerName: "Author",
      editable: (params) => params?.data?.testimonialId === editingRowId,
      sortable: true,
      filter: true,
    },
    {
      field: "testimonial",
      headerName: "Testimonial",
      editable: (params) => params?.data?.testimonialId === editingRowId,
      flex: 2,
      wrapText: true,
    },
    {
      field: "active",
      headerName: "Active",
      cellRenderer: (params: any) => {
        const isEditing = params.data.testimonialId === editingRowId;
        return (
          <input
            type="checkbox"
            checked={params.data.active}
            disabled={!isEditing}
            onChange={(e) => {
              const updatedActive = e.target.checked;
              setRowData((prev) =>
                prev.map((row) =>
                  row.testimonialId === params.data.testimonialId
                    ? { ...row, active: updatedActive }
                    : row
                )
              );
            }}
          />
        );
      },
      sortable: true,
      filter: true,
    },
    {
      headerName: "",
      cellRenderer: (params: any) => {
        const isEditing = params.data.testimonialId === editingRowId;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              height: "100%",
            }}
          >
            <button
              onClick={() => {
                if (isEditing) {
                  handleSave(params.data);
                } else {
                  setEditingRowId(params.data.testimonialId);
                }
              }}
              style={{
                backgroundColor: isEditing
                  ? "#4CAF50"
                  : colors.brand.brandDarkRed.hex,
                color: "white",
                border: "none",
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              {isEditing ? "Save" : "Edit"}
            </button>
            <button
              onClick={() => handleDelete(params.data.testimonialId)}
              style={{
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        );
      },
      flex: 1,
      sortable: false,
      filter: false,
    },
  ];

  if (isLoading || isSaving) {
    return (
      <AdminPageWrapper>
        <LoadingDots />
      </AdminPageWrapper>
    );
  }

  if (isError) {
    return (
      <AdminPageWrapper>
        <ErrorMessage message="Error fetching testimonials" />
      </AdminPageWrapper>
    );
  }

  return (
    <>
      <Helmet>
        <title>DTN - Admin - Testimonials</title>
        <meta
          name="description"
          content="Access and manage the DTN Academy administration dashboard. Control content, manage users, upload media, and maintain the website's functionality."
        />
      </Helmet>
      <AdminPageWrapper>
        <Breadcrumb
          items={[
            { label: "Admin", href: "/admin" },
            { label: "Testimonials" },
          ]}
        />
        {rowData.length > 0 ? (
          <div
            className="ag-theme-alpine"
            style={{ height: 500, marginTop: 16 }}
          >
            <AgGridReact<Testimonial>
              rowData={rowData}
              columnDefs={colDefs}
              onRowValueChanged={(event) => {
                setRowData((prev) =>
                  prev.map((row) =>
                    row.testimonialId === event.data?.testimonialId
                      ? event.data
                      : row
                  )
                );
              }}
            />
          </div>
        ) : (
          <p>No testimonials available.</p>
        )}
        <FloatingButton onClick={() => handleAddRow()} />
      </AdminPageWrapper>
    </>
  );
};

export default TestimonialsPage;
