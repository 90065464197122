import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

const CarouselContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

const CarouselItem = styled.div<{ itemsToShow: number }>`
  min-width: ${({ itemsToShow }) => 100 / itemsToShow}%;
  box-sizing: border-box;
  padding: 0 10px;

  @media (max-width: 768px) {
    min-width: 100%;
    padding: 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

interface CarouselProps {
  images: string[];
}

const CustomCarousel: React.FC<CarouselProps> = ({ images }) => {
  const [itemsToShow, setItemsToShow] = useState(
    window.innerWidth <= 768 ? 1 : 5
  );
  const carouselRef = useRef<HTMLDivElement>(null);
  const currentIndexRef = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const totalItems = images.length;

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(window.innerWidth <= 768 ? 1 : 5);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const startAutoScroll = () => {
      intervalRef.current = setInterval(() => {
        if (carouselRef.current) {
          currentIndexRef.current += 1;

          if (currentIndexRef.current >= totalItems) {
            currentIndexRef.current = 0;
            carouselRef.current.style.transition = "none";
            carouselRef.current.style.transform = `translateX(0px)`;

            setTimeout(() => {
              carouselRef.current!.style.transition =
                "transform 0.5s ease-in-out";
            }, 50);
          } else {
            const itemWidth = carouselRef.current.clientWidth / itemsToShow;
            const translateXValue = -currentIndexRef.current * itemWidth;
            carouselRef.current.style.transform = `translateX(${translateXValue}px)`;
          }
        }
      }, 3000);
    };

    startAutoScroll();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [images.length, itemsToShow, totalItems]);

  return (
    <CarouselContainer>
      <CarouselWrapper ref={carouselRef}>
        {[...images, ...images.slice(0, itemsToShow)].map((image, index) => (
          <CarouselItem key={index} itemsToShow={itemsToShow}>
            <Image src={image} alt={`carousel-${index}`} />
          </CarouselItem>
        ))}
      </CarouselWrapper>
    </CarouselContainer>
  );
};

export default CustomCarousel;
