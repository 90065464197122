import { useQuery, UseQueryResult } from "react-query";
import { getApiInstance } from "../../hooks/useApi";
import { useToast } from "../../hooks/useToast";
import { Category } from "../../types"; // Assuming you have a Category type

// Define the API call to fetch category by slug
const fetchCourseCategoryBySlug = async (
  slug: string,
  nrswa: string = "true"
) => {
  const response = await getApiInstance().get(
    `/api/courses/categories/${slug}?nrswa=all`
  );
  return response.data;
};

export const useGetCourseCategoryBySlug = (
  slug: string,
  nrswa: string = "true"
): UseQueryResult<Category, unknown> => {
  const { showToast } = useToast();

  // Use `useQuery` to fetch the course category by slug
  return useQuery<Category, unknown>(
    ["courseCategory", slug, nrswa], // Query key (based on the slug)
    () => fetchCourseCategoryBySlug(slug), // API fetching function
    {
      enabled: !!slug, // Only fetch if slug is available
      onError: (error: any) => {
        showToast("Error fetching course category", "error");
        console.error("Error fetching course category:", error);
      },
      staleTime: 1000 * 60 * 5, // Cache result for 5 minutes
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );
};
